/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import {
  reducer as authReducer,
  getInitialState as getAuthInitialState,
  STATE_KEY as authStateKey,
} from '@biotmed/auth-pages';
import portalBuilderReducer, {
  getInitialState as getPortalBuilderInitialState,
  STATE_KEY as portalBuilderStateKey,
} from 'src/routes/PortalBuilder/modules/slice';

import {
  SNACKBAR_SLICE_KEY as snackbarKey,
  snackbarReducer,
  getInitialState as getSnackbarInitialState,
} from '@biotmed/base-components';
import { reducer as dataReducer, STATE_KEY as dataStateKey, getDataInitialState } from '../data';
import { actions as loginActions } from '../data/login';

// eslint-disable-next-line no-use-before-define
const getInitialState = (state?: RootState) => ({
  [dataStateKey]: getDataInitialState(state),
  [authStateKey]: getAuthInitialState(state?.[authStateKey]),
  [portalBuilderStateKey]: getPortalBuilderInitialState(state?.[portalBuilderStateKey]),
  [snackbarKey]: getSnackbarInitialState(),
});

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
const appReducer = combineReducers({
  [dataStateKey]: dataReducer,
  [authStateKey]: authReducer,
  [snackbarKey]: snackbarReducer,
  [portalBuilderStateKey]: portalBuilderReducer,
});

// rootReducer in charge of resetting the store on logout.
// see https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
const reducer: typeof appReducer = (state: any, action: { type: string }) => {
  // add logout....
  if (loginActions.logoutFinish.type === action.type) {
    return appReducer(getInitialState(state), action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

export default reducer;
