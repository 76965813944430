import { SubmitButtonStatus } from '@biotmed/base-components';
import { PortalType } from '@biotmed/data-components';
import { GetViewResponse, View, ErrorResponse } from '@biotmed/settings-sdk';
import { createSlice, createSelector, createAction } from '@reduxjs/toolkit';
import { LoadViewStatus } from './constant';

export const STATE_KEY = 'portalBuilder';

export interface portalBuilderState {
  viewsList: View[];
  selectedView?: GetViewResponse;
  errors: { updateError?: string };
  apiError?: ErrorResponse;
  saveBuilderStatus: SubmitButtonStatus;
  loadBuilderStatus: LoadViewStatus;
}

export const getInitialState = (state?: portalBuilderState): portalBuilderState => ({
  viewsList: [],
  selectedView: undefined,
  errors: { updateError: undefined },
  saveBuilderStatus: SubmitButtonStatus.NORMAL,
  loadBuilderStatus: LoadViewStatus.NORMAL,
});
/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    onLoadViewsSuccess: (state, action) => {
      state.viewsList = action.payload;
    },
    onLoadSelectedView: (state, action) => {
      state.saveBuilderStatus = SubmitButtonStatus.NORMAL;
      state.loadBuilderStatus = LoadViewStatus.LOADING;
      state.apiError = undefined;
    },
    onLoadSelectedViewSuccess: (state, action) => {
      state.selectedView = action.payload;
      state.loadBuilderStatus = LoadViewStatus.SUCCESS;
    },
    onLoadSelectedViewFail: (state, action) => {
      state.apiError = action.payload;
      state.loadBuilderStatus = LoadViewStatus.NORMAL;
    },
    onUpdateView: (state, action) => {
      state.apiError = undefined;
      state.saveBuilderStatus = SubmitButtonStatus.LOADING;
    },
    onUpdateViewSuccess: (state, action) => {
      state.selectedView = action.payload;
      state.apiError = undefined;
      state.saveBuilderStatus = SubmitButtonStatus.SUCCESS;
    },
    afterUpdateViewSuccess: state => {
      state.saveBuilderStatus = SubmitButtonStatus.NORMAL;
    },
    onUpdateViewFail: (state, action) => {
      state.apiError = action.payload;
      state.saveBuilderStatus = SubmitButtonStatus.NORMAL;
    },
    resetSelectedView: state => {
      state.selectedView = getInitialState(state).selectedView;
    },
  },
});
/* eslint-enable no-param-reassign */

const getState = (state: any) => state[STATE_KEY] || getInitialState();

export const selectors = {
  getViewsList: createSelector(getState, state => state.viewsList),
  getSelectedView: createSelector(getState, state => state.selectedView),
  getUpdateError: createSelector(getState, state => state.errors.updateError),
  getApiError: createSelector(getState, state => state.apiError),
  getSaveBuilderStatus: createSelector(getState, state => state.saveBuilderStatus),
  getLoadBuilderStatus: createSelector(getState, state => state.loadBuilderStatus),
};
const extraActions = {
  onLoadViews: createAction<{ portalType: PortalType }>(`${STATE_KEY}/onLoadViews`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
