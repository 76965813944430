import { EntityType } from '@biotmed/data-components';
import { GetTemplateResponse } from '@biotmed/settings-sdk';
import React, { useMemo, useState } from 'react';
import { Entity } from 'src/redux/data/entity/modules/interfaces';
import TemplateComponent, { Mode, TemplateModeEnum, TemplateProps } from '../../../Template';

export const emptyRow = {
  entityTypeName: undefined as unknown as EntityType,
  displayName: '',
  name: '',
  description: '',
  builtInAttributes: [],
  dynamicAttributes: [],
  id: '',
  creationTime: '',
  lastModifiedTime: '',
};

const useChildren = (entity: Entity) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [template, setTemplate] = useState<GetTemplateResponse>(emptyRow);
  const [mode, setMode] = useState<Mode>(TemplateModeEnum.ADD);
  const [onSubmit, setOnSubmit] = useState(() => () => {});

  // eslint-disable-next-line no-shadow
  const openDrawer = (template: GetTemplateResponse, mode: Mode, onSubmit: any) => {
    setTemplate(template);
    setMode(mode);
    setIsOpen(true);
    setOnSubmit(() => onSubmit);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  const Template = useMemo(() => {
    return (props: Partial<TemplateProps>) => (
      <TemplateComponent
        entity={entity}
        template={template}
        handleClose={closeDrawer}
        open={isOpen}
        mode={mode}
        widthDiff="-70px"
        onFormSubmit={onSubmit}
        {...props}
      />
    );
  }, [template, mode, onSubmit, isOpen, entity]);

  return [openDrawer, closeDrawer, Template] as const;
};

export default useChildren;
