import { all, call, put, takeLatest, delay } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { getSdkApi } from '@biotmed/sdk-api-provider';
import { GetPortalViewsResponse, GetViewResponse, UpdateViewRequest, ErrorResponse } from '@biotmed/settings-sdk';
import { actions } from './slice';

function* onLoadViews(action: ReturnType<typeof actions.onLoadViews>): any {
  try {
    const response: AxiosResponse<GetPortalViewsResponse> = yield call(
      getSdkApi().settings.portalBuilderApi.getViewsByPortalType,
      action.payload.portalType,
    );
    const viewsList = response.data.views || [];
    yield put(actions.onLoadViewsSuccess(viewsList));
  } catch (e) {
    console.error(e);
  }
}

function* onLoadSelectedView(action: ReturnType<typeof actions.onLoadSelectedView>): any {
  try {
    const response: AxiosResponse<GetViewResponse> = yield call(
      getSdkApi().settings.portalBuilderApi.getViewResponse,
      action.payload.portalType,
      action.payload.viewId,
    );
    yield put(actions.onLoadSelectedViewSuccess(response.data));
  } catch (e: any) {
    console.error(e);
    const apiError = (e?.response?.data || e) as ErrorResponse;
    yield put(actions.onLoadSelectedViewFail(apiError as ErrorResponse));
  }
}

function* onUpdateView(action: ReturnType<typeof actions.onUpdateView>): any {
  const { portalType, viewId, attributes } = action.payload;
  try {
    const response: AxiosResponse<UpdateViewRequest> = yield call(
      getSdkApi().settings.portalBuilderApi.updateView,
      portalType,
      viewId,
      attributes,
    );
    yield put(actions.onUpdateViewSuccess(response.data));
  } catch (e: any) {
    console.error(e);
    yield put(actions.onLoadSelectedView({ portalType, viewId }));
    const apiError = (e?.response?.data || e) as ErrorResponse;
    yield put(actions.onUpdateViewFail(apiError as ErrorResponse));
  }
}

function* onUpdateViewSuccess() {
  yield delay(2000);
  yield put(actions.afterUpdateViewSuccess());
}
export default function* watchEntityActions() {
  yield all([
    takeLatest(actions.onLoadViews, onLoadViews),
    takeLatest(actions.onLoadSelectedView, onLoadSelectedView),
    takeLatest(actions.onUpdateView, onUpdateView),
    takeLatest(actions.onUpdateViewSuccess, onUpdateViewSuccess),
  ]);
}
