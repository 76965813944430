import { fork, all } from 'redux-saga/effects';
import { saga as authSaga } from '@biotmed/auth-pages';
import { saga as loginSaga } from 'src/redux/data/login';
import { saga as portalBuilderSaga } from 'src/routes/PortalBuilder';
import { sagas as dataSaga } from '../data';
import intercomSaga from '../../intercom/saga';

/*
 * The entry point for all general sagas used in this application.
 */
export default function* root() {
  yield all([fork(dataSaga), fork(loginSaga), fork(authSaga), fork(portalBuilderSaga), fork(intercomSaga)]);
}
