import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { AttributeTypeEnum } from '@biotmed/data-components';

import { TypesComponentsMap } from 'src/components/AttributeLayout';
import { CommonAttributeResponse } from '@biotmed/settings-sdk/service/model';
import BuiltInAttribute, { BuiltInAttributeProps } from './BuiltInAttribute';
import CustomAttribute, { CustomAttributeProps } from './CustomAttribute';
import { EntityTemplateForm } from '../TemplateForm';

export interface AttributeBaseProps {
  attrFieldName: string;
  type?: AttributeTypeEnum;
  phi?: boolean;
  required?: boolean;
  setRequired: (checked: boolean) => void;
  setDisplayName: (name: string) => void;
  attributeTouched?: any;
  attributeValues?: any;
  attributeInitialValues: any;
  attributeErrors?: any;
  isNewCustom?: boolean;
  selectedTargetAttribute: CommonAttributeResponse;
  onSelectedTargetAttributeChange: (targetAttribute: CommonAttributeResponse) => void;
}

type BuiltInOmittedValues =
  | 'setRequired'
  | 'setDisplayName'
  | 'setPHI'
  | 'setUniquely'
  | 'onSelectedTargetAttributeChange'
  | 'selectedTargetAttribute';

export type AttributeProps =
  | Omit<BuiltInAttributeProps, BuiltInOmittedValues>
  | Omit<CustomAttributeProps, BuiltInOmittedValues | 'setName' | 'setType'>;

export const Attribute: React.FC<AttributeProps> = props => {
  const {
    variant,
    attrFieldName,
    type,
    attributeTouched,
    attributeValues,
    attributeErrors,
    attributeInitialValues,
    isNewCustom,
    ...restProps
  } = props;

  const formik = useFormikContext<EntityTemplateForm>();
  const [selectedTargetAttribute, setSelectedTargetAttribute] = useState<CommonAttributeResponse>();

  const [attributeTypeSelected, setAttributeTypeSelected] = useState<string>(type || '');

  useEffect(() => {
    if (selectedTargetAttribute && isNewCustom) {
      handleChangeRequired(selectedTargetAttribute.validation?.mandatory || false);
      handleChangePHI(selectedTargetAttribute.phi || false);
      if (selectedTargetAttribute.type?.valueOf() === AttributeTypeEnum.Reference) {
        handleChangeUniquely(selectedTargetAttribute.referenceConfiguration?.uniquely || false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTargetAttribute]);

  const variantComponent: Record<typeof variant, React.FC<any>> = {
    builtIn: BuiltInAttribute,
    custom: CustomAttribute,
  };

  const Comp = variantComponent[variant];

  // const formikValue = formik.values[attrName][index];

  const handleChangeRequired = (checked: boolean) => {
    formik.setFieldValue(`${attrFieldName}.validation.mandatory`, checked);
  };

  // Sets Display Name
  const handleChangeDisplayName = (name: string) => {
    // TODO: CHECK THE SHOULD VALIDATE FALSE
    formik.setFieldValue(`${attrFieldName}.displayName`, name, false);
  };
  // Sets JSON Name
  const handleChangeName = (name: string) => {
    // TODO: CHECK THE SHOULD VALIDATE FALSE
    formik.setFieldValue(`${attrFieldName}.name`, name, false);
  };

  // Sets is PHI
  const handleChangePHI = (value: boolean) => {
    formik.setFieldValue(`${attrFieldName}.phi`, value);
  };

  // Sets is Uniquely
  const handleChangeUniquely = (checked: boolean) => {
    formik.setFieldValue(`${attrFieldName}.referenceConfiguration.uniquely`, checked);
  };

  // TODO: Add enum type when ready :
  const handleChangeType = (typeValue: string) => {
    formik.setFieldValue(`${attrFieldName}.type`, typeValue);
    setAttributeTypeSelected(typeValue);
    // reset validation and selectable values
    formik.setFieldValue(`${attrFieldName}.validation`, {
      mandatory: attributeValues?.validation?.mandatory || false,
    });
    formik.setFieldValue(`${attrFieldName}.selectableValues`, []);
    formik.setFieldValue(`${attrFieldName}.numericValuesUnits`, null);
    formik.setFieldValue(`${attrFieldName}.referenceConfiguration`, null);
    formik.setFieldValue(`${attrFieldName}.numericMetaData`, null);
    formik.setFieldValue(`${attrFieldName}.linkConfiguration`, null);
  };

  const typeComponentProps = {
    attrFieldName,
    attributeValues,
    attributeTouched,
    attributeErrors,
    attributeInitialValues,
    variant,
  };

  const handleSelectedTargetAttributeChange = (value: CommonAttributeResponse) => {
    setSelectedTargetAttribute(value);
  };

  const TypeComponent = TypesComponentsMap[attributeTypeSelected as keyof typeof TypesComponentsMap] ?? null;

  return (
    <Comp
      attributeValues={attributeValues}
      required={attributeValues?.validation?.mandatory}
      setRequired={handleChangeRequired}
      setDisplayName={handleChangeDisplayName}
      setName={handleChangeName}
      setType={handleChangeType}
      setPHI={handleChangePHI}
      setUniquely={handleChangeUniquely}
      attrFieldName={attrFieldName}
      attributeErrors={attributeErrors}
      attributeTouched={attributeTouched}
      isNew={isNewCustom}
      {...restProps}
      type={type}
      onSelectedTargetAttributeChange={handleSelectedTargetAttributeChange}
      selectedTargetAttribute={selectedTargetAttribute}
    >
      {TypeComponent && <TypeComponent {...typeComponentProps} targetAttribute={selectedTargetAttribute} />}
    </Comp>
  );
};

export default Attribute;
