/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
export enum RoutesEnum {
  AUTH = 'auth',
  PORTAL_BUILDER = 'portal-builder',
  TEMPLATES = 'templates',
  CHANGE_PASSWORD = 'change-password',
  IMPORT_EXPORT = 'import-export',
  TECHNICAL_INFORMATION = 'technical-information',
}

export default RoutesEnum;
