import { ViewTypeEnum } from '@biotmed/settings-sdk';

export const maxHeightContent = '46vh';
export const maxHeightForMultipleCategories = '30vh';
export const viewsTypesOrder = {
  [ViewTypeEnum.EntityList]: 0,
  [ViewTypeEnum.TemplateExpand]: 1,
  [ViewTypeEnum.TemplatePreview]: 2,
  [ViewTypeEnum.TemplateList]: 3,
};

/* eslint-disable no-shadow */
export enum SaveButtonTypeEnum {
  SAVE_AND_NEXT = 'SAVE_AND_NEXT',
  SAVE = 'SAVE',
  NONE = 'NONE',
}
export enum LoadViewStatus {
  NORMAL = 'noraml',
  LOADING = 'loading',
  SUCCESS = 'success',
  /* eslint-enable no-shadow */
}
