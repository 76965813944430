import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  Explained,
  SelectAutoComplete,
  SingleSelect,
  SubmitButtonStatus,
  ErrorBanner,
  AutocompleteOption,
} from '@biotmed/base-components';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'src/routes/PortalBuilder';
import { GetViewResponse, UpdateViewRequest, View } from '@biotmed/settings-sdk';
import { SelectChangeEvent } from '@mui/material';
import { PortalType, PortalTypeEnum, createErrorAdapter } from '@biotmed/data-components';
import AppConfig from 'src/config/AppConfig';
import {
  StyledPortalBuilderPage,
  PortalBuilderTitle,
  SelectorsRow,
  ButtonsWrapper,
  PortalBuilderHeader,
} from './PortalBuilder.styled';
import { adaptAllAttributesToUpdateRequest, mapViewListToAutoCompleteSelectList } from '../modules/mappers';
import { PortalBuilderErrorDictionary, valuesFormatterDictionary } from '../modules/dictionaries';
import { viewsTypesOrder, SaveButtonTypeEnum, LoadViewStatus } from '../modules/constant';
import SaveButton from '../modules/SaveButton';
import ViewTabsSelector from './ViewTabsSelector';

interface PortalBuilderProps {}

const errorAdapter = createErrorAdapter(PortalBuilderErrorDictionary, valuesFormatterDictionary);

const PortalBuilder: React.FC<PortalBuilderProps> = props => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [portal, setPortal] = useState<PortalType>(PortalTypeEnum.MANUFACTURER_PORTAL);
  const [view, setView] = useState<AutocompleteOption | null>(null);
  const [selectedSaveButtonType, setSelectedSaveButtonType] = useState(SaveButtonTypeEnum.NONE);
  const [selectedValues, setSelectedValues] = useState<GetViewResponse>();
  const [manualSelectedViewIndex, setManualSelectedViewIndex] = useState<number>();

  const selectedView = useSelector(selectors.getSelectedView);
  const viewList: View[] = useSelector(selectors.getViewsList);
  const saveButtonStatus: SubmitButtonStatus = useSelector(selectors.getSaveBuilderStatus);
  const loadViewStatus: LoadViewStatus = useSelector(selectors.getLoadBuilderStatus);

  const handleChangeSelectedViewValues = (changedViewValues: GetViewResponse) => {
    setSelectedValues({ ...selectedValues, ...changedViewValues });
  };

  const onLoadViews = (portalValue: PortalType) => dispatch(actions.onLoadViews({ portalType: portalValue }));

  const onLoadSelectedView = (portalValue: PortalType, viewId?: string) => {
    if (viewId) {
      dispatch(actions.onLoadSelectedView({ portalType: portalValue, viewId }));
    }
  };

  const onUpdateView = (portalValue: PortalType, viewId: string, selected: UpdateViewRequest) => {
    if (viewId) {
      dispatch(actions.onUpdateView({ portalType: portalValue, viewId, attributes: selected }));
    }
  };

  const resetSelectedView = () => dispatch(actions.resetSelectedView());

  useEffect(() => {
    setSelectedValues({ ...selectedView });
  }, [selectedView]);

  useEffect(() => {
    onLoadViews(portal);
    return () => {
      resetSelectedView();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (saveButtonStatus === SubmitButtonStatus.SUCCESS) {
      // Finished updating
      if (selectedSaveButtonType === SaveButtonTypeEnum.SAVE_AND_NEXT) {
        const index = viewsOptions.findIndex(val => val.value === view?.value);
        if (!manualSelectedViewIndex) {
          setManualSelectedViewIndex(index);
        }
        const nextIndex = (index + 1) % viewsOptions.length;
        if (manualSelectedViewIndex && manualSelectedViewIndex === nextIndex) {
          // We finished the round
          setManualSelectedViewIndex(undefined);
        } else {
          // Move to the next view
          const nextView = viewsOptions[nextIndex];
          updateSelectedView(nextView);
        }
      }
    } else if (saveButtonStatus === SubmitButtonStatus.NORMAL) {
      setSelectedSaveButtonType(SaveButtonTypeEnum.NONE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveButtonStatus]);

  const portalOptions = [
    {
      value: PortalTypeEnum.MANUFACTURER_PORTAL,
      title: intl.formatMessage({
        id: 'portalBuilder.manufacturerPortalOption',
        defaultMessage: 'Manufacturer Portal',
      }),
    },
    {
      value: PortalTypeEnum.ORGANIZATION_PORTAL,
      title: intl.formatMessage({
        id: 'portalBuilder.organizationPortalOption',
        defaultMessage: 'Organization Portal',
      }),
    },
  ];

  const onPortalChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    setPortal(value as PortalType);
    onLoadViews(value as PortalType);
    setView(null);
    resetSelectedView();
  };

  const updateSelectedView = (value: AutocompleteOption | null) => {
    setView(value);
    if (value?.value) {
      onLoadSelectedView(portal, value?.value);
    } else {
      resetSelectedView();
    }
  };

  const onClickSave = () => {
    if (selectedValues) {
      setSelectedSaveButtonType(SaveButtonTypeEnum.SAVE);
      if (view) {
        onUpdateView(portal, view.value, adaptAllAttributesToUpdateRequest(selectedValues));
      }
    }
  };

  const onClickSaveAndNext = () => {
    if (view && selectedValues && loadViewStatus === LoadViewStatus.SUCCESS) {
      // Update only if we finished loading the view
      onUpdateView(portal, view.value, adaptAllAttributesToUpdateRequest(selectedValues));
      setSelectedSaveButtonType(SaveButtonTypeEnum.SAVE_AND_NEXT);
    }
  };

  const onClickCancel = () => {
    setSelectedValues({ ...selectedView });
  };

  const apiError = useSelector(selectors.getApiError);
  const processedApiError = apiError && errorAdapter(apiError);

  const viewsOptions = useMemo(() => {
    const viewListSorted = viewList
      .slice()
      .sort(
        (a, b) =>
          a?.entityTypeName.localeCompare(b?.entityTypeName) ||
          (a?.template?.displayName ?? '').localeCompare(b?.template?.displayName ?? '') ||
          viewsTypesOrder[a?.type] - viewsTypesOrder[b?.type],
      );
    return mapViewListToAutoCompleteSelectList(viewListSorted, intl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewList]);

  const onLearnMoreClick = () => {
    window.open(AppConfig.PORTAL_BUILDER_LEARN_MORE_URL);
  };

  return (
    <StyledPortalBuilderPage>
      <PortalBuilderHeader>
        <PortalBuilderTitle>
          {intl.formatMessage({ id: 'portalBuilder.mainTitle', defaultMessage: 'Portal Builder' })}
        </PortalBuilderTitle>
        {processedApiError && <ErrorBanner {...processedApiError}>{processedApiError?.message}</ErrorBanner>}
        <Explained
          text={intl.formatMessage({
            id: 'portalBuilder.explained',
            defaultMessage: "Portal Builder enable you to customize BioT's portals to your needs",
          })}
          onLearnMore={onLearnMoreClick}
        />
        <SelectorsRow>
          <SingleSelect
            value={portal}
            selectList={portalOptions}
            placeholder={intl.formatMessage({
              id: 'portalBuilder.portalSelectPlaceholder',
              defaultMessage: 'Select Portal',
            })}
            onChange={onPortalChange}
          />
          <SelectAutoComplete
            value={view}
            options={viewsOptions}
            inputProps={{
              placeholder: intl.formatMessage({
                id: 'portalBuilder.viewSelectPlaceholder',
                defaultMessage: 'Select View/Component',
              }),
              fullWidth: false,
            }}
            onChange={(event, value) => {
              updateSelectedView(value);
            }}
          />
        </SelectorsRow>
      </PortalBuilderHeader>
      {selectedValues && <ViewTabsSelector selectedValues={selectedValues} onChange={handleChangeSelectedViewValues} />}
      {selectedView && (
        <ButtonsWrapper>
          <Button paddingHorizontal="32px" paddingVertical="15px" size="medium" onClick={onClickCancel}>
            {intl.formatMessage({
              id: 'portalBuilder.cancel-button',
              defaultMessage: 'Cancel',
            })}
          </Button>
          <SaveButton
            buttonType={SaveButtonTypeEnum.SAVE}
            onClick={onClickSave}
            disabled={
              selectedSaveButtonType !== SaveButtonTypeEnum.SAVE && selectedSaveButtonType !== SaveButtonTypeEnum.NONE
            }
            submitButtonProps={{
              status: selectedSaveButtonType === SaveButtonTypeEnum.SAVE ? saveButtonStatus : undefined,
            }}
          />
          <SaveButton
            buttonType={SaveButtonTypeEnum.SAVE_AND_NEXT}
            onClick={onClickSaveAndNext}
            disabled={
              selectedSaveButtonType !== SaveButtonTypeEnum.SAVE_AND_NEXT &&
              selectedSaveButtonType !== SaveButtonTypeEnum.NONE
            }
            submitButtonProps={{
              status: selectedSaveButtonType === SaveButtonTypeEnum.SAVE_AND_NEXT ? saveButtonStatus : undefined,
            }}
          />
        </ButtonsWrapper>
      )}
    </StyledPortalBuilderPage>
  );
};

export default PortalBuilder;
