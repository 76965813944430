import styled from 'styled-components';
import { convertTypographySettingsToCss } from '@biotmed/base-components';
import { Chip } from '@mui/material';

export const StyledNameSection = styled.div`
  // width: 100%;
  flex: auto;
  cursor: pointer;
  & .rootControl {
    border-radius: 0;
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.grayScale.light};
    }
    .MuiInputBase-adornedEnd {
      background-color: ${props => props.theme.palette.grayScale.light};
    }
    .MuiOutlinedInput-input {
      background-color: ${props => props.theme.palette.grayScale.light};
      ${props => convertTypographySettingsToCss(props.theme.typography.h7)}
    }
    .MuiOutlinedInput-adornedEnd {
      padding-right: 5px;
    }
  }
`;

export const StyledTitle = styled.div`
  flex-direction: row;
  ${props => convertTypographySettingsToCss(props.theme.typography.h7)}
  padding-left: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledJSONButton = styled(Chip)`
  &.chipControl {
    border-radius: 4px;
    background-color: ${props => props.theme.palette.primary.medium};
    color: ${props => props.theme.palette.grayScale.lightest};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30px;

    line-height: 0;
  }

  &.chipControl:hover {
    background-color: ${props => `${props.theme.palette.primary.medium}${props.theme.opacity.halfTransparent}`};
  }
`;

export const StyledJSONIcon = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

// eslint-disable-next-line no-shadow
export enum JSONIconTextSizeOptions {
  BIG = 'big',
  SMALL = 'small',
}

interface StyledJSONIconTextProps {
  size: JSONIconTextSizeOptions;
}

export const StyledJSONIconText = styled.div<StyledJSONIconTextProps>`
  font-size: ${props =>
    props.size === JSONIconTextSizeOptions.BIG
      ? '1.2rem'
      : '0.8rem'}; // hardCoded because it's an icon special case - approved by Adi
  height: ${props => (props.size === JSONIconTextSizeOptions.BIG ? '1.2rem' : 0)};
  text-align: center;
`;

export const TypeSectionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 180px; /* TODO: change px */
`;

export const StyledPHISection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  ${props => convertTypographySettingsToCss(props.theme.typography.button.small)}
  color: ${props => props.theme.palette.grayScale.medium};
`;

export const StyledBuiltInMessage = styled.div`
  padding: 5px 11px;
  border-radius: 50vh;
  background-color: ${props => props.theme.palette.grayScale.medium};
  color: ${props => props.theme.palette.grayScale.darker};
  ${props => convertTypographySettingsToCss(props.theme.typography.button.small)}
  text-align: center;
`;

export const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

export const FlexSeparator = styled.div`
  flex: 1;
`;

export const StyledRequired = styled.span`
  ${props => convertTypographySettingsToCss(props.theme.typography.button.small)}
  > span {
    padding-right: 5px;
    color: ${props => props.theme.palette.grayScale.darker};
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const NewLinkedNameSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  align-items: center;
`;
