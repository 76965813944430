/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'core-js';

// eslint-disable-next-line no-use-before-define
import React from 'react';
import ReactDOM from 'react-dom';

import UmsLogic from '@biot/ums-js-logic';
import { initSdkApi } from '@biotmed/sdk-api-provider';
import { Provider } from 'react-redux';
import AppConfig from 'src/config/AppConfig';
// FIXME: Fix SSO.
import { CHANGED_OUTSIDE_EVENT, CookiesStorage, refreshOnExternalLoginChange } from '@biotmed/data-components';
import configureStore from './redux';
import App from './routes/Root';

import { actions as loginActions } from './redux/data/login';

// Create redux store with session state
const initialState = {};

const store = configureStore(initialState);
// FIXME: Fix SSO.
const cookieSessionStorage = new CookiesStorage();
cookieSessionStorage.addEventListener(CHANGED_OUTSIDE_EVENT, refreshOnExternalLoginChange as (evt: Event) => void);

UmsLogic.init({
  umsBaseURL: `${AppConfig.API_URL}/ums`,
  onRefreshTokenFail: () => {
    store.dispatch(loginActions.logoutFinish());

    // eslint-disable-next-line no-console
    console.log('refreshTokenFailed');
  },
  localStorage,
  sessionStorage: cookieSessionStorage,
});

initSdkApi({ basePath: `${AppConfig.API_URL}`, tokenManager: UmsLogic });
const MOUNT_NODE = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  MOUNT_NODE,
);
// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line global-require,import/no-extraneous-dependencies
  require('offline-plugin/runtime').install();
}
