import { getSdkApi } from '@biotmed/sdk-api-provider';
import { AxiosResponse } from 'axios';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import {
  CreateTemplateRequest,
  ErrorResponse,
  GetTemplateResponse,
  BuiltInAttributeTypeEnum,
} from '@biotmed/settings-sdk';
import { enqueueSnack } from '@biotmed/base-components';
import { IntlFormatMessageParams } from '@biotmed/base-components/lib/types';
import {
  mapChildTemplateToCreateTemplateRequest,
  mapTemplateToCreateTemplateRequest,
  mapTemplateToUpdateTemplateRequest,
} from 'src/redux/data/template/modules/mappers';
import { EntityTypeEnum, EntityTypeToIntlDisplayNameMap } from '@biotmed/data-components';
import intl from '@biotmed/i18n';
import { actions, InUseCustomAttribute, selectors } from './slice';
import { TemplateSagaDictionary, TemplateSagaDictionaryKey } from './dictionaries';
import { CUSTOM_ATTRIBUTE_IN_USE_ERROR_CODE } from './constants';
import { Template } from '../../entity/modules/interfaces';

const SUCCESS_DELAY = 500;
let snacksCounter = 0;
function* onLoadTemplate(action: ReturnType<typeof actions.onLoadTemplate>): any {
  yield call(loadTemplates);
}

function* loadTemplates(): any {
  try {
    const searchRequest = yield select(selectors.selectSearchRequest);
    const response: AxiosResponse<any> = yield call(getSdkApi().settings.templatesApi.search, searchRequest);
    const templatesList = response?.data?.data;
    const templatesTotal = response?.data?.metadata?.page?.totalResults;
    yield put(actions.onLoadTemplatesSuccess({ templatesList, templatesTotal }));
  } catch (e: any) {
    console.error(e);
    const apiError = (e?.response?.data || e) as ErrorResponse;
    yield put(actions.onLoadTemplatesFail(apiError));
  }
}

function* onLoadAllTemplate(): any {
  try {
    const response: AxiosResponse<any> = yield call(getSdkApi().settings.templatesApi.minimalSearch, { limit: 1000 });
    const templatesList = response?.data.data;
    yield put(actions.onLoadAllTemplatesSuccess(templatesList));
  } catch (e: any) {
    console.error(e);
    const apiError = (e?.response?.data || e) as ErrorResponse;
    yield put(actions.onLoadTemplatesFail(apiError));
  }
}

function* createTemplate(template: CreateTemplateRequest): any {
  return yield call(getSdkApi().settings.templatesApi.createTemplate, template);
}

function updateChildrenBuiltInAttributes(
  childTemplate: Template,
  parentEntityTypeName: string,
  parentTemplateId: string,
) {
  const builtInAttributes = childTemplate.builtInAttributes?.map(builtInAttribute => {
    let updatedBuiltInAttribute = { ...builtInAttribute };
    if (
      updatedBuiltInAttribute.type === BuiltInAttributeTypeEnum.Reference &&
      updatedBuiltInAttribute.referenceConfiguration?.entityType &&
      updatedBuiltInAttribute.referenceConfiguration.entityType === parentEntityTypeName
    ) {
      updatedBuiltInAttribute = {
        ...updatedBuiltInAttribute,
        referenceConfiguration: {
          ...updatedBuiltInAttribute.referenceConfiguration,
          validTemplatesToReference: [parentTemplateId] as unknown as Set<string>,
        },
      };
    }

    return updatedBuiltInAttribute;
  });

  return builtInAttributes;
}

function* onCreateTemplate(action: ReturnType<typeof actions.createTemplate>): any {
  const { entityTemplate, childrenTemplates } = action.payload;
  try {
    const createResponse = yield call(createTemplate, mapTemplateToCreateTemplateRequest(entityTemplate));
    const parentTemplateId = createResponse.data.id;
    if (childrenTemplates) {
      let childTemplate: any;
      try {
        for (let index = 0; index < childrenTemplates.length; index++) {
          childTemplate = childrenTemplates[index];
          // Updating the validTemplateToReference in each built in reference attribute that references to the parent's entity type
          // to include only the parent template id
          const builtInAttributes = updateChildrenBuiltInAttributes(
            childTemplate,
            entityTemplate.entityTypeName || '',
            parentTemplateId,
          );

          const childTemplateReq = {
            ...childTemplate,
            parentTemplateId,
            ownerOrganizationId: entityTemplate.ownerOrganizationId,
            builtInAttributes,
          };

          yield call(createTemplate, mapChildTemplateToCreateTemplateRequest(childTemplateReq));
        }
        // Success All
        yield put(
          enqueueSnack({
            key: `createTemplateSuccess${snacksCounter++}`,
            message: [
              TemplateSagaDictionary[TemplateSagaDictionaryKey.CREATE_SUCCESS],
              { name: entityTemplate?.displayName },
            ] as IntlFormatMessageParams,
            options: {},
          }),
        );
        yield call(loadTemplates);
      } catch (e: any) {
        // Partially failed
        yield put(
          enqueueSnack({
            key: `createTemplateSuccessPartial${snacksCounter++}`,
            message: [
              TemplateSagaDictionary[TemplateSagaDictionaryKey.CREATE_SUCCESS_PARTIAL],
              { name: entityTemplate?.displayName },
            ] as IntlFormatMessageParams,

            options: {},
          }),
        );
        const apiError = (e?.response?.data || e) as ErrorResponse;
        yield call(loadTemplates);
        yield put(actions.closeModal());

        yield put(
          actions.createTemplateFailedPartial({
            error: apiError,
            additionalData: {
              entityName: intl.current.formatMessage(
                EntityTypeToIntlDisplayNameMap[childTemplate.entityTypeName as EntityTypeEnum],
              ),
            },
          }),
        );
      }
    }
    // Partially/All Success
    yield put(actions.createTemplateSuccess());
    yield delay(SUCCESS_DELAY);
    yield put(actions.closeModal());
  } catch (e: any) {
    console.error(e);
    // Failed All
    const apiError = (e?.response?.data || e) as ErrorResponse;
    yield put(actions.createTemplateFail(apiError));
  }
}

function* editTemplate(action: ReturnType<typeof actions.editTemplate>): any {
  const {
    templateId,
    entityTemplate,
    childrenTemplates,
    originalChildrenTemplates,
    forceUpdate = false,
  } = action.payload;
  try {
    yield call(getSdkApi().settings.templatesApi.updateTemplate, templateId, {
      ...mapTemplateToUpdateTemplateRequest(entityTemplate),
      forceUpdate,
    });

    const deletedChildrenTemplates = originalChildrenTemplates
      ?.slice()
      ?.filter(
        (childTemplate: GetTemplateResponse) => !childrenTemplates.map(child => child.id).includes(childTemplate.id),
      );

    let childTemplate: any;

    try {
      if (deletedChildrenTemplates) {
        for (let index = 0; index < deletedChildrenTemplates.length; index++) {
          childTemplate = deletedChildrenTemplates[index];
          yield call(getSdkApi().settings.templatesApi.deleteTemplate, childTemplate.id);
        }
      }
      if (childrenTemplates) {
        for (let index = 0; index < childrenTemplates.length; index++) {
          childTemplate = childrenTemplates[index];
          if (childTemplate?.id) {
            const childTemplateReq = mapTemplateToUpdateTemplateRequest({
              ...childTemplate,
              ownerOrganizationId: entityTemplate.ownerOrganizationId,
            });
            yield call(getSdkApi().settings.templatesApi.updateTemplate, childTemplate?.id, childTemplateReq);
          } else {
            // Updating the validTemplateToReference in each built in reference attribute that references to the parent's entity type
            // to include only the parent template id
            const builtInAttributes = updateChildrenBuiltInAttributes(
              childTemplate,
              entityTemplate.entityTypeName || '',
              templateId,
            );

            const childTemplateReq = mapChildTemplateToCreateTemplateRequest({
              ...childTemplate,
              parentTemplateId: templateId,
              builtInAttributes,
            });

            yield call(getSdkApi().settings.templatesApi.createTemplate, childTemplateReq);
          }
        }
      }
      yield put(
        enqueueSnack({
          key: `updateTemplateSuccess${snacksCounter++}`,
          message: [
            TemplateSagaDictionary[TemplateSagaDictionaryKey.UPDATE_SUCCESS],
            { name: entityTemplate?.displayName },
          ] as IntlFormatMessageParams,
          options: {},
        }),
      );
      yield call(loadTemplates);
      yield put(actions.editTemplateSuccess());
      yield put(actions.closeModal());
    } catch (e: any) {
      yield put(
        enqueueSnack({
          key: `updateTemplateSuccessPartial${snacksCounter++}`,
          message: [
            TemplateSagaDictionary[TemplateSagaDictionaryKey.UPDATE_SUCCESS_PARTIAL],
            { name: entityTemplate?.displayName },
          ] as IntlFormatMessageParams,
          options: {},
        }),
      );
      yield call(loadTemplates);
      yield put(actions.editTemplateSuccess());
      yield put(actions.closeModal());

      const apiError = (e?.response?.data || e) as ErrorResponse;

      yield put(
        actions.editTemplateFailedPartial({
          error: apiError,
          additionalData: {
            entityName: intl.current.formatMessage(
              EntityTypeToIntlDisplayNameMap[childTemplate.entityTypeName as EntityTypeEnum],
            ),
          },
        }),
      );
    }
  } catch (e: any) {
    const apiError = (e?.response?.data || e) as ErrorResponse;
    if (apiError?.code === CUSTOM_ATTRIBUTE_IN_USE_ERROR_CODE && apiError?.details?.attributes) {
      yield put(actions.onCustomAttributeInUseError(apiError.details.attributes as unknown as InUseCustomAttribute[]));
    } else {
      yield put(actions.editTemplateFail(apiError));
    }
  }
}

function* deleteTemplate(action: ReturnType<typeof actions.deleteTemplate>): any {
  const { templateId, templateName } = action.payload;
  try {
    yield call(getSdkApi().settings.templatesApi.deleteTemplate, templateId);
    yield call(loadTemplates);
    yield put(actions.deleteTemplateSuccess());
    yield put(
      enqueueSnack({
        key: `deleteTemplateSuccess${snacksCounter++}`,
        message: [
          TemplateSagaDictionary[TemplateSagaDictionaryKey.DELETE_SUCCESS],
          { name: templateName },
        ] as IntlFormatMessageParams,
        options: {},
      }),
    );
  } catch (e: any) {
    const apiError = (e?.response?.data || e) as ErrorResponse;
    yield put(actions.deleteTemplateFailed(apiError));
  }
}
export default function* watchEntityActions() {
  yield all([
    takeLatest(actions.onLoadTemplate, onLoadTemplate),
    takeLatest(actions.onLoadAllTemplate, onLoadAllTemplate),
    takeLatest(actions.createTemplate, onCreateTemplate),
    takeLatest(actions.editTemplate, editTemplate),
    takeLatest(actions.deleteTemplate, deleteTemplate),
  ]);
}
