import { getSdkApi } from '@biotmed/sdk-api-provider';
import { GetTemplateResponse } from '@biotmed/settings-sdk';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { childrenTemplatesFieldName } from '../modules/constant';
import { EntityTemplateForm } from './TemplateForm';

interface LoadChildrenTemplateProps {
  templateId: string;
  setOriginalChildrenTemplates: (value: GetTemplateResponse[]) => void;
}

const LoadChildrenTemplate: React.FC<LoadChildrenTemplateProps> = props => {
  const { templateId, setOriginalChildrenTemplates } = props;
  const formik = useFormikContext<EntityTemplateForm>();

  useEffect(() => {
    if (templateId) {
      getChildrenTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  const getChildrenTemplates = async () => {
    formik.setStatus({
      ...formik.status,
      isLoadingChildren: true,
    });

    try {
      // if there are more than 100 results, we need to add an iterator that receives a request
      // and returns all of the results from the BE
      const response = await getSdkApi().settings.templatesApi.search({
        filter: {
          parentTemplateId: {
            in: [templateId],
          },
        },
      });

      const childrenTemplates = response?.data?.data.sort((a: any, b: any) =>
        a?.displayName?.localeCompare(b?.displayName),
      );

      formik.setStatus({
        ...formik.status,
        isLoadingChildren: false,
        childrenTemplates,
      });

      formik.setFieldValue(childrenTemplatesFieldName, childrenTemplates);
      setOriginalChildrenTemplates(childrenTemplates);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to get children templates e', e);
    }
  };

  return null;
};

export default LoadChildrenTemplate;
