import React from 'react';
import { Item } from '@biotmed/base-components/lib/components/DraggableList/DraggableListItem';
import { EntityTypeToIntlDisplayNameMap, EntityType } from '@biotmed/data-components';
import {
  AllPortalAttributesGroup,
  AvailablePortalAttribute,
  GetViewResponse,
  MeasurementPortalAttributes,
  ReverseReferenceTabs,
  SelectedPortalAttribute,
  SelectedPortalAttributesGroup,
  UpdateViewRequest,
  View,
  ViewTypeEnum,
} from '@biotmed/settings-sdk';
import { IntlShape } from 'react-intl';
import { fallbackMessage } from 'src/routes/Templates/modules/constant';
import { viewTypesDictionary } from './dictionaries';

export const mapDraggableItemsToSelectedAttributes = (
  items: Item[],
  mapAttributes: Record<string, AvailablePortalAttribute>,
) => {
  return items.map((item: any) => ({
    name: mapAttributes[item.id]?.name,
    basePath: mapAttributes[item.id]?.basePath,
  }));
};

export const mapSelectedAttributesToDraggableItems = (
  selectedAttributes: Array<SelectedPortalAttribute>,
  mapAttributes: Record<string, AvailablePortalAttribute>,
) =>
  selectedAttributes.map((selected: SelectedPortalAttribute) => ({
    id: `${selected.basePath ?? ''}${selected.name}`,
    content: <div>{mapAttributes[`${selected.basePath ?? ''}${selected.name}`]?.displayName}</div>,
  }));

export const mapAttributesToAttributesRow = (attributesList: Array<AvailablePortalAttribute>) => {
  return attributesList.map(attribute => ({
    attribute: attribute.displayName,
    key: attribute.name,
    basePath: attribute.basePath,
    readOnly: attribute.readOnly,
  }));
};

export const mapViewListToAutoCompleteSelectList = (viewList: Array<View>, intl: IntlShape) =>
  viewList.map((viewItem: View) => {
    const viewLabelsMap = mapViewLabel(viewItem, intl);
    return {
      value: viewItem.id,
      label: viewLabelsMap[viewItem.type] || viewLabelsMap.default,
    };
  });

export const mapViewLabel = (viewItem: View, intl: IntlShape) => {
  const { entityTypeName, type, template } = viewItem;
  const entityType = intl.formatMessage(
    EntityTypeToIntlDisplayNameMap[entityTypeName as EntityType] || fallbackMessage(entityTypeName),
  );
  const viewType = intl.formatMessage(viewTypesDictionary[type] || fallbackMessage(type));
  return {
    [ViewTypeEnum.EntityList]: `${entityType} - ${viewType}`,
    [ViewTypeEnum.TemplateExpand]: `${entityType} - ${template?.displayName} - ${viewType}`,
    [ViewTypeEnum.TemplatePreview]: `${entityType} - ${template?.displayName} - ${viewType}`,
    [ViewTypeEnum.TemplateList]: `${entityType} - ${template?.displayName} - ${viewType}`,
    default: `${entityType} - ${template?.displayName || ''} - ${viewType}`,
  };
};

export const mapAttributes = (attributes: Array<AvailablePortalAttribute>) =>
  attributes.reduce<Record<string, AvailablePortalAttribute>>((obj, item) => {
    const object = obj;
    object[`${item.basePath ?? ''}${item.name}`] = item;
    return object;
  }, {});

export const adaptAllAttributesToUpdateRequest: (selectedValues: GetViewResponse) => UpdateViewRequest = (
  selectedValues: GetViewResponse,
) => ({
  ...mapAllPortalAttributesGroupToUpdateViewRequest(selectedValues?.attributes),
  ...mapMeasurementsToAttributeBuilderDataStructure(selectedValues?.measurements),
  ...mapReverseReferenceTabsToAttributeBuilderDataStructure(selectedValues?.reverseReferenceTabs),
});

export const mapAllPortalAttributesGroupToUpdateViewRequest = (attributes?: Array<AllPortalAttributesGroup>) => ({
  attributes: attributes
    ? attributes.map(attribute => ({
        classification: attribute.classification,
        selected: attribute.selected,
      }))
    : undefined,
});

export const mapMeasurementsToAttributeBuilderDataStructure = (measurements?: MeasurementPortalAttributes) => ({
  measurements: measurements ? measurements.selected : undefined,
});

export const mapReverseReferenceTabsToAttributeBuilderDataStructure = (
  reverseReferenceTabs?: ReverseReferenceTabs,
) => ({
  reverseReferenceTabs: reverseReferenceTabs ? reverseReferenceTabs.selected : undefined,
});

export const mapSelectedToState = (
  selectedItems: any[],
  itemsToMap?: Array<SelectedPortalAttributesGroup> | Array<any>,
  classification?: string,
) =>
  itemsToMap?.map((attribute: AllPortalAttributesGroup) =>
    attribute.classification === classification
      ? { classification: attribute.classification, selected: selectedItems }
      : { classification: attribute.classification, selected: attribute.selected },
  );

// TODO: this might need a special interface that draws from items in the sdk for measurements
export const mapPortalMeasurementsBackToUpdateViewRequest = (measurements: any) => {
  const measurementsRequestItems: { [X in string]: any } = {};

  measurements.forEach((item: any) => {
    if (item.classification) measurementsRequestItems[String(item.classification)] = item.selected;
  });
  return measurementsRequestItems;
};

export const filterSelectedAttributesByExistAttributes = (
  selectedAttributes: Array<SelectedPortalAttribute>,
  mappedAttributes: any,
) => {
  return selectedAttributes.filter(selected => {
    const key = selected.basePath ? selected.basePath + selected.name : selected.name;
    return mappedAttributes[key as string];
  });
};
