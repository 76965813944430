import { DrawerModal, Variant } from '@biotmed/base-components';
import { GetTemplateResponse } from '@biotmed/settings-sdk';
import React from 'react';
import { useSelector } from 'react-redux';
import { Entity } from 'src/redux/data/entity/modules/interfaces';
import { selectors as templateSelectors } from 'src/redux/data/template';
import { errorAdapters } from '../modules/constant';
import AddTemplate from './AddTemplate';
import EditTemplate from './EditTemplate';
import { EntityTemplateForm } from './TemplateForm';

export const TemplateModeEnum = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

const modeMap = {
  [TemplateModeEnum.ADD]: AddTemplate,
  [TemplateModeEnum.EDIT]: EditTemplate,
};

export type Mode = keyof typeof modeMap;

export interface TemplateProps {
  open: boolean;
  entity: Entity;
  mode: Mode;
  template: GetTemplateResponse;
  handleClose: () => void;
  variant?: Variant;
  widthDiff?: string;
  onFormSubmit: (
    entityType: string,
    templateId: string,
    values: EntityTemplateForm,
    originalChildrenTemplates: any,
    forceUpdate: boolean,
  ) => void;
}

const Template: React.FC<TemplateProps> = props => {
  const { open, entity, mode, template, handleClose, onFormSubmit, variant = 'halfScreen', widthDiff } = props;
  const ModalChildren = modeMap[mode];

  const apiError = useSelector(templateSelectors.getApiError);
  const submitButtonStatus = useSelector(templateSelectors.getAddEditStatus);

  return (
    <DrawerModal disableEnforceFocus variant={variant} open={open} widthDiff={widthDiff}>
      <ModalChildren
        entity={entity}
        template={template}
        handleClose={handleClose}
        apiError={apiError}
        submitButtonStatus={submitButtonStatus}
        errorAdapter={errorAdapters[mode]}
        submitForm={onFormSubmit}
      />
    </DrawerModal>
  );
};

export default Template;
