import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'src/redux/data/template';
import { Button } from '@biotmed/base-components';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { InUseCustomAttribute } from 'src/redux/data/template/modules/slice';
import {
  ButtonsSection,
  Content,
  ErrorPopupContent,
  ForceDeleteButton,
  InUseAttributesList,
  MessageHeader,
} from './InUseError.styled';
import { EntityTemplateForm } from '../../TemplateForm';
import { inUseIntlMessages as messages } from './intlMessages';

interface InUseErrorProps {
  onSubmitForced: (values: EntityTemplateForm) => void;
}

export const InUseError = (props: InUseErrorProps) => {
  const { onSubmitForced } = props;

  const formik = useFormikContext<EntityTemplateForm>();
  const { values, initialValues, setValues } = formik;

  const inUseCustomAttributes = useSelector(selectors.getInUseCustomAttributes);
  const dispatch = useDispatch();

  const approveDeleteInUseAttributes = () => dispatch(actions.onCustomAttributeInUseErrorApprove());
  const cancelDeleteInUseAttributes = () => dispatch(actions.onCustomAttributeInUseErrorCancel());

  const setRevertedValues = () => {
    const updatedCustomAttributes = [...(values?.entityTemplate?.customAttributes ?? [])];

    if (inUseCustomAttributes && Array.isArray(initialValues?.entityTemplate?.customAttributes)) {
      for (const inUseAttr of inUseCustomAttributes) {
        const initialInUseAttribute = initialValues?.entityTemplate?.customAttributes?.find(
          attr => attr.id === inUseAttr.id,
        );
        if (initialInUseAttribute) {
          const index = updatedCustomAttributes?.findIndex(attr => attr.id === inUseAttr.id); // find the original position
          if (index >= 0) {
            updatedCustomAttributes[index] = initialInUseAttribute;
          }
        }
      }
    }

    setValues({
      ...values,
      entityTemplate: {
        ...values.entityTemplate,
        customAttributes: updatedCustomAttributes,
      },
    });
  };

  const handleCancel = () => {
    setRevertedValues();
    cancelDeleteInUseAttributes();
  };

  const handleApprove = () => {
    onSubmitForced(values);
    approveDeleteInUseAttributes();
  };
  const intl = useIntl();

  const getDisplayNameLi = (attr: InUseCustomAttribute) =>
    initialValues?.entityTemplate?.customAttributes?.find(oldAttr => oldAttr.id === attr.id) || null;

  return (
    <ErrorPopupContent>
      <MessageHeader>{intl.formatMessage(messages.messageHeader)}</MessageHeader>
      <Content>
        {intl.formatMessage(messages.contentPartOne)}
        <InUseAttributesList>
          {inUseCustomAttributes?.map((attr: InUseCustomAttribute) => (
            <li>{getDisplayNameLi(attr)?.displayName || attr.name}</li>
          ))}
        </InUseAttributesList>
        {intl.formatMessage(messages.contentPartTwo)}
      </Content>

      <ButtonsSection>
        <div>
          <Button size="large" variant="outlined" onClick={handleCancel}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        </div>
        <div>
          <ForceDeleteButton size="large" variant="contained" onClick={handleApprove}>
            {intl.formatMessage(messages.continue)}
          </ForceDeleteButton>
        </div>
      </ButtonsSection>
    </ErrorPopupContent>
  );
};
