/**
 * Update user details on login. Intercom.('startTour', { name: 'Adi' })
 * Update user details on logout.
 */
import { all, AllEffect, ForkEffect, takeLatest } from 'redux-saga/effects';
import { actions as loginActions } from '../redux/data/login';
import { actions as appActions } from '../redux/data/app';
import { actions as userActions } from '../redux/data/user';
import { intercomStaticAttributes } from './constants';

declare global {
  interface Window {
    Intercom: any;
  }
}

/* eslint-disable require-yield */
function* onUserDetailsLoaded(action: ReturnType<typeof userActions.userDetailsLoaded>) {
  const { payload } = action;

  const { _id, _email, _name, _phone, _ownerOrganization } = payload;
  const { name: ownerOrganizationName, id: ownerOrganizationId } = _ownerOrganization;

  window.Intercom('update', {
    user_id: _id,
    name: `${_name.firstName} ${_name.lastName}`,
    phone: _phone,
    email: _email,
    ...intercomStaticAttributes,
    company: { ...intercomStaticAttributes.company, name: ownerOrganizationName, id: ownerOrganizationId },
    application_name: 'Console',
  });
}

// TODO: Check if there is a better way on intercom.
// Added shutdown and boot because intercom save user session on local storage
// But our web-app doesnt so we want to reset it everytime the app start / logout.
function* resetIntercom() {
  window.Intercom('shutdown');

  window.Intercom('boot');
}

export default function* watch(): Generator<AllEffect<ForkEffect<never>>, void, void> {
  yield all([
    takeLatest(userActions.userDetailsLoaded, onUserDetailsLoaded),
    takeLatest(loginActions.logout, resetIntercom),
    takeLatest(appActions.appStart, resetIntercom),
  ]);
}
