import React from 'react';
import { ReverseReferenceTabs, SelectedPortalAttribute } from '@biotmed/settings-sdk';
import { useIntl } from 'react-intl';

import { SectionTitle, AttributesWrapper, SectionWrapper } from '../PortalBuilder.styled';

import PortalViewCategoryRenderer from '../PortalViewCategoryRenderer/PortalViewCategoryRenderer';

interface TabsBuilderProps {
  tabsAttributes: ReverseReferenceTabs;
  onChange: (values: any) => void;
  fullScreen: boolean;
}

const TabsBuilder: React.FC<TabsBuilderProps> = props => {
  const { tabsAttributes, onChange, fullScreen = false } = props;

  const intl = useIntl();

  const handleChangeTabs = (selected: SelectedPortalAttribute[]) => {
    onChange({
      selected,
    });
  };

  return (
    <SectionWrapper>
      <SectionTitle>
        {intl.formatMessage({
          id: 'portalBuilder.ReversedReferenceTabsSectionTitle',
          defaultMessage: 'Reversed Reference Tabs Section - select tabs, then order',
        })}
      </SectionTitle>
      <AttributesWrapper>
        <PortalViewCategoryRenderer
          key="reversed-reference"
          title={intl.formatMessage({
            id: 'portalBuilder.ReversedReferenceTabsTitle',
            defaultMessage: 'Reversed Reference',
          })}
          attributes={tabsAttributes.attributes}
          selected={tabsAttributes?.selected}
          onChange={handleChangeTabs}
          fullScreen={fullScreen}
        />
      </AttributesWrapper>
    </SectionWrapper>
  );
};

export default TabsBuilder;
