import { Radio } from '@biotmed/base-components';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import TransparentInput from 'src/components/TransparentInput';
import { EntityTemplateForm } from 'src/routes/Templates/components/TemplateForm';
import { TypeComponentProps, TypeVariantComponentProps } from '../interfaces';
import Range from '../NumericType/Range';
import TypeComponentContainer from '../TypeComponent';
import { RangeContainer, WaveformContainer, SubTypesContainer } from './WaveformType.styled';

interface WaveformTypeVariantProps extends TypeVariantComponentProps {}

interface WaveformTypeProps extends TypeComponentProps {}

const WaveformType: React.FC<WaveformTypeProps> = props => {
  const { variant, attrFieldName, attributeValues, readonly } = props;
  const variantMap: Record<typeof variant, any> = {
    custom: CustomWaveformType,
    builtIn: BuiltInWaveformType,
  };
  const TypeComponent = variantMap[variant];

  return <TypeComponent attrFieldName={attrFieldName} attributeValues={attributeValues} readonly={readonly} />;
};

const CustomWaveformType: React.FC<WaveformTypeVariantProps> = props => {
  return <WaveformTypeAttribute {...props} />;
};

const BuiltInWaveformType: React.FC<WaveformTypeVariantProps> = props => {
  return <WaveformTypeAttribute {...props} />;
};

const WAVEFORM_SUB_TYPES = {
  INTEGER: 'INTEGER',
  DECIMAL: 'DECIMAL',
};

const WaveformTypeAttribute: React.FC<WaveformTypeVariantProps> = props => {
  const { attrFieldName, attributeValues, attributeErrors, attributeTouched, readonly = false } = props;

  const intl = useIntl();
  const formik = useFormikContext<EntityTemplateForm>();
  const [subType, setSubType] = useState(attributeValues?.numericMetaData?.subType ?? WAVEFORM_SUB_TYPES.INTEGER);
  const [unit, setUnit] = useState(attributeValues?.numericMetaData?.units ?? '');
  const minError = attributeTouched?.validation?.min && attributeErrors?.validation?.min;
  const maxError = attributeTouched?.validation?.max && attributeErrors?.validation?.max;

  useEffect(() => {
    if (!attributeValues?.numericMetaData?.subType) {
      formik.setFieldValue(`${attrFieldName}.numericMetaData.subType`, WAVEFORM_SUB_TYPES.INTEGER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeMin = (value: number | null) => {
    formik.setFieldValue(`${attrFieldName}.validation.min`, value);
  };

  const handleChangeMax = (value: number | null) => {
    formik.setFieldValue(`${attrFieldName}.validation.max`, value);
  };

  const handleSubTypeChange = (value: any) => {
    if (value !== subType) {
      handleChangeMin(null);
      handleChangeMax(null);
    }

    formik.setFieldValue(`${attrFieldName}.numericMetaData.subType`, value);
    setSubType(value);
  };

  const handleChangeUnit = (event: any) => {
    const { value } = event.target;
    setUnit(value);
    formik.setFieldValue(`${attrFieldName}.numericMetaData.units`, value);
  };

  return (
    <TypeComponentContainer readonly={readonly}>
      <WaveformContainer>
        <SubTypesContainer>
          <Radio
            name={`${attrFieldName}.numericMetaData.subType`}
            radioObjects={[
              {
                label: intl.formatMessage({
                  id: 'template.modal.attribute.WaveformType-subType.integer',
                  defaultMessage: 'Integer',
                }),
                value: WAVEFORM_SUB_TYPES.INTEGER,
                isChecked: subType === WAVEFORM_SUB_TYPES.INTEGER,
              },
              {
                label: intl.formatMessage({
                  id: 'template.modal.attribute.WaveformType-subType.decimal',
                  defaultMessage: 'Decimal',
                }),
                value: WAVEFORM_SUB_TYPES.DECIMAL,
                isChecked: subType === WAVEFORM_SUB_TYPES.DECIMAL,
              },
            ]}
            onChangeRadio={handleSubTypeChange}
            value={subType}
          />
        </SubTypesContainer>
        <RangeContainer>
          <Range
            title={intl.formatMessage({
              id: 'template.modal.attribute.numeric-type.value-range.title',
              defaultMessage: 'Value Range',
            })}
            minValue={attributeValues?.validation?.min}
            maxValue={attributeValues?.validation?.max}
            attrFieldName={attrFieldName}
            onChangeMin={handleChangeMin}
            onChangeMax={handleChangeMax}
            integer={subType === WAVEFORM_SUB_TYPES.INTEGER}
            minError={minError}
            maxError={maxError}
            readonly={readonly}
          />
        </RangeContainer>
        <TransparentInput
          value={unit}
          fullWidth={false}
          onChange={handleChangeUnit}
          name={`${attrFieldName}.numericMetaData.units`}
          placeholder={intl.formatMessage({
            id: 'template.modal.attribute.NumericType-unit-placeholder',
            defaultMessage: 'Set Unit',
          })}
          readonly={readonly}
        />
      </WaveformContainer>
    </TypeComponentContainer>
  );
};

export default WaveformType;
