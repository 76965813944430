import React from 'react';
import { AuthSwitch, LoginResponse } from '@biotmed/auth-pages';
import { useDispatch } from 'react-redux';
import { actions as loginActions } from 'src/redux/data/login/modules/slice';
import { useIntl } from 'react-intl';
import appConfig from 'src/config/AppConfig';
import { LanguagePicker } from '@biotmed/i18n';
import OrganizationImage from '../../components/OrganizationImage';
import { isAuthorized } from '../../utils/auth';

export type { LoginResponse };

export default () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const onLoginSuccess = (loginResponse: LoginResponse) => {
    dispatch(loginActions.loginSuccess({ loginResponse }));
  };

  return (
    <AuthSwitch
      loginFilters={[
        {
          condition: (loginResponse: LoginResponse) => !isAuthorized(loginResponse),
          message: intl.formatMessage({
            id: 'auth.condition.organization',
            defaultMessage: 'you are not allowed to login',
          }),
        },
      ]}
      baseUrl={appConfig.API_URL || ''}
      onLoginSuccess={onLoginSuccess}
      companyDetails={{
        name: 'BioT Console',
        description: intl.formatMessage({
          id: 'companyDetails.label',
          defaultMessage: 'Connect. Collaborate. Care.',
        }),
        Logo: OrganizationImage,
      }}
      onTermsClick={() => {
        window.open(appConfig.TERMS, '_blank');
      }}
      onPrivacyClick={() => {
        window.open(appConfig.PRIVACY, '_blank');
      }}
      LanguagePickerComponent={() => <LanguagePicker availableLanguages={Object.values(appConfig.AVAILABLE_LOCALES)} />}
    />
  );
};
