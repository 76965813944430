import {
  Button,
  Table,
  SidePreview,
  Explained,
  entityIcons,
  EntityAvatarOptions,
  Menu,
  MenuItem,
  Icon,
  SubmitButtonStatus,
  ValidationModal,
  ValidationModalProps,
  ErrorBanner,
  FreeTextSearch,
  SearchTerms,
} from '@biotmed/base-components';
import React, { useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { actions as entityActions, selectors as entitySelectors } from 'src/redux/data/entity';
import { actions as templateActions, selectors as templateSelectors } from 'src/redux/data/template';
// TODO: import { OrderOrderEnum } from '@biotmed/sdk-api-provider/lib/types/settings';
import { FilterV2, OrderOrderEnum } from '@biotmed/settings-sdk';
import {
  EntityTypeToIntlDisplayNameMap,
  EntityType,
  AttributeTypeEnum,
  createCustomFilter,
} from '@biotmed/data-components';
import { GetTemplateResponse } from '@biotmed/sdk-api-provider/lib/types/settings';
import { TablePaginationConfig } from 'antd';

import AppConfig from 'src/config/AppConfig';
import {
  TemplatesPageLayout,
  ContentWrapper,
  TemplatesTitle,
  TemplatesTotalSubTitle,
  TemplatesTotalRow,
  CategoryContent,
  RightSide,
  StyledPageTitleContainer,
} from './Templates.styled';
import { RequestType } from '../../../redux/data/template/modules/slice';
import Template, { Mode } from './Template';
import { EntityTemplateForm } from './TemplateForm';
import { cleanTemplate, errorAdapters, fallbackMessage } from '../modules/constant';

interface TemplatesProps {}

// type ErrorMode = keyof typeof errorAdapters;

const emptyRow = {
  entityTypeName: undefined as unknown as EntityType,
  displayName: '',
  name: '',
  description: '',
  builtInAttributes: [],
  dynamicAttributes: [],
  id: '',
  creationTime: '',
  lastModifiedTime: '',
};

const Templates: React.FC<TemplatesProps> = props => {
  const [mode, setMode] = useState<Mode>('ADD');
  const [selectedRow, setSelectedRow] = useState(emptyRow);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();

  const [selectedEntityType, setSelectedEntityType] = useState('device');
  const theme = useTheme();
  const open = useSelector(templateSelectors.getIsOpen);
  const setOpen = (isOpen: boolean) => {
    dispatch(isOpen ? templateActions.openModal() : templateActions.closeModal());
  };
  const templatesTotal = useSelector(templateSelectors.getTemplatesTotal);
  const pageLimit = useSelector(templateSelectors.selectPageLimit);
  const currentPage = useSelector(templateSelectors.selectCurrentPage);
  const templatesList = useSelector(templateSelectors.getTemplatesList);

  const currentTemplate = useSelector(templateSelectors.getTemplateById(selectedRow.id)); // to get updated row data
  const selectedRowEntity = useSelector(entitySelectors.getEntityByEntityName(selectedRow.entityTypeName || ''));
  const selectedEntity = useSelector(entitySelectors.getEntityByEntityName(selectedEntityType));
  const supportedToAddEntityTypes: EntityType[] = useSelector(entitySelectors.getSupportedToAddTypes) as EntityType[];
  const intl = useIntl();
  const apiError = useSelector(templateSelectors.getApiError);
  const loading = useSelector(templateSelectors.selectLoading);

  const deleteStatus = useSelector(templateSelectors.getDeleteStatus);
  const entitiesFilterValues: EntityType[] = useSelector(entitySelectors.getTypes) as EntityType[];

  const freeTextSearch = useSelector(templateSelectors.selectFreeTextSearch);

  const itemsCountText = useMemo(() => {
    if (freeTextSearch && freeTextSearch.trim().length > 0) {
      return intl.formatMessage(
        {
          id: 'templates.itemsReturnedForSearchTerms.count',
          defaultMessage: '{count} Items found for search terms',
        },
        { count: templatesTotal },
      );
    }
    return intl.formatMessage(
      { id: 'templates.items.count', defaultMessage: '{count} Templates' },
      { count: templatesTotal },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeTextSearch, templatesTotal]);

  useEffect(() => {
    dispatch(entityActions.onLoadEntityType());
    dispatch(templateActions.onLoadTemplate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(templateActions.resetSearchRequest());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (deleteStatus === SubmitButtonStatus.NORMAL) {
      setSelectedRow(emptyRow);
    }
  }, [deleteStatus]);

  // TODO: WHEN THE GET_TEMPLATE_RESPONSE INTERFACE UPDATE IN SDK REMOVE '& {DISPLAY_NAME:STRING}'.
  const templatesDataSource = templatesList.map((templateListItem: GetTemplateResponse, index: number) => {
    const { creationTime, displayName } = templateListItem;
    // TODO: Change N/A to some constant intl item
    const dateMessage = creationTime
      ? intl.formatDate(creationTime, { dateStyle: 'short', timeStyle: 'short' })
      : 'N/A';
    return {
      ...templateListItem,
      key: templateListItem.id === selectedRow.id ? 'selected-template' : `template-${index}`,
      creationTime: dateMessage,
      name: displayName,
    };
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    dispatch(templateActions.deleteTemplate({ templateId: selectedRow.id, templateName: selectedRow.name }));
  };

  const handleExpand = () => {
    setMode('EDIT');
    setOpen(true);
  };

  const handleAddButtonClicked = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    } else {
      handleCloseMenu();
    }
  };

  const openAddModal = (entityType: string) => {
    setSelectedEntityType(entityType);
    handleCloseMenu();
    setMode('ADD');
    setOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const menuItems = supportedToAddEntityTypes.map((entityType: EntityType) => ({
    text: intl.formatMessage(EntityTypeToIntlDisplayNameMap[entityType] || fallbackMessage(entityType)),
    Logo: entityIcons[entityType as keyof typeof entityIcons],
    onClick: () => {
      openAddModal(entityType);
    },
  }));

  const handleChange = (
    pagination: TablePaginationConfig,
    sorterUnboxed: { field: string; order: 'ascend' | 'descend' | undefined },
    filters: { [key: string]: FilterV2 },
  ) => {
    dispatch(
      templateActions.updateSearchRequest({
        filter: filters,
        page: pagination.current ? pagination.current - 1 : 0,
        limit: pagination.pageSize,
        sort: sorterUnboxed.order && [
          {
            prop: sorterUnboxed.field,
            order: sorterUnboxed.order === 'ascend' ? OrderOrderEnum.Asc : OrderOrderEnum.Desc,
          },
        ],
      }),
    );
    dispatch(templateActions.onLoadTemplate());
  };

  const displayErrorBanner =
    apiError?.requestType === RequestType.LIST ||
    apiError?.requestType === RequestType.DELETE ||
    apiError?.requestType === RequestType.ADD_PARTIAL ||
    apiError?.requestType === RequestType.EDIT_PARTIAL;

  const processedApiError =
    apiError && displayErrorBanner && errorAdapters[apiError.requestType](apiError.error, apiError.additionalData);

  const categoryColumnSelectableValues = useMemo(
    () =>
      entitiesFilterValues?.map(value => ({
        name: value,
        displayName: intl.formatMessage(EntityTypeToIntlDisplayNameMap[value as EntityType] || fallbackMessage(value)),
      })) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entitiesFilterValues],
  );

  const [validationModalOpen, setValidationModalOpen] = useState<boolean>(false);

  const validationModalProps: ValidationModalProps = {
    open: validationModalOpen,
    onClose: () => setValidationModalOpen(false),
    agreeButtonLabel: intl.formatMessage({
      id: 'templates.validation.component.agree.label',
      defaultMessage: 'Delete',
    }),
    dismissButtonLabel: intl.formatMessage({
      id: 'templates.validation.component.dismiss.label',
      defaultMessage: 'Cancel',
    }),
    onAgree: handleDelete,
    message: intl.formatMessage({
      id: 'templates.validation.component.message',
      defaultMessage: 'Are you sure you wish to delete this item?',
    }),
  };

  const onLearnMoreClick = () => {
    window.open(AppConfig.TEMPLATES_LEARN_MORE_URL);
  };

  const onSubmitAdd = (
    entityType: string,
    templateId: string,
    values: EntityTemplateForm,
    originalChildrenTemplates: any,
    forceUpdate: boolean,
  ) => {
    dispatch(
      templateActions.createTemplate({
        entityTemplate: values.entityTemplate,
        childrenTemplates: values.childrenTemplates,
      }),
    );
  };

  const onSubmitEdit = (
    entityType: string,
    templateId: string,
    values: EntityTemplateForm,
    originalChildrenTemplates: GetTemplateResponse[],
    forceUpdate: boolean,
  ) => {
    dispatch(
      templateActions.editTemplate({
        // TODO: REMOVE || '' FROM ENTITY TYPE AND TEMPLATE ID
        entityType: entityType || '',
        templateId,
        entityTemplate: cleanTemplate(values.entityTemplate),
        childrenTemplates: values.childrenTemplates?.map(childTemplate => cleanTemplate(childTemplate)),
        originalChildrenTemplates,
        forceUpdate,
      }),
    );
  };

  const onFreeTextSearchChange = (value: string) => {
    dispatch(templateActions.updateFreeTextSearch({ freeTextSearch: value }));
    dispatch(templateActions.onLoadTemplate());
  };

  return (
    <TemplatesPageLayout>
      <ContentWrapper>
        <ValidationModal {...validationModalProps} />

        <StyledPageTitleContainer>
          <TemplatesTitle>
            {intl.formatMessage({ id: 'templates.mainTitle', defaultMessage: 'Templates' })}
          </TemplatesTitle>
          <FreeTextSearch freeTextSearch={freeTextSearch} onFreeTextSearchChange={onFreeTextSearchChange} />
        </StyledPageTitleContainer>
        {processedApiError && <ErrorBanner {...processedApiError}>{processedApiError?.message}</ErrorBanner>}
        <Explained
          text={intl.formatMessage({
            id: 'templates.explained',
            defaultMessage: "Templates enable you to customize BioT's data model to your needs",
          })}
          onLearnMore={onLearnMoreClick}
        />
        <TemplatesTotalRow>
          <TemplatesTotalSubTitle>{itemsCountText}</TemplatesTotalSubTitle>
          <Button variant="outlined" onClick={handleAddButtonClicked} size="medium">
            {intl.formatMessage({ id: 'templates.add', defaultMessage: 'Add +' })}
          </Button>
          <Menu
            title={intl.formatMessage({ id: 'templates.addMenuTitle', defaultMessage: 'new template for' })}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            menuPosition={{ left: '30px' }}
            PaperProps={{
              style: {},
            }}
          >
            {menuItems.map(menuItemProps => (
              <MenuItem {...menuItemProps} key={`${menuItemProps.text}-menu-item`} />
            ))}
          </Menu>
        </TemplatesTotalRow>
        <Template
          entity={mode === 'EDIT' ? selectedRowEntity : selectedEntity}
          template={currentTemplate || selectedRow}
          handleClose={handleClose}
          open={open}
          mode={mode}
          onFormSubmit={mode === 'EDIT' ? onSubmitEdit : onSubmitAdd}
        />
        <SearchTerms freeTextSearch={freeTextSearch} onFreeTextSearchChange={onFreeTextSearchChange} />
        <Table
          columns={[
            {
              title: intl.formatMessage({ id: 'templates.categoryColumn', defaultMessage: 'Category' }),
              dataIndex: 'entityTypeName',
              key: 'category',
              width: '300px',
              sorter: true,
              showSorterTooltip: false,
              filters: [],
              filterDropdown: createCustomFilter(AttributeTypeEnum.MultiSelect, {
                selectableValues: categoryColumnSelectableValues,
              }),
              render: (text: string, record: { entityTypeName: EntityAvatarOptions }, index: number) => {
                const EntityIcon = entityIcons[record.entityTypeName];
                return (
                  <CategoryContent key={`category-${index}`}>
                    {EntityIcon && <Icon IconComponent={EntityIcon} height="25px" color={theme.palette.primary.dark} />}
                    {text &&
                      intl.formatMessage(EntityTypeToIntlDisplayNameMap[text as EntityType] || fallbackMessage(text))}
                  </CategoryContent>
                );
              },
            },
            {
              title: intl.formatMessage({ id: 'templates.column-name', defaultMessage: 'Name' }),
              dataIndex: 'displayName',
              key: 'name',
            },
            {
              title: intl.formatMessage({ id: 'templates.column-creationTime', defaultMessage: 'Creation Time' }),
              dataIndex: 'creationTime',
              key: 'creationTime',
            },
          ]}
          dataSource={templatesDataSource}
          totalItems={templatesTotal}
          pageSize={pageLimit}
          pagination={{ current: currentPage + 1 }}
          onRow={(record, rowIndex) => ({
            onClick: () => {
              setSelectedRow(record);
              dispatch(templateActions.initError());
            },
          })}
          onChange={(pagination, filters, sorter, extra) => {
            const sorterUnboxed = sorter as { field: string; order: 'ascend' | 'descend' | undefined };
            const parsedFilters: Record<string, FilterV2> = {};
            for (const [dataIndex, filterValue] of Object.entries(filters)) {
              if (filterValue !== null) {
                const processedDataIndex = dataIndex === 'category' ? 'entityTypeName' : dataIndex;
                parsedFilters[processedDataIndex] = JSON.parse(filterValue as unknown as string) as FilterV2;
              }
            }

            handleChange(pagination, sorterUnboxed, parsedFilters);
          }}
          isLoading={loading}
        />
      </ContentWrapper>
      <RightSide>
        <SidePreview
          entityType={selectedRow.entityTypeName}
          name={currentTemplate?.displayName || selectedRow.displayName}
          subtitle={
            selectedRow.entityTypeName &&
            intl.formatMessage(
              EntityTypeToIntlDisplayNameMap[selectedRow.entityTypeName as EntityType] ||
                fallbackMessage(selectedRow.entityTypeName),
            )
          }
          description={currentTemplate?.description || selectedRow.description}
          deleteText={intl.formatMessage({ id: 'templates.delete', defaultMessage: 'Delete template' })}
          deleteLoadingText={intl.formatMessage({ id: 'templates.deleteLoading', defaultMessage: 'Deleting template' })}
          deleteSuccessText={intl.formatMessage({ id: 'templates.deleteSuccess', defaultMessage: 'Template Deleted' })}
          deleteButtonStatus={deleteStatus}
          onExpand={handleExpand}
          disabledDeleteButton={!currentTemplate?.removable}
          onDelete={() => setValidationModalOpen(true)}
        />
      </RightSide>
    </TemplatesPageLayout>
  );
};

export default Templates;
