import { createSlice, createSelector, createAction, PayloadAction } from '@reduxjs/toolkit';
import { EntityType } from '@biotmed/settings-sdk/service/model/entity-type';
import { RootState } from '../../../reducer';
import { DATA_STATE_KEY } from '../../constants';
import { Entity } from './interfaces';

export const STATE_KEY = 'entity';

interface EntityState {
  entitiesList: { [x: string]: Entity };
  supportedToAddTypes: string[];
}

export const getInitialState = (state?: EntityState): EntityState => ({
  entitiesList: {},
  supportedToAddTypes: [],
});

const mapSelectList = (selectList: any[]) =>
  selectList.reduce<{ [name: string]: Entity }>((obj, item) => {
    const object = obj;
    object[item.name] = item;
    return object;
  }, {});

const extractSupportedToAddTypes = (selectList: EntityType[]) =>
  selectList.filter(item => item.addingTemplatesSupported).map(item => item.name);

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    onLoadEntityTypeSuccess: (state, action: PayloadAction<EntityType[]>) => {
      state.entitiesList = mapSelectList(action.payload);
      state.supportedToAddTypes = extractSupportedToAddTypes(action.payload);
    },
  },
});
/* eslint-enable no-param-reassign */

const getState = (state: RootState) => state[DATA_STATE_KEY][STATE_KEY] || getInitialState();

export const selectors = {
  getTypes: createSelector(getState, state => Object.keys(state.entitiesList)),
  getSupportedToAddTypes: createSelector(getState, state => state.supportedToAddTypes),
  getEntityByEntityName: (entityName: string) => createSelector(getState, state => state.entitiesList[entityName]),
};
const extraActions = {
  onLoadEntityType: createAction(`${STATE_KEY}/onLoadEntityType`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
