/* eslint-disable no-console */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';
import { LanguageProvider as SharedLanguageProvider } from '@biotmed/i18n';
import APP_CONFIG from 'src/config/AppConfig';
import { useDispatch } from 'react-redux';
import { actions } from 'src/redux/data/app';

interface LanguageProviderProps {
  children: React.ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = props => {
  const { children } = props;
  const dispatch = useDispatch();
  const [messages, setMessages] = useState<{ [x: string]: { [x: string]: string } }>();

  useEffect(() => {
    updateMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateMessages = async () => {
    const availableLanguages = APP_CONFIG.AVAILABLE_LOCALES;
    const obj: { language: string; languageMessages: { [x: string]: string } }[] = await Promise.all(
      availableLanguages.map(async (language: string) => {
        let localTranslationFile = {};
        let externalTranslationFile = {};

        try {
          localTranslationFile = require(`../../../translations/locales/${language.toString()}.json`);
        } catch (ex) {
          console.error('Failed to get local translation file for', language);
        }

        if (APP_CONFIG.TRANSLATION_FILES_BASE_URL) {
          try {
            externalTranslationFile = await (
              await fetch(`${APP_CONFIG.TRANSLATION_FILES_BASE_URL}/${language.toString()}.json`)
            ).json();
          } catch (ex) {
            console.error('Failed to fetch external translation file for', language);
          }
        }

        const languageMessages = { ...localTranslationFile, ...externalTranslationFile };

        return { language, languageMessages };
      }),
    );

    const updatedMessages: { [x: string]: { [x: string]: string } } = await obj.reduce(
      (tempMessages, languageMessages) => {
        return { ...tempMessages, [languageMessages.language]: languageMessages.languageMessages };
      },
      {},
    );

    dispatch(actions.onFetchMessagesSuccess());
    setMessages(updatedMessages);
  };

  return messages ? (
    <SharedLanguageProvider
      availableLocales={APP_CONFIG.AVAILABLE_LOCALES}
      languageToMessages={messages}
      defaultLocale={APP_CONFIG.DEFAULT_LOCALE}
    >
      {children}
    </SharedLanguageProvider>
  ) : null;
};

export default LanguageProvider;
