import React from 'react';
import { MeasurementPortalAttributes, SelectedPortalAttribute } from '@biotmed/settings-sdk';
import { useIntl } from 'react-intl';

import { measurementViewClassificationToIntlDisplayNameMap } from '@biotmed/data-components';
import { SectionTitle, AttributesWrapper, SectionWrapper } from '../PortalBuilder.styled';

import PortalViewCategoryRenderer from '../PortalViewCategoryRenderer/PortalViewCategoryRenderer';

interface MeasurementsBuilderProps {
  measurementAttributes: MeasurementPortalAttributes;
  onChange: (values: any) => void;
  fullScreen: boolean;
}

const MeasurementsBuilder: React.FC<MeasurementsBuilderProps> = props => {
  const { measurementAttributes, onChange, fullScreen = false } = props;

  const intl = useIntl();

  const handleChangeChart = (selected: SelectedPortalAttribute[]) => {
    onChange({
      selected: {
        ...measurementAttributes.selected,
        chart: selected,
      },
    });
  };

  const handleChangeHeadline = (selected: SelectedPortalAttribute[]) => {
    onChange({
      selected: {
        ...measurementAttributes.selected,
        headline: selected,
      },
    });
  };

  return (
    <SectionWrapper>
      <SectionTitle>
        {intl.formatMessage({
          id: 'portalBuilder.MeasurementsSectionTitle',
          defaultMessage: 'Measurements Section - select measurements, then order',
        })}
      </SectionTitle>
      <AttributesWrapper>
        <PortalViewCategoryRenderer
          key="chart"
          title={intl.formatMessage(measurementViewClassificationToIntlDisplayNameMap?.chart) || 'chart'}
          attributes={measurementAttributes.attributes}
          selected={measurementAttributes?.selected?.chart}
          onChange={handleChangeChart}
          fullScreen={fullScreen}
        />
        <PortalViewCategoryRenderer
          key="headline"
          title={intl.formatMessage(measurementViewClassificationToIntlDisplayNameMap?.headline) || 'headline'}
          attributes={measurementAttributes.attributes}
          selected={measurementAttributes?.selected?.headline}
          onChange={handleChangeHeadline}
          fullScreen={fullScreen}
        />
      </AttributesWrapper>
    </SectionWrapper>
  );
};

export default MeasurementsBuilder;
