import React from 'react';
import { defineMessages } from 'react-intl';
import TemplateAttributeNumericType from 'src/routes/Templates/components/Attribute/TemplateAttribute/NumericType';
import TemplateAttributeBooleanType from 'src/routes/Templates/components/Attribute/TemplateAttribute/BooleanType';
import { AttributeTypesEnum } from 'src/utils/attributeTypeEnum';
import FileType from '../FileType/FileType';
import LabelType from '../LabelType/LabelType';
import LinkedType from '../LinkedType/LinkedType';
import NumericType from '../NumericType/NumericType';
import ReferenceType from '../ReferenceType/ReferenceType';
import SelectType from '../SelectType/SelectType';
import WaveformType from '../Waveform/WaveformType';

const messages = defineMessages({
  Label: {
    id: 'attribute-type.label',
    defaultMessage: 'Label',
  },
  Paragraph: {
    id: 'attribute-type.Paragraph',
    defaultMessage: 'Paragraph',
  },
  Integer: {
    id: 'attribute-type.Integer',
    defaultMessage: 'Integer',
  },
  Decimal: {
    id: 'attribute-type.Decimal',
    defaultMessage: 'Decimal',
  },
  Boolean: {
    id: 'attribute-type.Boolean',
    defaultMessage: 'Boolean',
  },
  SingleSelect: {
    id: 'attribute-type.SingleSelect',
    defaultMessage: 'Single Select',
  },
  MultiSelect: {
    id: 'attribute-type.MultiSelect',
    defaultMessage: 'Multi Select',
  },
  Address: {
    id: 'attribute-type.Address',
    defaultMessage: 'Address',
  },
  Phone: {
    id: 'attribute-type.Phone',
    defaultMessage: 'Phone',
  },
  Email: {
    id: 'attribute-type.Email',
    defaultMessage: 'Email',
  },
  DateTime: {
    id: 'attribute-type.DateTime',
    defaultMessage: 'Date Time',
  },
  Date: {
    id: 'attribute-type.Date',
    defaultMessage: 'Date',
  },
  Timezone: {
    id: 'attribute-type.Timezone',
    defaultMessage: 'Timezone',
  },
  Locale: {
    id: 'attribute-type.Locale',
    defaultMessage: 'Locale',
  },
  Reference: {
    id: 'attribute-type.Reference',
    defaultMessage: 'Entity',
  },
  Name: {
    id: 'attribute-type.Name',
    defaultMessage: 'Name',
  },
  Picture: {
    id: 'attribute-type.Picture',
    defaultMessage: 'Picture',
  },
  File: {
    id: 'attribute-type.File',
    defaultMessage: 'File',
  },
  Image: {
    id: 'attribute-type.Image',
    defaultMessage: 'Image',
  },
  Waveform: {
    id: 'attribute-type.Waveform',
    defaultMessage: 'Waveform',
  },
  Linked: {
    id: 'attribute-type.Linked',
    defaultMessage: 'Linked',
  },
});
export const TypesComponentsMap = {
  [AttributeTypesEnum.SingleSelect]: SelectType,
  [AttributeTypesEnum.MultiSelect]: (props: any) => <SelectType multi {...props} />,
  [AttributeTypesEnum.Label]: LabelType,
  [AttributeTypesEnum.Decimal]: NumericType,
  [AttributeTypesEnum.Integer]: (props: any) => <NumericType integer {...props} />,
  [AttributeTypesEnum.Reference]: ReferenceType,
  [AttributeTypesEnum.File]: FileType,
  [AttributeTypesEnum.Image]: FileType,
  [AttributeTypesEnum.Waveform]: WaveformType,
  [AttributeTypesEnum.Linked]: LinkedType,
};

export const TemplateAttributeTypesComponentsMap = {
  [AttributeTypesEnum.Integer]: TemplateAttributeNumericType,
  [AttributeTypesEnum.Boolean]: TemplateAttributeBooleanType,
};

export const mapAttributesTypeToLabel = new Map<AttributeTypesEnum, any>([
  [AttributeTypesEnum.Label, messages.Label],
  [AttributeTypesEnum.Paragraph, messages.Paragraph],
  [AttributeTypesEnum.Integer, messages.Integer],
  [AttributeTypesEnum.Decimal, messages.Decimal],
  [AttributeTypesEnum.Boolean, messages.Boolean],
  [AttributeTypesEnum.SingleSelect, messages.SingleSelect],
  [AttributeTypesEnum.MultiSelect, messages.MultiSelect],
  [AttributeTypesEnum.Address, messages.Address],
  [AttributeTypesEnum.Phone, messages.Phone],
  [AttributeTypesEnum.Email, messages.Email],
  [AttributeTypesEnum.DateTime, messages.DateTime],
  [AttributeTypesEnum.Timezone, messages.Timezone],
  [AttributeTypesEnum.Locale, messages.Locale],
  [AttributeTypesEnum.Reference, messages.Reference],
  [AttributeTypesEnum.Date, messages.Date],
  [AttributeTypesEnum.Name, messages.Name],
  [AttributeTypesEnum.Picture, messages.Picture],
  [AttributeTypesEnum.File, messages.File],
  [AttributeTypesEnum.Image, messages.Image],
  [AttributeTypesEnum.Waveform, messages.Waveform],
  [AttributeTypesEnum.Linked, messages.Linked],
]);
