import React from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { EntityTemplateForm } from 'src/routes/Templates/components/TemplateForm';
import InputWithTooltip, {
  InputWithTooltipProps,
  OnChangeTooltipValueReason,
  TooltipInputCanChangeType,
} from 'src/components/InputWithTooltip';

export interface NameSectionAttributeProps extends Partial<InputWithTooltipProps> {
  attributeId: string;
  basePath?: string;
  bold?: boolean;
}

const NameSection: React.FC<NameSectionAttributeProps & TooltipInputCanChangeType> = props => {
  const intl = useIntl();
  const {
    onChange,
    placeholder = intl.formatMessage({
      id: 'templates.attribute.name-section.title-placeholder',
      defaultMessage: 'Please choose a name',
    }),
    fieldName,
    tooltipFieldName,
    canChangeTooltipValue,
    tooltipInputCanChangeProps,
    bold = true,
    ...restProps
  } = props;
  const formik = useFormikContext<EntityTemplateForm>();

  const TooltipInputProps = canChangeTooltipValue
    ? {
        canChangeTooltipValue,
        tooltipInputCanChangeProps: {
          ...tooltipInputCanChangeProps,
          onChange: (value: any, reason: OnChangeTooltipValueReason) => {
            if (reason === 'inputChange') {
              // json name was changed
              tooltipInputCanChangeProps?.onChange?.(value, reason);
              formik.setFieldTouched(tooltipFieldName ?? '', true, false);
            } else if (
              reason === 'byValue' &&
              (tooltipInputCanChangeProps?.updateTooltipValueByValue ||
                (tooltipInputCanChangeProps?.updateTooltipValueByValue === undefined &&
                  !formik.getFieldMeta(tooltipFieldName ?? '').touched))
            ) {
              // display name was changed and json name should be updated accordingly
              tooltipInputCanChangeProps?.onChange?.(value, reason);
            } else if (reason === 'clickAway') {
              // the received value is the original one
              tooltipInputCanChangeProps?.onChange?.(value, reason);
            }
          },
          onBlur: (e: any) => {
            formik.handleBlur(e);
            tooltipInputCanChangeProps?.onBlur?.(e);
          },
          onSave: (value: string | null) => tooltipInputCanChangeProps?.onSave?.(value),
          updateTooltipValueByValue:
            tooltipInputCanChangeProps?.updateTooltipValueByValue ??
            !formik.getFieldMeta(tooltipFieldName ?? '').touched ??
            true,
        },
      }
    : { canChangeTooltipValue };

  return (
    <InputWithTooltip
      placeholder={placeholder}
      tooltipFieldName={tooltipFieldName}
      fieldName={fieldName}
      onChange={value => {
        onChange?.(value ?? '');
        formik.setFieldTouched(fieldName ?? '', true, false);
      }}
      {...TooltipInputProps}
      {...restProps}
      onBlur={formik.handleBlur}
      bold={bold}
    />
  );
};

export default NameSection;
