import {
  AttributeType,
  AttributeTypeEnum,
  createErrorAdapter,
  EntityTypeEnum,
  ErrorAdapterReturn,
  ErrorDictionary,
} from '@biotmed/data-components';
import { Template } from 'src/redux/data/entity/modules/interfaces';
import { deepFreeze } from 'src/utils/deepFreeze';
import AppConfig from 'src/config/AppConfig';
import { ErrorResponse } from '@biotmed/settings-sdk';
import { ReactIntlMessageExtraValues } from '@biotmed/base-components';
import { AttributeTypesEnum } from '../../../utils/attributeTypeEnum';
import { EntityTemplateForm } from '../components/TemplateForm';
import {
  addTemplateErrorDictionary,
  addTemplatePartialErrorDictionary,
  deleteTemplateErrorDictionary,
  editTemplateErrorDictionary,
  editTemplatePartialErrorDictionary,
  templateListErrorDictionary,
} from './dictionaries';

export const templateInitialValues: Template = {
  name: '',
  displayName: '',
  description: undefined,
  builtInAttributes: [],
  customAttributes: [],
  ownerOrganizationId: '',
};

export const TEMPLATE_TYPE_TO_LEARN_MORE_URL: Partial<Record<EntityTypeEnum, string | undefined>> = {
  [EntityTypeEnum.CAREGIVER]: AppConfig.CAREGIVER_TEMPLATE_LEARN_MORE_URL,
  [EntityTypeEnum.PATIENT]: AppConfig.PATIENT_TEMPLATE_LEARN_MORE_URL,
  [EntityTypeEnum.ORGANIZATION_USER]: AppConfig.ORGANIZATION_USER_TEMPLATE_LEARN_MORE_URL,
  [EntityTypeEnum.ORGANIZATION]: AppConfig.ORGANIZATION_TEMPLATE_LEARN_MORE_URL,
  [EntityTypeEnum.DEVICE]: AppConfig.DEVICE_TEMPLATE_LEARN_MORE_URL,
  [EntityTypeEnum.GENERIC_ENTITY]: AppConfig.GENERIC_ENTITY_TEMPLATE_LEARN_MORE_URL,
  [EntityTypeEnum.USAGE_SESSION]: AppConfig.USAGE_TYPE_TEMPLATE_LEARN_MORE_URL,
};

export const entityTemplateFieldName = 'entityTemplate';
export const childrenTemplatesFieldName = 'childrenTemplates';
export const customAttributesFieldName = `${entityTemplateFieldName}.customAttributes`;

export const EntityTemplateFormInitialValues: EntityTemplateForm = {
  [entityTemplateFieldName]: templateInitialValues,
  [childrenTemplatesFieldName]: [],
};
export const typesArray: AttributeTypesEnum[] = [
  AttributeTypesEnum.Label,
  AttributeTypesEnum.SingleSelect,
  AttributeTypesEnum.MultiSelect,
  AttributeTypesEnum.Decimal,
  AttributeTypesEnum.Integer,
  AttributeTypesEnum.Paragraph,
  AttributeTypesEnum.Boolean,
  AttributeTypesEnum.Address,
  AttributeTypesEnum.Phone,
  AttributeTypesEnum.Email,
  AttributeTypesEnum.DateTime,
  AttributeTypesEnum.Date,
  AttributeTypesEnum.Timezone,
  AttributeTypesEnum.Locale,
  AttributeTypesEnum.Reference,
  AttributeTypesEnum.Name,
  AttributeTypesEnum.File,
  AttributeTypesEnum.Image,
];

export const MAX_NAME_LENGTH = 36;

export const validationConstants = deepFreeze({
  templateModal: {
    templateName: {
      maxLength: MAX_NAME_LENGTH,
    },
    builtinAttributes: {
      displayName: {
        maxLength: MAX_NAME_LENGTH,
      },
    },
    customAttributes: {
      displayName: {
        maxLength: MAX_NAME_LENGTH,
      },
      name: {
        maxLength: MAX_NAME_LENGTH,
      },
      selectableValuesArray: {
        minOptionsLength: 2,
      },
    },
    selectableAttributes: {
      displayName: {
        maxLength: MAX_NAME_LENGTH,
      },
    },
  },
});

const typesToCleanSelectableValues: AttributeType[] = [AttributeTypeEnum.Locale, AttributeTypeEnum.Timezone];
const isTypeToCleanSelectableValues = (type: AttributeType) => typesToCleanSelectableValues.includes(type);

export const cleanTemplate = (template: Template): Template => {
  const customAttributes = template.customAttributes?.map(value => ({
    ...value,
    selectableValues: isTypeToCleanSelectableValues(value.type) ? undefined : value.selectableValues,
  }));

  return { ...template, customAttributes };
};

const errorAdapterDictionaries: Record<string, ErrorDictionary> = {
  ADD: addTemplateErrorDictionary,
  EDIT: editTemplateErrorDictionary,
  LIST: templateListErrorDictionary,
  DELETE: deleteTemplateErrorDictionary,
  ADD_PARTIAL: addTemplatePartialErrorDictionary,
  EDIT_PARTIAL: editTemplatePartialErrorDictionary,
};

export const errorAdapters: Record<
  string,
  (error: ErrorResponse, additionalData?: ReactIntlMessageExtraValues) => ErrorAdapterReturn
> = Object.keys(errorAdapterDictionaries).reduce(
  (obj, dictionaryKey) => ({
    ...obj,
    [dictionaryKey]: createErrorAdapter(
      errorAdapterDictionaries[dictionaryKey as keyof typeof errorAdapterDictionaries],
    ),
  }),
  {},
);

export const fallbackMessage = (message: any) => ({
  id: 'fallback.message',
  defaultMessage: message,
});
