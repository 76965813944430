import { FieldArray, useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Entity } from 'src/redux/data/entity/modules/interfaces';
import { childrenTemplatesFieldName, entityTemplateFieldName } from 'src/routes/Templates/modules/constant';
import AddFieldButton from 'src/components/AddFieldButton';
import { EntityTypeToIntlDisplayNameInPluralMap, EntityTypeToIntlDisplayNameMap } from '@biotmed/data-components';
import { Hr } from 'src/components/Hr/Hr.styled';
import { FieldError } from '../../../../../components/formikHelpers/FieldError';
import ChildrenType from './ChildrenType/ChildrenType';
import { SpinBox, Spin, ChildrenTypesContainer, NoChildrenTypes } from './ChildrenTypes.styled';
import { EntityTemplateForm } from '../../TemplateForm';
import { Title } from '../../Templates.styled';
import useChildren, { emptyRow } from './ChildrenType/useChildren';
import { TemplateModeEnum } from '../../Template';

interface ChildrenTypesProps {
  entity: Entity;
}

function updateTemplateName<T extends { name: string; parentTemplateName: string; basePath?: string }>(
  builtInAttribute: T,
  parentTemplateName: string,
) {
  const updatedBuiltInAttribute = { ...builtInAttribute };

  if (builtInAttribute.name === '_device' && !builtInAttribute.basePath) {
    updatedBuiltInAttribute.parentTemplateName = parentTemplateName;
  }

  return updatedBuiltInAttribute;
}

const ChildrenTypes: React.FC<ChildrenTypesProps> = props => {
  const intl = useIntl();
  const formik = useFormikContext<EntityTemplateForm>();
  const { entity } = props;
  const { values, errors, touched, status, setFieldValue } = formik;

  // These useMemo and useEffect change both the entity and formik values.
  // The entity is responsible for initial values for add children type template
  // and the formik values are for edit children type template
  const updatedEntity = useMemo(() => {
    const builtInAttributesForEntity = entity.builtInAttributes.map(builtInAttribute => {
      return updateTemplateName(builtInAttribute, formik.values[entityTemplateFieldName].displayName);
    });

    return {
      ...entity,
      builtInAttributes: builtInAttributesForEntity,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const childrenTypes = [...values?.[childrenTemplatesFieldName]] ?? [];

    childrenTypes?.forEach((childrenType: any, index: number) => {
      const builtInAttributes = childrenType.builtInAttributes.map((builtInAttribute: any) => {
        return updateTemplateName(builtInAttribute, formik.values[entityTemplateFieldName].displayName);
      });

      setFieldValue(`${childrenTemplatesFieldName}.${index}`, {
        ...values?.[childrenTemplatesFieldName][index],
        builtInAttributes,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const childrenTypesList = useMemo(() => {
    const childrenTypes = [...values?.[childrenTemplatesFieldName]] ?? [];

    return childrenTypes
      ?.map((childrenType: any, index: number) => ({ childrenTypeObject: childrenType, index }))
      ?.filter(childrenType => childrenType.childrenTypeObject.entityTypeName === entity.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.childrenTemplates]);

  const [openDrawer, closeDrawer, TemplateComponent] = useChildren(updatedEntity);

  const addSubmit =
    (push: any) =>
    (
      entityType: string,
      templateId: string,
      childrenTemplateValues: EntityTemplateForm,
      originalChildrenTemplates: any,
      forceUpdate: boolean,
    ) => {
      const customAttributes = childrenTemplateValues.entityTemplate.customAttributes?.map(customAttribute => ({
        ...customAttribute,
        isSaved: true,
      }));
      const childrenTemplate = { ...childrenTemplateValues.entityTemplate, customAttributes };
      push(childrenTemplate);
      closeDrawer();
    };

  const editSubmit =
    (index: string) =>
    (
      entityType: string,
      templateId: string,
      childrenTemplateValues: EntityTemplateForm,
      originalChildrenTemplates: any,
      forceUpdate: boolean,
    ) => {
      const customAttributes = childrenTemplateValues.entityTemplate.customAttributes?.map(customAttribute => ({
        ...customAttribute,
        isSaved: true,
      }));

      setFieldValue(`${childrenTemplatesFieldName}.${index}`, {
        ...childrenTemplateValues.entityTemplate,
        customAttributes,
      });
      closeDrawer();
    };

  return (
    <ChildrenTypesContainer>
      <Title>{`${intl.formatMessage(EntityTypeToIntlDisplayNameInPluralMap[entity.name])}`}</Title>
      <FieldArray name={childrenTemplatesFieldName}>
        {(arrayHelpers: any) => {
          const { push, remove } = arrayHelpers;
          return status?.isLoadingChildren ? (
            <SpinBox>
              <Spin />
            </SpinBox>
          ) : (
            <>
              {childrenTypesList.length ? (
                childrenTypesList?.map((childrenType: any, index: number) => {
                  return (
                    <ChildrenType
                      entityName={entity.name}
                      onClick={() => {
                        openDrawer(
                          childrenType.childrenTypeObject,
                          TemplateModeEnum.EDIT,
                          editSubmit(childrenType.index),
                        );
                      }}
                      childrenType={childrenType.childrenTypeObject}
                      key={childrenType.index}
                      onDelete={() => {
                        remove(childrenType.index);
                      }}
                    />
                  );
                })
              ) : (
                <NoChildrenTypes>
                  {intl.formatMessage(
                    {
                      id: 'template.modal.children-type.no-children-types',
                      defaultMessage: "You don't have any {childrenTypeName} assigned yet",
                    },
                    {
                      childrenTypeName: intl
                        .formatMessage(EntityTypeToIntlDisplayNameInPluralMap[entity.name])
                        .toLowerCase(),
                    },
                  )}
                </NoChildrenTypes>
              )}
              <FieldError
                errors={errors}
                attributeName={childrenTemplatesFieldName}
                name={childrenTemplatesFieldName}
                touched={touched}
              />
              <Hr />
              <AddFieldButton shadow onClick={() => openDrawer(emptyRow, TemplateModeEnum.ADD, addSubmit(push))}>
                {intl.formatMessage(
                  {
                    id: 'template.modal.add-children-field-button',
                    defaultMessage: '+ Add {childrenTypeName} ',
                  },
                  { childrenTypeName: intl.formatMessage(EntityTypeToIntlDisplayNameMap[entity.name]) },
                )}
              </AddFieldButton>
            </>
          );
        }}
      </FieldArray>
      <TemplateComponent />
    </ChildrenTypesContainer>
  );
};

export default ChildrenTypes;
