import { Button, convertTypographySettingsToCss } from '@biotmed/base-components';
import styled from 'styled-components';

export const ErrorPopupContent = styled.div`
  padding: 32px 25px 25px;
  display: flex;
  flex-direction: column;
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  max-width: 430px;
  align-items: center;
`;

export const MessageHeader = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h4)};
  padding-bottom: 20px;
`;

export const Content = styled.span`
  overflow-wrap: break-word;
  hyphens: manual;
  white-space: pre-line;
  /* text-align: justify; */
`;

export const ButtonsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  padding-top: 28px;
`;

export const ForceDeleteButton = styled(Button)`
  &&.MuiButton-contained {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${props => props.theme.palette.error.medium};
    :hover:not(:disabled) {
      background-color: ${props => props.theme.palette.error.dark};
    }
  }
  div {
    font-size: 10px;
  }
`;

export const InUseAttributesList = styled.ul`
  overflow-y: auto;
  padding: 0 0 0 15px;
  max-height: 90px;
`;
