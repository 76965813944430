import { defineMessages } from 'react-intl';
import { ViewTypeEnum } from '@biotmed/settings-sdk';

export const valuesFormatterDictionary = {
  // 400
  MANDATORY_ATTRIBUTES_ARE_MISSING: ({ mandatoryAttributesNames }: any) => {
    return {
      mandatoryAttributesName: mandatoryAttributesNames?.join(),
    };
  },
  SELECTED_ATTRIBUTE_CANT_BE_DISPLAY_ON_SECTION: ({
    invalidHeadlineAttributes,
    supportedHeadlineTypes,
    invalidChartAttributes,
    supportedChartTypes,
  }: any) => {
    return {
      invalidHeadlineAttributesLength: invalidHeadlineAttributes?.length || 0,
      invalidHeadlineAttributes: invalidHeadlineAttributes
        ?.map((attr: { name: string; basePath: any }) => attr.name)
        ?.join(),
      supportedHeadlineTypes: supportedHeadlineTypes?.join(),
      invalidChartAttributesLength: invalidChartAttributes?.length || 0,
      invalidChartAttributes: invalidChartAttributes?.map((attr: { name: string; basePath: any }) => attr.name)?.join(),
      supportedChartTypes: supportedChartTypes?.join(),
    };
  },
};
export const PortalBuilderErrorDictionary = defineMessages({
  // 400
  SELECTED_ATTRIBUTE_NOT_FOUND: {
    id: 'portal-builder.error.selected_attribute_not_found',
    defaultMessage: 'Selected attribute not found.', // REVIEW: params? [attributes, templateId, viewSection]
  },
  MANDATORY_ATTRIBUTES_ARE_MISSING: {
    id: 'portal-builder.error.mandatory-attributes-missing',
    defaultMessage: 'Mandatory attributes are missing: {mandatoryAttributesNames} ',
  },
  SELECT_REFERENCE_ATTRIBUTE_NOT_FOUND: {
    id: 'portal-builder.error.select_reference_attribute_not_found',
    defaultMessage: 'The selected reference attribute `{attributeName}` was not found.',
  },
  SELECTED_ATTRIBUTE_CANT_BE_DISPLAY_ON_SECTION: {
    id: 'portal-builder.error.selected_attribute_cant_be_display_on_section',
    defaultMessage:
      "{invalidHeadlineAttributesLength, plural, =0 {} other {Attributes: {invalidHeadlineAttributes} can't be displayed on headline. Supported types: [{supportedHeadlineTypes}]. }}\n {invalidChartAttributesLength, plural, =0 {} other {Attributes: {invalidChartAttributes} can't be displayed on chart. Supported types: [{supportedChartTypes}]. }}",
  },
  // 404
  VIEW_NOT_FOUND: {
    id: 'portal-builder.error.view_not_found',
    defaultMessage: 'View with id `{viewId}` was not found for portal type `{portalType}`.',
  },
});

export const viewTypesDictionary = {
  [ViewTypeEnum.EntityList]: {
    id: 'ViewType.entity-list',
    defaultMessage: 'List',
  },
  [ViewTypeEnum.TemplateExpand]: {
    id: 'ViewType.template-expand',
    defaultMessage: 'Add, Edit, Expand',
  },
  [ViewTypeEnum.TemplatePreview]: {
    id: 'ViewType.template-preview',
    defaultMessage: 'Preview',
  },
  [ViewTypeEnum.TemplateList]: {
    id: 'ViewType.template-list',
    defaultMessage: 'Template List',
  },
};
