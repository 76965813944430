import { SingleSelect, fileSizeUtils } from '@biotmed/base-components';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import NumberEditInput from 'src/components/NumberEditInput';
import { EntityTemplateForm } from 'src/routes/Templates/components/TemplateForm';
import { TypeComponentProps, TypeVariantComponentProps } from '../interfaces';
import TypeComponentContainer from '../TypeComponent';
import {
  StyledTitle,
  MaxSizeContainer,
  Separator,
  SizeValueContainer,
  SizeUnitContainer,
  HelperText,
} from './FileType.styled';

interface FileTypeVariantProps extends TypeVariantComponentProps {}

interface FileTypeProps extends TypeComponentProps {}

const FileType: React.FC<FileTypeProps> = props => {
  const { variant, attrFieldName, attributeValues, attributeErrors, attributeTouched } = props;
  const variantMap: Record<typeof variant, any> = {
    custom: CustomFileType,
    builtIn: BuiltInFileType,
  };
  const TypeComponent = variantMap[variant];

  return (
    <TypeComponent
      attrFieldName={attrFieldName}
      attributeValues={attributeValues}
      attributeErrors={attributeErrors}
      attributeTouched={attributeTouched}
    />
  );
};

const CustomFileType: React.FC<FileTypeVariantProps> = props => {
  return <FileTypeAttribute {...props} />;
};

const BuiltInFileType: React.FC<FileTypeVariantProps> = props => {
  return <FileTypeAttribute {...props} />;
};

const FileTypeAttribute: React.FC<FileTypeVariantProps> = props => {
  const { attrFieldName, attributeValues, readonly, attributeErrors, attributeTouched } = props;
  const sizeObject = fileSizeUtils.formatBytesToAny(attributeValues.validation.max);
  const [sizeUnit, setSizeUnit] = useState(
    attributeValues.validation.max ? sizeObject?.sizeUnit : fileSizeUtils.FileSizeUnitsEnum.KB,
  );
  const [sizeValue, setSizeValue] = useState(attributeValues.validation.max ? sizeObject?.sizeValue : '');

  const intl = useIntl();
  const formik = useFormikContext<EntityTemplateForm>();

  const handleSizeUnitChange = (event: any) => {
    setSizeUnit(event.target.value);
    handleMaxSizeChange(sizeValue, event.target.value ?? '');
  };

  const handleSizeValueChange = (value: any) => {
    setSizeValue(value);
    handleMaxSizeChange(value, sizeUnit);
  };

  const handleMaxSizeChange = (value: any, unit: any) => {
    formik.setFieldValue(
      `${attrFieldName}.validation.max`,
      value !== 0 && !value ? undefined : fileSizeUtils.maxSizeToBytes[unit ?? ''](value),
      true,
    );
    formik.setFieldTouched(`${attrFieldName}.validation.max`, true);
  };

  return (
    <TypeComponentContainer readonly={readonly}>
      <StyledTitle>
        {intl.formatMessage({
          id: 'template.modal.attribute.file-type.max-size',
          defaultMessage: 'Max size',
        })}
      </StyledTitle>
      <MaxSizeContainer>
        <SizeValueContainer>
          <NumberEditInput
            value={sizeValue}
            onChange={handleSizeValueChange}
            name={`${attrFieldName}.validation.max`}
            variant="standard"
            error={!!attributeErrors?.validation?.max && !!attributeTouched?.validation}
          />
        </SizeValueContainer>
        <Separator />
        <SizeUnitContainer>
          <SingleSelect
            selectList={[
              {
                value: fileSizeUtils.FileSizeUnitsEnum.BYTES,
              },
              {
                value: fileSizeUtils.FileSizeUnitsEnum.KB,
              },
              {
                value: fileSizeUtils.FileSizeUnitsEnum.MB,
              },
              {
                value: fileSizeUtils.FileSizeUnitsEnum.GB,
              },
            ]}
            value={sizeUnit}
            fullWidth
            inputProps={{
              variant: 'standard',
            }}
            onChange={handleSizeUnitChange}
          />
        </SizeUnitContainer>
      </MaxSizeContainer>
      <HelperText>{attributeTouched?.validation ? attributeErrors?.validation?.max : ''}</HelperText>
    </TypeComponentContainer>
  );
};

export default FileType;
