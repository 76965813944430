import { Button, ConfirmationTooltip, Icon } from '@biotmed/base-components';
import { EntityType, EntityTypeToIntlDisplayNameMap } from '@biotmed/data-components';
import { ClickAwayListener } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { ReactComponent as DeleteIcon } from '../../../../../../images/delete.svg';
import { ChildrenTypeButtonStyled, ChildrenTypeText, DeleteButtonContainer } from './ChildrenType.styled';

export interface ChildrenTypeProps {
  onDelete: () => void;
  onClick: (childrenType: any) => void;
  childrenType: any;
  entityName: EntityType;
}

const ChildrenType: React.FC<ChildrenTypeProps> = props => {
  const { onDelete, onClick, childrenType, entityName, ...rest } = props;
  const theme = useTheme();
  const intl = useIntl();
  const [showDeleteConfirmationTooltip, setShowDeleteConfirmationTooltip] = useState(false);

  const onConfirmDeleteClick = () => {
    setShowDeleteConfirmationTooltip(false);
    onDelete();
  };

  const onDeleteClick = () => {
    setShowDeleteConfirmationTooltip(!showDeleteConfirmationTooltip);
  };

  const closeDeleteConfirmationTooltip = () => {
    setShowDeleteConfirmationTooltip(false);
  };

  return (
    <ChildrenTypeButtonStyled
      onClick={() => onClick(childrenType)}
      type="button"
      key={childrenType.index}
      variant="outlined"
      size="large"
      textColor={theme.palette.grayScale.darkest}
      {...rest}
    >
      <ChildrenTypeText>{childrenType.displayName}</ChildrenTypeText>
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={closeDeleteConfirmationTooltip}>
        <DeleteButtonContainer onClick={event => event.stopPropagation()}>
          <ConfirmationTooltip
            okText={intl.formatMessage({
              id: 'template.modal.children-type.delete-tooltip.discard',
              defaultMessage: 'Discard',
            })}
            title={intl.formatMessage(
              {
                id: 'template.modal.children-type.delete-tooltip.title',
                defaultMessage: 'Are you sure you want to discard this {childrenTypeName}?',
              },
              {
                childrenTypeName: intl.formatMessage(EntityTypeToIntlDisplayNameMap[entityName]).toLowerCase(),
              },
            )}
            onOkClick={onConfirmDeleteClick}
            okButtonStyle={{
              backgroundColor: theme.palette.error.medium,
              borderColor: theme.palette.error.medium,
              textColor: theme.palette.grayScale.lightest,
            }}
            open={showDeleteConfirmationTooltip}
            placement="bottom-start"
            onClickButton={onDeleteClick}
            onCancelClick={closeDeleteConfirmationTooltip}
          >
            <Button>
              <Icon IconComponent={DeleteIcon} color={theme.palette.grayScale.darkest} />
            </Button>
          </ConfirmationTooltip>
        </DeleteButtonContainer>
      </ClickAwayListener>
    </ChildrenTypeButtonStyled>
  );
};

export default ChildrenType;
