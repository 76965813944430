import { Button, PopperWithAnchor, SubmitButtonStatus, FormikOnError } from '@biotmed/base-components';
import { EntityTypeToIntlDisplayNameMap, ErrorAdapterReturn } from '@biotmed/data-components';
import { ErrorResponse } from '@biotmed/settings-sdk';
import { GetTemplateResponse } from '@biotmed/sdk-api-provider/lib/types/settings';
import { Formik, FormikTouched, FormikErrors } from 'formik';
import React, { useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Entity } from 'src/redux/data/entity/modules/interfaces';
import { selectors } from 'src/redux/data/template';
import { useTheme } from 'styled-components';
import { scrollToTop } from 'src/utils/scrollUtils';
import {
  childrenTemplatesFieldName,
  entityTemplateFieldName,
  EntityTemplateFormInitialValues,
  fallbackMessage,
} from '../modules/constant';
import { stepComponentsMap } from '../modules/factory';
import { TemplateForm, EntityTemplateForm } from './TemplateForm';
import {
  EditTemplateForm,
  FullWidthErrorBanner,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Title,
  Tabs,
  ModalContentContainer,
  TemplateFormContainer,
} from './Templates.styled';
import { ApiError } from '../../../redux/data/template/modules/slice';
import { InUseError } from './formStepsComponents/InUseError/InUseError';
import LoadChildrenTemplate from './LoadChildrenTemplate';

// TODO: CHANGE TO ONE INTERFACE WITH ADD TEMPLATE INTEFACE
export interface EditTemplateProps {
  handleClose: () => void;
  entity: Entity;
  template: GetTemplateResponse;
  apiError?: ApiError;
  submitButtonStatus: SubmitButtonStatus;
  errorAdapter: (error: ErrorResponse, additionalData?: any) => ErrorAdapterReturn;
  submitForm: (
    entityType: string,
    templateId: string,
    values: EntityTemplateForm,
    originalChildrenTemplates: any,
    forceUpdate: boolean,
  ) => void;
}

const EditTemplate: React.FC<EditTemplateProps> = props => {
  const { handleClose, entity, template, apiError, submitButtonStatus, errorAdapter, submitForm } = props;
  const intl = useIntl();
  const theme = useTheme();
  const [pageIndex, setPageIndex] = useState(0);
  const [scrollToError, setScrollToError] = useState(false);
  const modalContentRef = useRef<any>(null);
  const [originalChildrenTemplates, setOriginalChildrenTemplates] = useState<GetTemplateResponse[]>([]);

  const onSubmit = (values: EntityTemplateForm) => {
    handleSubmitForm(values, false);
  };

  const onSubmitForced = (values: EntityTemplateForm) => {
    handleSubmitForm(values, true);
  };

  const handleSubmitForm = (values: EntityTemplateForm, forceUpdate: boolean) => {
    // TODO: REMOVE ?? '' FROM ENTITY TYPE AND TEMPLATE ID
    submitForm(template.entityTypeName ?? '', template.id ?? '', values, originalChildrenTemplates, forceUpdate);
  };

  const formsSteps = useMemo(() => {
    if (entity) {
      const stepsComponents = stepComponentsMap(entity, intl);
      return {
        tabsData: stepsComponents.map((step, index) => ({
          label: step.tabName,
          tabKey: step.tabName,
          value: index.toString(),
        })),
        components: stepsComponents.map(step => step.component),
        validationSchema: stepsComponents.map(step => step.validationSchema),
      };
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  const handleChange = (event: any, newValue: any) => {
    setPageIndex(newValue);
    scrollToTop(modalContentRef);
  };

  const resetScrollToError = () => {
    setScrollToError(false);
  };

  const onChangeTab = (
    validateForm: (values?: any) => Promise<FormikErrors<EntityTemplateForm>>,
    setTouched: (touched: FormikTouched<EntityTemplateForm>, shouldValidate?: boolean | undefined) => void,
  ) => {
    return async (event: React.ChangeEvent<any>, newValue: string) => {
      await validateForm().then((e: Object) => {
        setTouched(e);
        if (!Object.keys(e).length) {
          handleChange(event, newValue);
        } else {
          setScrollToError(true);
        }
      });
    };
  };

  const initialValues: EntityTemplateForm = useMemo(() => {
    if (template) {
      return {
        [entityTemplateFieldName]: {
          ...template,
          builtInAttributes: template.builtInAttributes
            ?.slice()
            .sort((a, b) => a.displayName.localeCompare(b.displayName)),
          customAttributes: template.customAttributes
            ?.slice()
            .sort((a, b) => a.id?.localeCompare(b.id) && a.displayName.localeCompare(b.displayName)),
        },
        [childrenTemplatesFieldName]: [],
      };
    }

    return EntityTemplateFormInitialValues;
  }, [template]);

  const processedApiError = apiError && errorAdapter(apiError?.error, apiError?.additionalData);

  const inUseCustomAttributes = useSelector(selectors.getInUseCustomAttributes);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formsSteps?.validationSchema[pageIndex]}
      onSubmit={onSubmit}
      validateOnBlur
      enableReinitialize
    >
      {({ handleSubmit, validateForm, setTouched, status }) => (
        <>
          <FormikOnError resetScrollToError={resetScrollToError} scrollToError={scrollToError}>
            <LoadChildrenTemplate
              templateId={template.id ?? ''}
              setOriginalChildrenTemplates={setOriginalChildrenTemplates}
            />
            <ModalHeader>
              {processedApiError && (
                <FullWidthErrorBanner {...processedApiError}>{processedApiError?.message}</FullWidthErrorBanner>
              )}
              <Title>
                {intl.formatMessage(
                  {
                    id: 'template-edit.modal.header-title',
                    defaultMessage: 'Edit {entityName} Template',
                  },
                  {
                    entityName:
                      entity?.name &&
                      intl.formatMessage(EntityTypeToIntlDisplayNameMap[entity.name] || fallbackMessage(entity.name)),
                  },
                )}
              </Title>
            </ModalHeader>
            <ModalContent>
              {formsSteps && (
                <ModalContentContainer>
                  <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={pageIndex.toString()}
                    tabsData={formsSteps.tabsData}
                    selectedTabKey={pageIndex.toString()}
                    padding="0"
                    onChange={onChangeTab(validateForm, setTouched)}
                  />
                  <EditTemplateForm>
                    <TemplateFormContainer ref={modalContentRef}>
                      <TemplateForm pageIndex={pageIndex} pages={formsSteps.components} />
                    </TemplateFormContainer>
                  </EditTemplateForm>
                </ModalContentContainer>
              )}
            </ModalContent>
            <ModalFooter>
              <Button
                paddingHorizontal="32px"
                paddingVertical="15px"
                textColor={theme.palette.grayScale.darker2}
                size="large"
                variant="text"
                onClick={handleClose}
              >
                {intl.formatMessage({
                  id: 'template-edit.modal.footer.cancel',
                  defaultMessage: 'Cancel',
                })}
              </Button>
              <PopperWithAnchor
                withArrow={false}
                open={!!inUseCustomAttributes.length}
                PopperComponent={<InUseError onSubmitForced={values => onSubmitForced(values)} />}
                handleClickAway={() => {}} // Can possibly be used to highlight the message to notify the user that he has to choose
                modifiers={[
                  {
                    name: 'offset',
                    enabled: true,
                    options: {
                      offset: [-150, 20],
                    },
                  },
                ]}
              >
                <Button
                  onClick={() => handleSubmit()}
                  submit
                  paddingHorizontal="32px"
                  paddingVertical="15px"
                  submitButtonProps={{
                    loadingLabel: intl.formatMessage({
                      id: 'template-edit.modal.footer.save-button.loading',
                      defaultMessage: 'Saving',
                    }),
                    successLabel: intl.formatMessage({
                      id: 'template-edit.modal.footer.save-button.success',
                      defaultMessage: 'Saved',
                    }),
                    status: submitButtonStatus,
                  }}
                >
                  {intl.formatMessage({
                    id: 'template-edit.modal.footer.save-button',
                    defaultMessage: 'Save Changes',
                  })}
                </Button>
              </PopperWithAnchor>
            </ModalFooter>
          </FormikOnError>
        </>
      )}
    </Formik>
  );
};
export default EditTemplate;
