import React, { useMemo } from 'react';
import { Category } from '@biotmed/sdk-api-provider/lib/types/settings';
import { FieldArray, FormikErrors, FormikTouched, useFormikContext } from 'formik';
import { Template } from 'src/redux/data/entity/modules/interfaces';
import { entityTemplateFieldName } from 'src/routes/Templates/modules/constant';
import { Attribute } from '../../Attribute';
import { EntityTemplateForm } from '../../TemplateForm';

type FormikTouchedCurrent = FormikTouched<Template['customAttributes']>;
type FormikErrorsCurrent = FormikErrors<Template['customAttributes']>;
interface BuiltInFieldArray {
  category: Category;
}
const BuiltInAttributesArrayField: React.FC<BuiltInFieldArray> = (props: any) => {
  const { category } = props;

  const formik = useFormikContext<EntityTemplateForm>();
  const { errors, values, touched, initialValues } = formik;

  const filteredBuiltInAttributes = useMemo(() => {
    const { builtInAttributes } = formik.values.entityTemplate;
    return builtInAttributes
      ?.map((builtInAttr: any, index: number) => ({ attr: builtInAttr, index }))
      ?.filter((builtInAttr: { attr: any; index: number }) => builtInAttr.attr.category?.name === category.name)
      ?.slice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.entityTemplate.builtInAttributes]);

  const builtInAttributesFieldName = `${entityTemplateFieldName}.builtInAttributes`;
  return (
    <FieldArray name={builtInAttributesFieldName}>
      {() => {
        return (
          <>
            {filteredBuiltInAttributes &&
              filteredBuiltInAttributes.length > 0 &&
              filteredBuiltInAttributes.map((attr: any) => {
                const attributeTouched = (touched?.entityTemplate?.builtInAttributes as FormikTouchedCurrent)?.[
                  attr.index
                ];
                const attributeErrors = (errors?.entityTemplate?.builtInAttributes as FormikErrorsCurrent)?.[
                  attr.index
                ];
                const attributeValues = values?.entityTemplate?.builtInAttributes?.[attr.index];
                const attributeInitialValues = initialValues?.entityTemplate?.builtInAttributes?.[attr.index];
                return (
                  <Attribute
                    key={attr.attr.name}
                    variant="builtIn"
                    attrFieldName={`${builtInAttributesFieldName}.${attr.index}`}
                    attributeTouched={attributeTouched}
                    attributeValues={attributeValues}
                    attributeErrors={attributeErrors}
                    attributeInitialValues={attributeInitialValues}
                    type={attr.attr.type}
                  />
                );
              })}
          </>
        );
      }}
    </FieldArray>
  );
};
export default BuiltInAttributesArrayField;
