import React from 'react';
import { AttributeTypeEnum } from '@biotmed/data-components';
import AttributeLayout, {
  BuiltIn,
  FlexSeparator,
  NameSection,
  PHISelectSection,
  Required,
  RowFlex,
  TypeSectionReadOnly,
} from 'src/components/AttributeLayout';
import { VerticalSection } from './Attribute.styled';

import { AttributeBaseProps } from './Attribute';

export interface SelectItem {
  value: string;
  label: string;
}

export interface BuiltInAttributeProps extends AttributeBaseProps {
  variant: 'builtIn';
  errors?: any;
  setPHI: (value: boolean) => void;
  setUniquely: (checked: boolean) => void;
}

export const BuiltInAttribute: React.FC<BuiltInAttributeProps> = props => {
  const {
    children,
    attributeValues,
    required = false,
    setRequired,
    setDisplayName,
    setPHI,
    setUniquely,
    attrFieldName,
    attributeErrors,
    attributeTouched,
  } = props;
  const attributeId = attributeValues?.basePath
    ? `${attributeValues.basePath}.${attributeValues.name}`
    : attributeValues.name;
  const headerComponent = (
    <VerticalSection>
      <NameSection
        value={attributeValues.displayName}
        tooltipValue={attributeValues.name}
        onChange={setDisplayName}
        autoFocus={false}
        canChangeTooltipValue={false}
        helperText={attributeTouched?.displayName ? attributeErrors?.displayName : ''}
        error={attributeTouched?.displayName && Boolean(attributeErrors?.displayName)}
        fieldName={`${attrFieldName}.displayName`}
        tooltipFieldName={`${attrFieldName}.name`}
        attributeId={attributeId}
        basePath={attributeValues?.basePath}
      />
      <TypeSectionReadOnly type={attributeValues.type} />
    </VerticalSection>
  );
  const footerComponent = (
    <RowFlex>
      <RowFlex>
        <BuiltIn />
        <PHISelectSection
          withUniquely={attributeValues.type === AttributeTypeEnum.Reference}
          uniquely={attributeValues?.referenceConfiguration?.uniquely}
          setUniquely={setUniquely}
          phi={attributeValues.phi}
          setPHI={setPHI}
        />
      </RowFlex>
      <FlexSeparator />
      <RowFlex>
        <Required
          required={required}
          setRequired={setRequired}
          readOnly={Boolean(attributeValues.validationMetadata?.mandatoryReadOnly)}
        />
      </RowFlex>
    </RowFlex>
  );
  return <AttributeLayout header={headerComponent} content={children} footer={footerComponent} />;
};

export default BuiltInAttribute;
