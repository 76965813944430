import React from 'react';
import {
  EntityTypeEnum,
  categoryToIntlDisplayNameMap,
  Category as CategoryType,
  EntityTypeToIntlDisplayNameInPluralMap,
  EntityType,
} from '@biotmed/data-components';
import { Entity } from 'src/redux/data/entity/modules/interfaces';
import { IntlShape } from 'react-intl';
import DetailsFields from '../components/formStepsComponents/DetailsFields/DetailsFields';
import Overview from '../components/formStepsComponents/Overview/Overview';
import { validationMap } from './validationFactory';
import ChildrenTypes from '../components/formStepsComponents/ChildrenTypes/ChildrenTypes';

export interface FormSteps {
  tabName: string;
  component: React.ReactNode;
  validationSchema: any;
}

const overviewStepCreator = (intl: any, formSteps: FormSteps[], withSelectOwnerOrganization: boolean) => {
  formSteps.push({
    tabName: intl.formatMessage({ id: 'template.modal.overview-tab.tab-name', defaultMessage: 'Overview' }),
    component: <Overview key="overview" withSelectOwnerOrganization={withSelectOwnerOrganization} />,
    validationSchema: validationMap(intl).overview,
  });
};

const detailsFieldStepCreator = (entity: Entity, intl: any, formSteps: FormSteps[]) => {
  entity.attributeCategories.forEach(category => {
    formSteps.push({
      tabName: `${intl.formatMessage(categoryToIntlDisplayNameMap[category.name as CategoryType])} 
      ${intl.formatMessage({ id: 'template.modal.category-tab.tab-name', defaultMessage: 'Fields' })}`,
      component: <DetailsFields category={category} key={category.name} />,
      validationSchema: validationMap(intl).detailsField(category.name),
    });
  });
};

const childrenCreator = (entity: Entity, intl: any, formSteps: FormSteps[]) => {
  entity?.children?.forEach(child => {
    const step = MapChildrenToSteps(intl, child).get(child.name as EntityType);
    if (step) {
      formSteps.push(step);
    }
  });
};

export const stepComponentsMap = (entity: Entity, intl: IntlShape): FormSteps[] =>
  mapComponentsByEntity[entity.name](entity, intl);

const mapComponentsByEntity: Record<EntityTypeEnum, (entity: Entity, intl: IntlShape) => FormSteps[]> = {
  [EntityTypeEnum.DEVICE]: (entity, intl) => {
    const formSteps: FormSteps[] = [];
    overviewStepCreator(intl, formSteps, true);
    detailsFieldStepCreator(entity, intl, formSteps);
    childrenCreator(entity, intl, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.ORGANIZATION]: (entity, intl) => {
    const formSteps: FormSteps[] = [];
    overviewStepCreator(intl, formSteps, false);
    detailsFieldStepCreator(entity, intl, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.PATIENT]: (entity, intl) => {
    const formSteps: FormSteps[] = [];
    overviewStepCreator(intl, formSteps, false);
    detailsFieldStepCreator(entity, intl, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.ORGANIZATION_USER]: (entity, intl) => {
    const formSteps: FormSteps[] = [];
    overviewStepCreator(intl, formSteps, true);
    detailsFieldStepCreator(entity, intl, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.CAREGIVER]: (entity, intl) => {
    const formSteps: FormSteps[] = [];
    overviewStepCreator(intl, formSteps, true);
    detailsFieldStepCreator(entity, intl, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.MANUFACTURER_USER]: (entity, intl) => {
    const formSteps: FormSteps[] = [];
    overviewStepCreator(intl, formSteps, false);
    detailsFieldStepCreator(entity, intl, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.USAGE_SESSION]: (entity, intl) => {
    const formSteps: FormSteps[] = [];
    overviewStepCreator(intl, formSteps, false);
    detailsFieldStepCreator(entity, intl, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.GENERIC_ENTITY]: (entity, intl) => {
    const formSteps: FormSteps[] = [];
    overviewStepCreator(intl, formSteps, true);
    detailsFieldStepCreator(entity, intl, formSteps);
    return formSteps;
  },
  [EntityTypeEnum.COMMAND]: (entity, intl) => {
    const formSteps: FormSteps[] = [];
    overviewStepCreator(intl, formSteps, false);
    detailsFieldStepCreator(entity, intl, formSteps);
    return formSteps;
  },
};

const MapChildrenToSteps = (intl: any, entity: Entity) =>
  new Map<EntityType, FormSteps>([
    [
      EntityTypeEnum.USAGE_SESSION,
      {
        tabName: intl.formatMessage(EntityTypeToIntlDisplayNameInPluralMap[EntityTypeEnum.USAGE_SESSION]),
        component: <ChildrenTypes entity={entity} key="usageTypes" />,
        validationSchema: validationMap(intl).usageTypes,
      },
    ],
    [
      EntityTypeEnum.COMMAND,
      {
        tabName: intl.formatMessage(EntityTypeToIntlDisplayNameInPluralMap[EntityTypeEnum.COMMAND]),
        component: <ChildrenTypes entity={entity} key="command" />,
        validationSchema: validationMap(intl).command,
      },
    ],
  ]);
