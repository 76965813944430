import { Checkbox, DraggableList, Table } from '@biotmed/base-components';
import React, { useMemo } from 'react';
import { AvailablePortalAttribute, SelectedPortalAttribute } from '@biotmed/settings-sdk';
import { useIntl } from 'react-intl';

import {
  DraggableListHeader,
  DraggableListSection,
  DraggableListWrapper,
  SectionContent,
  TableWrapper,
} from '../PortalBuilder.styled';

import {
  filterSelectedAttributesByExistAttributes,
  mapAttributes,
  mapDraggableItemsToSelectedAttributes,
  mapSelectedAttributesToDraggableItems,
} from '../../modules/mappers';

interface PortalViewCategoryRendererProps {
  title?: string;
  attributes?: AvailablePortalAttribute[];
  selected?: SelectedPortalAttribute[];
  onChange: (selectedItems: SelectedPortalAttribute[]) => void;
  fullScreen: boolean;
}

const PortalViewCategoryRenderer: React.FC<PortalViewCategoryRendererProps> = props => {
  const { title, attributes = [], onChange, fullScreen } = props;
  const intl = useIntl();

  // if null, the default value wont work... we should be able to remove this, but for now I keep it.
  const { selected = [] } = props;

  const mappedAttributes: any = useMemo(() => {
    return attributes ? mapAttributes(attributes) : {};
  }, [attributes]);

  const handleClickCheckBox = (checked: boolean, record: any) => {
    if (checked) {
      onChange(selected.filter((item: any) => item?.name !== record.name || item?.basePath !== record.basePath));
    } else {
      onChange([...selected, { name: record.name, basePath: record.basePath }]);
    }
  };

  return (
    <SectionContent fullScreen={fullScreen}>
      <TableWrapper>
        <Table
          scroll={{ y: 'auto' }}
          columns={[
            {
              title,
              dataIndex: 'displayName',
              key: 'name',
              showSorterTooltip: false,
              sorter: (a: any, b: any) => a?.displayName.localeCompare(b?.displayName, { sensitivity: 'base' }),
              sortOrder: 'ascend',
              colSpan: 2,
            },
          ]}
          dataSource={attributes.map(item => ({ ...item, key: `${item.basePath}.${item.name}` }))}
          rowSelection={{
            columnWidth: 40,
            hideSelectAll: true,
            selectedRowKeys: selected.map((row: any) => {
              return `${row.basePath}.${row.name}`;
            }),
            renderCell: (checked: boolean, record: any) => {
              return (
                <Checkbox
                  key={`checkbox${record.name}`}
                  checked={checked}
                  disabled={record.readOnly}
                  onClick={() => handleClickCheckBox(checked, record)}
                />
              );
            },
          }}
          totalItems={attributes.length}
          pageSize={attributes.length}
          pagination={{ hideOnSinglePage: true }}
        />
      </TableWrapper>
      <DraggableListSection>
        <DraggableListHeader>
          {intl.formatMessage({
            id: 'portalBuilder.ViewOrder',
            defaultMessage: 'View order',
          })}
        </DraggableListHeader>
        <DraggableListWrapper fullScreen={fullScreen}>
          <DraggableList
            items={mapSelectedAttributesToDraggableItems(
              // TODO: MAYBE REMOVE THIS WHEN FIXED IN BE
              filterSelectedAttributesByExistAttributes(selected, mappedAttributes),
              mappedAttributes,
            )}
            onItemsChange={items => {
              onChange(mapDraggableItemsToSelectedAttributes(items, mappedAttributes));
            }}
          />
        </DraggableListWrapper>
      </DraggableListSection>
    </SectionContent>
  );
};

export default PortalViewCategoryRenderer;
