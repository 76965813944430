import { createSlice, createSelector, createAction } from '@reduxjs/toolkit';

import { RootState } from '../../../reducer';
import { DATA_STATE_KEY } from '../../constants';

export const STATE_KEY = 'app';

interface AppState {
  isAppLoading: boolean;
  messagesLoaded: boolean;
}

export const getInitialState = (state?: any): AppState => ({
  // eslint-disable-next-line no-use-before-define
  isAppLoading: state ? getIsAppLoading(state) : true,
  messagesLoaded: false,
});

const getState = (state: RootState) => state[DATA_STATE_KEY][STATE_KEY] || getInitialState();
const getIsAppLoading = createSelector(getState, state => state.isAppLoading);
const getIsMessagesLoaded = createSelector(getState, state => state.messagesLoaded);

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    appStartFinish: (state, action) => {
      state.isAppLoading = action.payload;
      return state;
    },
    onFetchMessagesSuccess: state => {
      state.messagesLoaded = true;
      return state;
    },
  },
});
/* eslint-enable no-param-reassign */

// For saga/reducer-less actions
const extraActions = {
  appStart: createAction(`${STATE_KEY}/appStart`),
};

export const selectors = {
  getIsAppLoading,
  getIsMessagesLoaded,
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
