import styled from 'styled-components';
import { PageMainTitle, PageLayout } from 'src/components/CommonStyledComponents';
import {
  ErrorBanner,
  convertTypographySettingsToCss,
  fontWeightNameMappings,
  Tabs as muiTabs,
} from '@biotmed/base-components';

export const FullWidthErrorBanner = styled(ErrorBanner)`
  flex: 1 1 100%;
`;

export const TextWrapper = styled.div``;

export const Title = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h5)};
`;
export const Description = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  color: ${props => props.theme.palette.grayScale.darker};
  margin-top: 5px;
`;
export const Steps = styled.div`
  margin-right: 60px;
`;
export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
export const ModalHeader = styled.div`
  display: flex;
  flex: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 12px 34px;
  background-color: ${props => props.theme.palette.grayScale.lightest};
  border-top-left-radius: 4px;
  gap: 8px;
  border-bottom: solid 1px ${props => `${props.theme.palette.grayScale.medium}${props.theme.opacity.extraTransparent}`};
  margin-right: 60px;
`;
export const ModalContent = styled.div`
  background-color: ${props => props.theme.palette.primary.lighter};
  flex: 1;
  overflow-y: auto;
`;
export const ModalContentContainer = styled.div<{ add?: boolean }>`
  display: flex;
  padding: ${props => (props?.add ? '16px 32px' : '0')};
  flex-direction: column;
  gap: ${props => (props?.add ? '16px' : '0')};
  overflow-y: ${props => (props?.add ? 'unset' : 'auto')};
  max-height: ${props => (props?.add ? 'auto' : '100%')};
`;
export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 9px 29px;
  justify-content: flex-end;
  border-top: solid 1px ${props => props.theme.palette.grayScale.medium};
  background: ${props => props.theme.palette.grayScale.lightest};
  flex: 0;
  padding-right: 100px;
`;
export const FormContent = styled.div`
  flex: 1;
`;
export const StyleForm = styled.form``;
export const BackButtonContainer = styled.div`
  flex: 1;
`;
export const FieldArrayContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

export const TemplatesPageLayout = styled.div`
  display: grid;
  grid-template-columns: 5fr minmax(200px, 300px);
  height: 100%;
`;

export const ContentWrapper = styled(PageLayout)`
  overflow-y: auto;
  padding-right: 20px;

  .ant-pagination-item {
    line-height: 32px;
  }

  .ant-pagination-total-text {
    margin-top: 2px;
  }

  tr[data-row-key='selected-template'] {
    background-color: ${props => `${props.theme.palette.primary.dark}${props.theme.opacity.almostTransparent}`};
  }
`;

export const StyledPageTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TemplatesTitle = styled(PageMainTitle)``;

export const TemplatesTotalSubTitle = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h7)};
  margin-right: 10px;
  margin-top: 5px;
`;

export const TemplatesTotalRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
`;

export const CategoryContent = styled.div`
  font-weight: ${fontWeightNameMappings.semiBold};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
export const Tabs = styled(muiTabs)`
  flex: 0;
  padding: 0 32px;
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
`;
export const EditTemplateForm = styled.div`
  flex: 1;
  overflow-y: auto;
  max-height: 100%;
  padding-right: 55px;
`;
export const TemplateFormContainer = styled.div`
  padding: 16px 32px;
`;

export const AddTemplateContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-right: 55px;
`;

export const RightSide = styled.div`
  width: 296px;
`;
