import { defineMessages } from 'react-intl';

export const inUseIntlMessages = defineMessages({
  messageHeader: {
    id: 'templates.in-use-fields-message.header',
    defaultMessage: 'Delete All Observations?',
    description: 'In use fields message - header',
  },
  warningLine: {
    id: 'templates.in-use-fields-message.sub-header-warning',
    defaultMessage: 'Delete all observation fields data?',
    description: 'In use fields message - Delete all in-use fields data!',
  },
  contentPartOne: {
    id: 'templates.in-use-fields-message.content-part-one',
    defaultMessage: 'You changed or removed the following fields:',
    description: 'In use fields message - Some of the attributes are in use...',
  },
  contentPartTwo: {
    id: 'templates.in-use-fields-message.content-part-two',
    defaultMessage:
      'To apply this change we will delete the observations database (i.e. also observations stored on other fields).',
    description: 'In use fields message - We already stored observations...will have to delete...',
  },
  contentPartThree: {
    id: 'templates.in-use-fields-message.content-part-three',
    defaultMessage:
      'Otherwise, the changed or deleted observation fields in the form will be reverted to their initial values. Other unsaved changes will remain and you will be able to continue editing the form.',
    description: 'In use fields message - If you choose not to remove the data...',
  },
  cancel: {
    id: 'templates.in-use-fields-message.cancel',
    defaultMessage: 'Cancel',
    description: 'In use fields message - Cancel button',
  },
  continue: {
    id: 'templates.in-use-fields-message.continue',
    defaultMessage: 'Delete Observations Database',
    description: 'In use fields message - Continue button',
  },
});
