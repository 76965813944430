// eslint-disable-next-line no-use-before-define
import { Snackbar } from '@biotmed/base-components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivatePage, PublicPage } from './Routes';
import { Spin, SpinBox } from './styled';

import { selectors as appSelectors } from '../../../redux/data/app';
import { actions as appActions } from '../../../redux/data/app/modules/slice';
import { selectors as loginSelectors } from '../../../redux/data/login/modules/slice';
import RoutesEnum from '../modules/Routes';

interface NavigationProps {}

const Navigation: React.FC<NavigationProps> = () => {
  const isLoggedIn = !!useSelector(loginSelectors.getIsLoggenIn);
  const isAppLoading = useSelector(appSelectors.getIsAppLoading);
  const dispatch = useDispatch();

  const _onAppStart = () => {
    dispatch(appActions.appStart());
  };

  // eslint-disable-next-line
  useEffect(_onAppStart, []);

  return (
    <>
      {isAppLoading ? (
        <SpinBox>
          <Spin />
        </SpinBox>
      ) : (
        <Snackbar>
          <Switch>
            <Route sensitive path={`/${RoutesEnum.AUTH}`} render={() => <PublicPage isLoggedIn={isLoggedIn} />} />
            <Route path="/*" render={() => <PrivatePage isLoggedIn={isLoggedIn} />} />
            <Redirect to="/" />
          </Switch>
        </Snackbar>
      )}
    </>
  );
};

export default Navigation;
