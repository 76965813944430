import { createSlice, createSelector, createAction, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { GetTemplateResponse, SearchRequestV2 } from '@biotmed/sdk-api-provider/lib/types/settings';
import { ErrorResponse } from '@biotmed/settings-sdk';
import { SubmitButtonStatus } from '@biotmed/base-components';
import { Template } from '../../entity/modules/interfaces';
import { RootState } from '../../../reducer';
import { DATA_STATE_KEY } from '../../constants';

export const STATE_KEY = 'template';

// eslint-disable-next-line no-shadow
export enum RequestType {
  ADD = 'ADD',
  EDIT = 'EDIT',
  LIST = 'LIST',
  DELETE = 'DELETE',
  ADD_PARTIAL = 'ADD_PARTIAL',
  EDIT_PARTIAL = 'EDIT_PARTIAL',
}
export interface ApiError {
  error: ErrorResponse;
  requestType: RequestType;
  additionalData?: Record<string, any>;
}

const FILTER_TABLE_TEMPLATES = Object.freeze({
  parentTemplateId: { isNull: true },
});

export interface InUseCustomAttribute {
  id: string;
  name: string;
}

interface TemplateState {
  searchRequest: SearchRequestV2;
  freeTextSearch: string;
  allTemplates: any;
  templates: any;
  apiError?: ApiError;
  addEditStatus: SubmitButtonStatus;
  deleteStatus: SubmitButtonStatus;
  isOpen: boolean;
  organizationList?: any;
  loading?: boolean;
  inUseCustomAttributes: InUseCustomAttribute[];
}

const templatesAdapter = createEntityAdapter<GetTemplateResponse>({});
const allTemplatesAdapter = createEntityAdapter<GetTemplateResponse>({});

export const getInitialState = (state?: TemplateState): TemplateState => ({
  searchRequest: { limit: 10 },
  templates: templatesAdapter.getInitialState({ templatesTotal: 0 }),
  allTemplates: allTemplatesAdapter.getInitialState({}),
  addEditStatus: SubmitButtonStatus.NORMAL,
  deleteStatus: SubmitButtonStatus.NORMAL,
  isOpen: false,
  loading: false,
  inUseCustomAttributes: [],
  freeTextSearch: '',
});

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    onLoadTemplate: state => {
      state.addEditStatus = SubmitButtonStatus.NORMAL;
      state.loading = true;
    },
    onLoadTemplatesSuccess: (
      state,
      action: PayloadAction<{ templatesList: Array<GetTemplateResponse>; templatesTotal: number }>,
    ) => {
      templatesAdapter.setAll(state.templates, action.payload.templatesList);
      state.templates.templatesTotal = action.payload.templatesTotal;
      state.deleteStatus = SubmitButtonStatus.NORMAL;
      state.apiError = undefined;
      state.loading = false;
    },
    onLoadAllTemplatesSuccess: (state, action: PayloadAction<Array<GetTemplateResponse>>) => {
      allTemplatesAdapter.setAll(state.allTemplates, action.payload);
    },
    onLoadTemplatesFail: (state, action: PayloadAction<ErrorResponse>) => {
      state.apiError = { error: action.payload, requestType: RequestType.LIST };
      state.loading = false;
    },
    createTemplate: (state, action: PayloadAction<{ entityTemplate: Template; childrenTemplates?: Template[] }>) => {
      state.addEditStatus = SubmitButtonStatus.LOADING;
    },
    createTemplateSuccess: state => {
      state.searchRequest = { page: 0, limit: state.searchRequest.limit };
      state.addEditStatus = SubmitButtonStatus.SUCCESS;
    },
    createTemplateFail: (state, action: PayloadAction<ErrorResponse>) => {
      state.addEditStatus = SubmitButtonStatus.NORMAL;
      state.apiError = { error: action.payload, requestType: RequestType.ADD };
    },
    createTemplateFailedPartial: (state, action: PayloadAction<{ error: ErrorResponse; additionalData: any }>) => {
      state.addEditStatus = SubmitButtonStatus.NORMAL;
      state.apiError = {
        error: action.payload.error,
        requestType: RequestType.ADD_PARTIAL,
        additionalData: action.payload.additionalData,
      };
    },
    editTemplate: (
      state,
      action: PayloadAction<{
        entityType: string;
        templateId: string;
        entityTemplate: Template;
        // TODO MAYBE CONVERT TO UPDATE_TEMPLATE_REQUEST BEFORE SENT TO THE ACTION!!
        childrenTemplates: Template[];
        originalChildrenTemplates: any;
        forceUpdate?: boolean;
      }>,
    ) => {
      state.addEditStatus = SubmitButtonStatus.LOADING;
    },
    editTemplateSuccess: state => {
      state.addEditStatus = SubmitButtonStatus.SUCCESS;
    },
    editTemplateFail: (state, action: PayloadAction<ErrorResponse>) => {
      state.addEditStatus = SubmitButtonStatus.NORMAL;
      state.apiError = { error: action.payload, requestType: RequestType.EDIT };
    },
    editTemplateFailedPartial: (state, action: PayloadAction<{ error: ErrorResponse; additionalData?: any }>) => {
      state.addEditStatus = SubmitButtonStatus.NORMAL;
      state.apiError = {
        error: action.payload.error,
        requestType: RequestType.EDIT_PARTIAL,
        additionalData: action.payload.additionalData,
      };
    },
    deleteTemplate: (state, action: PayloadAction<any>) => {
      state.deleteStatus = SubmitButtonStatus.LOADING;
    },
    deleteTemplateSuccess: state => {
      state.deleteStatus = SubmitButtonStatus.NORMAL;
    },
    deleteTemplateFailed: (state, action: PayloadAction<ErrorResponse>) => {
      state.deleteStatus = SubmitButtonStatus.NORMAL;
      state.apiError = { error: action.payload, requestType: RequestType.DELETE };
    },
    openModal: state => {
      state.addEditStatus = SubmitButtonStatus.NORMAL;
      state.apiError = undefined;
      state.isOpen = true;
    },
    closeModal: state => {
      state.isOpen = false;
      state.addEditStatus = SubmitButtonStatus.NORMAL;
      state.apiError = undefined;
    },
    updateFreeTextSearch: (state, action: PayloadAction<{ freeTextSearch: string }>) => {
      state.freeTextSearch = action.payload.freeTextSearch;
      state.searchRequest.page = 0;
    },
    updateSearchRequest: (state, action: PayloadAction<SearchRequestV2>) => {
      state.searchRequest = action.payload;
    },
    resetSearchRequest: state => {
      state.searchRequest = getInitialState().searchRequest;
      state.freeTextSearch = getInitialState().freeTextSearch;
    },
    onCustomAttributeInUseError: (state, action: PayloadAction<InUseCustomAttribute[]>) => {
      state.inUseCustomAttributes = action.payload;
    },
    onCustomAttributeInUseErrorApprove: state => {
      state.inUseCustomAttributes = [];
    },
    onCustomAttributeInUseErrorCancel: state => {
      state.inUseCustomAttributes = [];
      state.addEditStatus = SubmitButtonStatus.NORMAL;
    },
    initError: state => {
      state.apiError = undefined;
    },
  },
});
/* eslint-enable no-param-reassign */

const getState = (state: RootState) => state[DATA_STATE_KEY][STATE_KEY] || getInitialState();

export const selectors = {
  getTemplatesTotal: createSelector(getState, state => state.templates.templatesTotal),
  selectFreeTextSearch: createSelector(getState, state => state.freeTextSearch),
  selectSearchRequest: createSelector(getState, state => ({
    ...state.searchRequest,
    filter: { ...state.searchRequest.filter, ...FILTER_TABLE_TEMPLATES },
    freeTextSearch: state.freeTextSearch,
  })),
  selectPageLimit: createSelector(getState, state => state.searchRequest.limit ?? 0),
  selectCurrentPage: createSelector(getState, state => state.searchRequest.page ?? 0),
  getTemplatesList: createSelector(getState, state => templatesAdapter.getSelectors().selectAll(state.templates)),
  getAllTemplatesListByEntityType: (entityType: string) =>
    createSelector(getState, state =>
      allTemplatesAdapter
        .getSelectors()
        .selectAll(state.allTemplates)
        .filter((template: GetTemplateResponse) => template.entityTypeName === entityType),
    ),
  getTemplateById: (templateId: string) =>
    createSelector(getState, state => templatesAdapter.getSelectors().selectById(state.templates, templateId)),
  getTemplateByEntityType: (entityType: string) =>
    createSelector(getState, state =>
      templatesAdapter
        .getSelectors()
        .selectAll(state.templates)
        .filter(x => x.entityTypeName === entityType),
    ),
  getApiError: createSelector(getState, state => state.apiError),
  getAddEditStatus: createSelector(getState, state => state.addEditStatus),
  getDeleteStatus: createSelector(getState, state => state.deleteStatus),
  getIsOpen: createSelector(getState, state => state.isOpen),
  selectLoading: createSelector(getState, state => state.loading),
  getInUseCustomAttributes: createSelector(getState, state => state.inUseCustomAttributes),
};

const extraActions = {
  onLoadAllTemplate: createAction(`${STATE_KEY}/onLoadAllTemplate`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
