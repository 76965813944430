import React, { useState, useEffect, useRef } from 'react';
import { Input } from '@biotmed/base-components';
import { toJsonNameFormat } from 'src/utils/stringUtils';
import { InputAdornment } from '@mui/material';
import { TooltipButton } from './Tooltip';
import { InputWithTooltipWrapper, StyledTitle } from './InputWithTooltip.styled';

export type OnChangeTooltipValueReason = 'inputChange' | 'clickAway' | 'byValue';

export interface InputWithTooltipProps {
  value?: string | null;
  tooltipValue?: string;
  onChange?: (value: string) => void;
  onBlur?: (e: any) => void;
  fieldName?: string;
  tooltipFieldName?: string;
  autoFocus?: boolean;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  bold?: boolean;
}

interface TooltipInputCanChangeProps {
  onChange?: (value: string | null, reason: OnChangeTooltipValueReason) => void;
  onSave?: (value: string | null) => void;
  onBlur?: (e: any) => void;
  updateTooltipValueByValue?: boolean;
  error?: boolean;
  placeholder?: string;
  helperText?: string;
}
export type TooltipInputCanChangeType =
  | { canChangeTooltipValue?: false; tooltipInputCanChangeProps?: never }
  | { canChangeTooltipValue: true; tooltipInputCanChangeProps: TooltipInputCanChangeProps };

const InputWithTooltip: React.FC<InputWithTooltipProps & TooltipInputCanChangeType> = props => {
  const {
    value = null,
    tooltipValue,
    canChangeTooltipValue = false,
    tooltipInputCanChangeProps,
    onChange,
    onBlur,
    fieldName = '',
    tooltipFieldName = '',
    autoFocus = false,
    placeholder = '',
    error = false,
    helperText,
    bold = true,
    ...rest
  } = props;

  const [inputInFocus, setInputInFocus] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus) {
      setInputInFocus(true);
    }
  }, [autoFocus]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newValue = event.target.value;
    if (tooltipInputCanChangeProps) {
      tooltipInputCanChangeProps.onChange?.(toJsonNameFormat(newValue), 'byValue');
    }
    onChange?.(newValue);
  };

  const handleBlur = (event: any) => {
    if (!error) {
      if (!openTooltip) setInputInFocus(false);
    }

    onBlur?.(event);
  };

  const handleJSONSave = (newValue: string | null) => {
    tooltipInputCanChangeProps?.onSave?.(newValue);
    setOpenTooltip(false);
    inputRef?.current?.focus();
  };

  return (
    <InputWithTooltipWrapper bold={bold}>
      {inputInFocus || error || tooltipInputCanChangeProps?.error || openTooltip ? (
        <Input
          type="text"
          inputRef={inputRef}
          name={fieldName}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          onFocus={() => setInputInFocus(true)}
          onBlur={handleBlur}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" disablePointerEvents={false}>
                <TooltipButton
                  name={tooltipFieldName}
                  open={openTooltip}
                  onOpenChange={setOpenTooltip}
                  {...(tooltipValue || tooltipValue === null ? { value: tooltipValue } : {})}
                  {...(canChangeTooltipValue
                    ? {
                        readOnly: false,
                        onClickSave: handleJSONSave,
                        ...tooltipInputCanChangeProps,
                        ...rest,
                      }
                    : { readOnly: true, ...rest })}
                />
              </InputAdornment>
            ),
          }}
          autoFocus={autoFocus || inputInFocus}
        />
      ) : (
        <StyledTitle
          bold={bold}
          className={`jsonInput ${value ? 'value' : 'placeholder'}`}
          onClick={() => setInputInFocus(true)}
        >
          {value && value !== '' ? value : placeholder}
        </StyledTitle>
      )}
    </InputWithTooltipWrapper>
  );
};

export default InputWithTooltip;
