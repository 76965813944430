import {
  Explained,
  Button,
  SubmitButtonStatus,
  FormikOnError,
  ReactIntlMessageExtraValues,
} from '@biotmed/base-components';
import { EntityTypeToIntlDisplayNameMap, ErrorAdapterReturn } from '@biotmed/data-components';
import { ErrorResponse } from '@biotmed/settings-sdk';
import React, { useMemo, useRef, useState } from 'react';
import { Entity } from 'src/redux/data/entity/modules/interfaces';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { Formik, FormikTouched, FormikErrors } from 'formik';
import { scrollToTop } from 'src/utils/scrollUtils';
import { stepComponentsMap } from '../modules/factory';
import { EntityTemplateForm, TemplateForm } from './TemplateForm';
import {
  BackButtonContainer,
  FullWidthErrorBanner,
  ModalContainer,
  ModalContent,
  ModalContentContainer,
  ModalFooter,
  ModalHeader,
  Steps,
  Title,
  AddTemplateContentWrapper,
} from './Templates.styled';
import {
  entityTemplateFieldName,
  EntityTemplateFormInitialValues,
  fallbackMessage,
  TEMPLATE_TYPE_TO_LEARN_MORE_URL,
} from '../modules/constant';
import { ApiError } from '../../../redux/data/template/modules/slice';

export interface AddTemplateProps {
  entity: Entity;
  handleClose: () => void;
  apiError?: ApiError;
  submitButtonStatus: SubmitButtonStatus;
  errorAdapter: (error: ErrorResponse, additionalData?: ReactIntlMessageExtraValues) => ErrorAdapterReturn;
  submitForm: (
    entityType: string,
    templateId: string,
    values: EntityTemplateForm,
    originalChildrenTemplates: any,
    forceUpdate: boolean,
  ) => void;
}

const ButtonTypesMap = {
  next: (oldIndex: number) => oldIndex + 1,
  prev: (oldIndex: number) => oldIndex - 1,
};

type ButtonTypes = keyof typeof ButtonTypesMap;

const AddTemplate: React.FC<AddTemplateProps> = props => {
  const { handleClose, entity, apiError, submitButtonStatus, errorAdapter, submitForm } = props;
  const [pageIndex, setPageIndex] = useState(0);
  const [scrollToError, setScrollToError] = useState(false);
  const intl = useIntl();
  const theme = useTheme();
  const modalContentRef = useRef<any>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formsSteps = useMemo(() => (entity ? stepComponentsMap(entity, intl) : null), [entity]);

  const initialValues: EntityTemplateForm = useMemo(() => {
    if (entity) {
      const { builtInAttributes, templateAttributes } = entity;

      return {
        ...EntityTemplateFormInitialValues,
        [entityTemplateFieldName]: {
          ...EntityTemplateFormInitialValues?.[entityTemplateFieldName],
          builtInAttributes: builtInAttributes?.slice().sort((a, b) => a.displayName.localeCompare(b.displayName)),
          templateAttributes,
          entityTypeName: entity.name,
        },
      };
    }
    return { ...EntityTemplateFormInitialValues };
  }, [entity]);

  const onSubmit = (values: EntityTemplateForm) => {
    submitForm(values.entityTemplate.entityTypeName ?? '', '', values, [], false);
  };

  const handleChangePageIndex = (type: ButtonTypes) => {
    setPageIndex(oldPageIndex => ButtonTypesMap[type](oldPageIndex));
  };
  const handleOnClickNext = () => {
    handleChangePageIndex('next');
    scrollToTop(modalContentRef);
  };
  const handleOnClickPrev = () => {
    handleChangePageIndex('prev');
    scrollToTop(modalContentRef);
  };

  const onChangePage = async (
    validateForm: (values?: any) => Promise<FormikErrors<EntityTemplateForm>>,
    setTouched: (touched: FormikTouched<EntityTemplateForm>, shouldValidate?: boolean | undefined) => void,
    followUpFunction: () => void,
  ) => {
    await validateForm().then((e: any) => {
      setTouched(e);
      if (!Object.keys(e).length) {
        followUpFunction();
      } else {
        setScrollToError(true);
      }
    });
  };

  const resetScrollToError = () => {
    setScrollToError(false);
  };

  const stepsCount = formsSteps?.length || 0;
  const isLastPage = pageIndex === stepsCount - 1;
  const isFirstPage = pageIndex === 0;

  const entityName =
    entity?.name && intl.formatMessage(EntityTypeToIntlDisplayNameMap[entity.name] || fallbackMessage(entity.name));

  const onLearnMoreClick = () => {
    window.open(learnMoreUrl);
  };

  const processedApiError = apiError && errorAdapter(apiError?.error, apiError?.additionalData);

  const learnMoreUrl = TEMPLATE_TYPE_TO_LEARN_MORE_URL[entity?.name];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formsSteps?.[pageIndex]?.validationSchema}
      onSubmit={onSubmit}
      validateOnBlur
    >
      {({ handleSubmit, validateForm, setTouched, errors, values, ...rest }) => {
        return (
          <FormikOnError scrollToError={scrollToError} resetScrollToError={resetScrollToError}>
            <ModalContainer>
              <ModalHeader>
                {processedApiError && (
                  <FullWidthErrorBanner {...processedApiError}>{processedApiError?.message}</FullWidthErrorBanner>
                )}
                {/* TODO: CHANGE TO DISPLAY NAME */}
                <Title>
                  {intl.formatMessage(
                    { id: 'template-add.modal.header-title', defaultMessage: 'New {entityName} Template' },
                    {
                      entityName:
                        entity?.name &&
                        intl.formatMessage(EntityTypeToIntlDisplayNameMap[entity.name] || fallbackMessage(entity.name)),
                    },
                  )}
                </Title>
                <Steps>
                  {intl.formatMessage(
                    { id: 'template-add.modal.header-steps', defaultMessage: 'Step {pageIndex}/{stepsCount}' },
                    { pageIndex: pageIndex + 1, stepsCount },
                  )}
                </Steps>
              </ModalHeader>
              <ModalContent>
                <ModalContentContainer add ref={modalContentRef}>
                  <AddTemplateContentWrapper>
                    <Explained
                      text={intl.formatMessage(
                        {
                          id: 'template-add.modal.explained',
                          defaultMessage:
                            'The {entityName} template enables you to build the data model of your specific {entityNameLowercase}',
                        },
                        { entityName, entityNameLowercase: entityName.toLocaleLowerCase() },
                      )}
                      onLearnMore={learnMoreUrl ? onLearnMoreClick : undefined}
                    />
                    <TemplateForm pageIndex={pageIndex} pages={formsSteps && formsSteps.map(step => step.component)} />
                  </AddTemplateContentWrapper>
                </ModalContentContainer>
              </ModalContent>
              <ModalFooter>
                {!isFirstPage && (
                  <BackButtonContainer>
                    <Button
                      bold
                      textColor={theme.palette.grayScale.darkest}
                      size="large"
                      onClick={() => onChangePage(validateForm, setTouched, handleOnClickPrev)}
                    >
                      &lt;
                      {intl.formatMessage({
                        id: 'template-add.modal.footer.back-button',
                        defaultMessage: 'Back',
                      })}
                    </Button>
                  </BackButtonContainer>
                )}
                <Button
                  paddingHorizontal="32px"
                  paddingVertical="15px"
                  textColor={theme.palette.grayScale.darker2}
                  size="large"
                  variant="text"
                  onClick={handleClose}
                >
                  {intl.formatMessage({
                    id: 'template-add.modal.footer.cancel-button',
                    defaultMessage: 'Cancel',
                  })}
                </Button>
                {!isLastPage && (
                  <Button
                    paddingHorizontal="32px"
                    paddingVertical="15px"
                    variant="contained"
                    size="large"
                    onClick={() => onChangePage(validateForm, setTouched, handleOnClickNext)}
                  >
                    {intl.formatMessage({
                      id: 'template-add.modal.footer.next-button',
                      defaultMessage: 'Next',
                    })}
                  </Button>
                )}
                {isLastPage && (
                  <Button
                    variant="contained"
                    size="large"
                    paddingHorizontal="32px"
                    paddingVertical="15px"
                    onClick={() => handleSubmit()}
                    submit
                    submitButtonProps={{
                      loadingLabel: intl.formatMessage({
                        id: 'template-add.modal.footer.save-button.loading',
                        defaultMessage: 'Creating',
                      }),
                      successLabel: intl.formatMessage({
                        id: 'template-add.modal.footer.save-button.success',
                        defaultMessage: 'Created',
                      }),
                      status: submitButtonStatus,
                    }}
                  >
                    {intl.formatMessage({
                      id: 'template-add.modal.footer.save-button',
                      defaultMessage: 'Create',
                    })}
                  </Button>
                )}
              </ModalFooter>
            </ModalContainer>
          </FormikOnError>
        );
      }}
    </Formik>
  );
};

export default AddTemplate;
