import styled from 'styled-components';
import { PageMainTitle, PageLayout } from 'src/components/CommonStyledComponents';
import { convertTypographySettingsToCss } from '@biotmed/base-components';
import { contentPadding } from 'src/components/AppLayout';
import { maxHeightContent, maxHeightForMultipleCategories } from '../modules/constant';

const sectionContentPaddingRight = '24px';
const sectionContentPaddingLeft = '14px';

export const StyledPortalBuilderPage = styled(PageLayout)`
  padding-left: 50px;
  height: 100%;
`;

export const PortalBuilderHeader = styled(PageLayout)`
  margin-top: 5px;
  padding: 0;
  padding-top: ${contentPadding};
`;

export const PortalBuilderTitle = styled(PageMainTitle)``;

export const SelectorsRow = styled.div`
  display: flex;
  padding: 15px 5px 0;
  flex-direction: row;

  .single-select:not(:last-of-type) {
    margin-right: 20px;
  }

  .single-select,
  .autocomplete {
    max-width: 450px;
  }
`;

export const SectionsOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  padding-right: 30px;
  height: 100%;
  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.palette.grayScale.dark};
  }
  ::-webkit-scrollbar-track {
    background-color: ${props => props.theme.palette.grayScale.light};
  }
`;

export const SectionWrapper = styled.div``;

export const SectionTitle = styled.div`
  border-bottom: 1px solid ${props => props.theme.palette.grayScale.light};
  margin: 0 14px 0 24px;
  padding: 10px 0;
  ${props => convertTypographySettingsToCss(props.theme.typography.h6)};
`;

export const SectionContent = styled.div<{ fullScreen: boolean }>`
  background-color: ${props => props.theme.palette.grayScale.lightest};
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 10px ${sectionContentPaddingRight} 0 ${sectionContentPaddingLeft};
  height: 100%;

  && th.ant-table-selection-column {
    display: none;
  }

  .ant-table-content {
    overflow-y: auto;
  }

  && .ant-table-column-has-sorters {
    padding-left: 0;
  }

  && .ant-table-body {
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${props => props.theme.palette.grayScale.dark};
    }
    ::-webkit-scrollbar-track {
      background-color: ${props => props.theme.palette.grayScale.light};
    }
    max-height: ${props => (props.fullScreen ? maxHeightContent : maxHeightForMultipleCategories)};
    overflow-y: auto;
  }
`;

export const DraggableListSection = styled.div`
  width: calc(50% - ${sectionContentPaddingRight});
`;

export const DraggableListWrapper = styled.div<{ fullScreen: boolean }>`
  flex: 1;
  max-height: ${props => (props.fullScreen ? maxHeightContent : maxHeightForMultipleCategories)};
  padding: 5px 20px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.palette.grayScale.dark};
  }
  ::-webkit-scrollbar-track {
    background-color: ${props => props.theme.palette.grayScale.light};
  }
`;

export const DraggableListHeader = styled.div`
  padding: 18px 0 16px;
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  font-weight: ${props => props.theme.typography.h5['font-weight']};
  border-bottom: 1px solid ${props => props.theme.palette.grayScale.medium};
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${props => props.theme.palette.grayScale.lightest};
  margin-left: 20px;
`;

export const AttributesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableWrapper = styled.div`
  flex-direction: column;
  display: flex;
  width: calc(50% - ${sectionContentPaddingLeft});
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  background-color: ${props => props.theme.palette.grayScale.lightest};
  border-top: 1px solid ${props => props.theme.palette.grayScale.light};
  padding-top: 10px;
`;

export const HRLine = styled.hr`
  width: calc(100% - 50px);
  border-top: ${props => props.theme.palette.grayScale.medium};
`;

export const Spacer = styled.div`
  height: 10px;
`;
