// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import AppLayout from 'src/components/AppLayout';

import TechnicalInformation from 'src/routes/TechnicalInformation';
import AuthSwitch from '../../Auth';
import ChangePasswordPage from '../../ChangePasswordPage/ChangePasswordPage';
import PortalBuilder from '../../PortalBuilder';
import Templates from '../../Templates';

import RoutesEnum from '../modules/Routes';

interface RoutePagesProps extends RouteProps {
  isLoggedIn: boolean;
}

export const PublicPage: React.FC<RoutePagesProps> = props => {
  const { isLoggedIn } = props;

  return !isLoggedIn ? <AuthSwitch /> : <Redirect to="/" />;
};

export const PrivatePage: React.FC<RoutePagesProps> = props => {
  const { isLoggedIn } = props;
  return isLoggedIn ? (
    <AppLayout>
      <Switch>
        <Route exact path={`/${RoutesEnum.TEMPLATES}`} render={() => <Templates />} />
        <Route exact path={`/${RoutesEnum.PORTAL_BUILDER}`} render={() => <PortalBuilder />} />
        <Route exact path={`/${RoutesEnum.CHANGE_PASSWORD}`} render={() => <ChangePasswordPage />} />
        <Route exact path={`/${RoutesEnum.TECHNICAL_INFORMATION}`} render={() => <TechnicalInformation />} />
        <Redirect to={`/${RoutesEnum.TEMPLATES}`} />
      </Switch>
    </AppLayout>
  ) : (
    <Redirect to={{ pathname: `/${RoutesEnum.AUTH}` }} />
  );
};
