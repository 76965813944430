import { Card } from '@biotmed/base-components';
import { TemplateAttributeTypeEnum } from '@biotmed/settings-sdk';
import { useFormikContext } from 'formik';
import React from 'react';
import { NameSection, TemplateAttributeTypesComponentsMap } from 'src/components/AttributeLayout';
import { EntityTemplateForm } from '../../TemplateForm';

export interface TemplateAttributeProps {
  attrFieldName: string;
  type?: TemplateAttributeTypeEnum;
  attributeTouched?: any;
  attributeValues?: any;
  attributeInitialValues: any;
  attributeErrors?: any;
  handleChangeValue: (value: number | null) => void;
}

const TemplateAttribute: React.FC<Omit<TemplateAttributeProps, 'handleChangeValue'>> = props => {
  const { type, attributeValues, attributeTouched, attributeErrors, attrFieldName } = props;
  const formik = useFormikContext<EntityTemplateForm>();
  const TypeComponent =
    TemplateAttributeTypesComponentsMap[type as string as keyof typeof TemplateAttributeTypesComponentsMap] ?? null;

  const handleChangeDisplayName = (name: string | null) => {
    formik.setFieldValue(`${attrFieldName}.displayName`, name, false);
  };

  const handleChangeValue = (value: any) => {
    formik.setFieldValue(`${attrFieldName}.value`, value, false);
    formik.setFieldTouched(`${attrFieldName}.value`, true, false);
  };

  return (
    <Card padding="26px 26px 12px">
      <NameSection
        canChangeTooltipValue={false}
        value={attributeValues.displayName}
        tooltipValue={attributeValues.name && attributeValues.name}
        onChange={handleChangeDisplayName}
        autoFocus={false}
        fieldName={`${attrFieldName}.displayName`}
        tooltipFieldName={`${attrFieldName}.name`}
        helperText={attributeTouched?.displayName ? attributeErrors?.displayName : ''}
        error={attributeTouched?.displayName && Boolean(attributeErrors?.displayName)}
        attributeId={attributeValues.id || attributeValues.arrayId}
      />
      <TypeComponent {...props} handleChangeValue={handleChangeValue} />
    </Card>
  );
};

export default TemplateAttribute;
