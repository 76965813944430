import React, { cloneElement } from 'react';
import { Template } from 'src/redux/data/entity/modules/interfaces';
import { StyleForm } from './Templates.styled';

export interface EntityTemplateForm {
  entityTemplate: Template;
  childrenTemplates: Template[];
}

export const TemplateForm = (props: any) => {
  const { pageIndex, formik, pages, className = '' } = props;
  const activePage = pages && pages[pageIndex];
  return (
    pages && (
      <StyleForm className={className}>{cloneElement(activePage, { parentState: { ...props }, formik })}</StyleForm>
    )
  );
};
