import styled from 'styled-components';
import { convertTypographySettingsToCss } from '@biotmed/base-components';

export const StyleName = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  flex: 1;
`;
export const NameSectionContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 6px 0;
`;
export const NameSectionDiv = styled.div`
  flex: 1;
`;

export const StyledWarning = styled.div`
  color: ${props => props.theme.palette.error.medium};
`;
