import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { getSdkApi } from '@biotmed/sdk-api-provider';
import { GetEntityTypesResponse } from '@biotmed/sdk-api-provider/lib/types/settings';

import { actions } from './slice';

function* onLoadEntityType(): any {
  try {
    const response: AxiosResponse<GetEntityTypesResponse> = yield call(getSdkApi().settings.entityApi.getEntityTypes);
    const entitiesList = response.data.entityTypes || [];
    yield put(actions.onLoadEntityTypeSuccess(entitiesList));
  } catch (e) {
    console.error(e);
  }
}

export default function* watchEntityActions() {
  yield all([takeLatest(actions.onLoadEntityType, onLoadEntityType)]);
}
