import React, { ReactNode } from 'react';
import { Input, Button } from '@biotmed/base-components';
import { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';
import {
  BasePath,
  ConfirmationButtonsWrapper,
  TooltipContentStyled,
  TooltipInputWrapper,
} from '../InputWithTooltip.styled';
import { OnChangeTooltipValueReason } from '../InputWithTooltip';

export interface TooltipContentProps {
  title?: string | ReactNode;
  saveButtonText?: string | ReactNode;
  value?: string | null;
  onChange?: (value: string | null, reason: OnChangeTooltipValueReason) => void;
  onClickSave?: (name: string | null) => void;
  error?: boolean;
  helperText?: string;
  name: string;
  readOnly: boolean;
  basePath?: string;
}

export const TooltipContent: React.FC<TooltipContentProps> = props => {
  const intl = useIntl();
  const defaultSaveButtonText = intl.formatMessage({ id: 'jsonTooltipSaveButton', defaultMessage: 'Save' });
  const {
    title = intl.formatMessage({
      id: 'templates.change-name-tooltip.title-text',
      defaultMessage: 'Json name',
    }),
    saveButtonText = defaultSaveButtonText,
    value,
    onChange,
    onClickSave,
    error,
    helperText,
    readOnly,
    name,
    basePath,
  } = props;
  const theme = useTheme();

  const handleChange = (event: any) => {
    onChange?.(event.target.value, 'inputChange');
  };

  const handleClickSave = (event: any) => {
    onClickSave?.(value ?? '');
  };

  const valueObject = value || value === null ? { value } : {};

  return (
    <TooltipContentStyled>
      {title}
      <TooltipInputWrapper>
        <Input
          disabled={readOnly}
          autoFocus
          name={name}
          error={error}
          helperText={helperText}
          onChange={handleChange}
          {...valueObject}
          variant="standard"
          fullWidth
          title={valueObject?.value || undefined}
          InputProps={{
            startAdornment: <BasePath readOnly={readOnly}>{basePath ? `${basePath}.` : ''}</BasePath>,
          }}
        />
      </TooltipInputWrapper>
      <ConfirmationButtonsWrapper>
        <Button
          disabled={readOnly || error}
          textColor={theme.palette.grayScale.darkest}
          size="medium"
          onClick={handleClickSave}
        >
          {saveButtonText}
        </Button>
      </ConfirmationButtonsWrapper>
    </TooltipContentStyled>
  );
};

export default TooltipContent;
