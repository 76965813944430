import { combineReducers } from 'redux';
import { fork, all } from 'redux-saga/effects';
import { RootState } from '../reducer';
import { DATA_STATE_KEY } from './constants';
import {
  STATE_KEY as userStateKey,
  reducer as userReducer,
  saga as userSaga,
  getInitialState as getUserInitialState,
} from './user';
import {
  STATE_KEY as entityStateKey,
  reducer as entityReducer,
  saga as entitySaga,
  getInitialState as getEntityInitialState,
} from './entity';
import {
  STATE_KEY as organizationStateKey,
  reducer as organizationReducer,
  saga as organizationSaga,
  getInitialState as getOrganizationInitialState,
} from './organization';
import {
  STATE_KEY as templateStateKey,
  reducer as templateReducer,
  saga as templateSaga,
  getInitialState as getTemplateInitialState,
} from './template';
import {
  STATE_KEY as appStateKey,
  reducer as appReducer,
  saga as appSaga,
  getInitialState as getAppInitialState,
} from './app';
import {
  STATE_KEY as loginStateKey,
  reducer as loginReducer,
  saga as loginSaga,
  getInitialState as getLoginInitialState,
} from './login';

export const STATE_KEY = DATA_STATE_KEY;

export const getDataInitialState = (state?: RootState) => ({
  [appStateKey]: getAppInitialState(state),
  [userStateKey]: getUserInitialState(state),
  [entityStateKey]: getEntityInitialState(state?.data[entityStateKey]),
  [templateStateKey]: getTemplateInitialState(state?.data[templateStateKey]),
  [organizationStateKey]: getOrganizationInitialState(state?.data[organizationStateKey]),

  [loginStateKey]: getLoginInitialState(state),
});

export const reducer = combineReducers({
  [appStateKey]: appReducer,
  [userStateKey]: userReducer,
  [entityStateKey]: entityReducer,
  [templateStateKey]: templateReducer,
  [organizationStateKey]: organizationReducer,
  [loginStateKey]: loginReducer,
});
export const sagas = function* root() {
  yield all([
    fork(appSaga),
    fork(userSaga),
    fork(loginSaga),
    fork(entitySaga),
    fork(templateSaga),
    fork(organizationSaga),
  ]);
};
