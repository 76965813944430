import React from 'react';
import { AllPortalAttributesGroup, SelectedPortalAttribute } from '@biotmed/settings-sdk';
import { useIntl } from 'react-intl';
import { categoryToIntlDisplayNameMap } from '@biotmed/data-components';

import { SectionTitle, AttributesWrapper, SectionWrapper } from '../PortalBuilder.styled';

import PortalViewCategoryRenderer from '../PortalViewCategoryRenderer/PortalViewCategoryRenderer';

interface AttributesBuilderProps {
  attributes: AllPortalAttributesGroup[];
  onChange: (values: any, classification?: string) => void;
  fullScreen: boolean;
}

const AttributesBuilder: React.FC<AttributesBuilderProps> = props => {
  const { attributes, onChange, fullScreen = false } = props;

  const intl = useIntl();

  const handleChange = (selectedValues: SelectedPortalAttribute[], classification?: string) => {
    onChange(selectedValues, classification);
  };

  const attributesTitle = intl.formatMessage({ id: 'portalBuilder.attributes-title.', defaultMessage: 'Attributes' });

  return (
    <SectionWrapper>
      <SectionTitle>
        {intl.formatMessage({
          id: 'portalBuilder.AttributesSectionTitle',
          defaultMessage: 'Attributes Section - select attributes, then order',
        })}
      </SectionTitle>
      {attributes.map((categoryObject: AllPortalAttributesGroup) => {
        return (
          categoryObject.attributes && (
            <AttributesWrapper key={categoryObject.classification ?? null}>
              <PortalViewCategoryRenderer
                title={`${
                  categoryObject.classification
                    ? intl.formatMessage(
                        categoryToIntlDisplayNameMap[
                          categoryObject.classification as 'REGULAR' | 'STATUS' | 'CONFIGURATION'
                        ],
                      )
                    : ''
                } ${attributesTitle}`}
                attributes={categoryObject.attributes}
                selected={categoryObject.selected}
                onChange={selectedValues => handleChange(selectedValues, categoryObject.classification)}
                fullScreen={fullScreen}
              />
            </AttributesWrapper>
          )
        );
      })}
    </SectionWrapper>
  );
};

export default AttributesBuilder;
