import React, { useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Button, Checkbox, Icon, otherIcons } from '@biotmed/base-components';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { v4 } from 'uuid';
import { TooltipButton } from 'src/components/InputWithTooltip/Tooltip';
import { EntityTemplateForm } from 'src/routes/Templates/components/TemplateForm';
import { toJsonNameFormat } from 'src/utils/stringUtils';
import { OnChangeTooltipValueReason } from 'src/components/InputWithTooltip';
import { FieldError } from '../../../formikHelpers/FieldError';
import { NameSectionContainer, NameSectionDiv, StyleName } from './SelectType.styled';
import { NameSection } from '../..';
import TypeComponentContainer from '../TypeComponent';
import { TypeComponentProps, TypeVariantComponentProps } from '../interfaces';

interface SelectTypeVariantComponentProps extends TypeVariantComponentProps {
  multi?: boolean;
}
interface SelectTypeProps extends TypeComponentProps, SelectTypeVariantComponentProps {}

const SelectType: React.FC<SelectTypeProps> = props => {
  const { attrFieldName, variant, attributeValues, multi = false, attributeTouched, attributeErrors } = props;

  const variantMap: Record<typeof variant, any> = {
    custom: CustomSelectType,
    builtIn: BuiltInSelectType,
  };
  const TypeComponent = variantMap[variant];

  return (
    <TypeComponent
      attrFieldName={attrFieldName}
      multi={multi}
      attributeValues={attributeValues}
      attributeTouched={attributeTouched}
      attributeErrors={attributeErrors}
    />
  );
};

const CustomSelectType: React.FC<SelectTypeVariantComponentProps> = props => {
  const { attrFieldName, attributeValues, multi = false, attributeTouched, attributeErrors, readonly } = props;
  const theme = useTheme();
  const intl = useIntl();
  const formik = useFormikContext<EntityTemplateForm>();

  return (
    <>
      <FieldArray name={`${attrFieldName}.selectableValues`}>
        {(fieldArrayProps: any) => {
          const { remove, push } = fieldArrayProps;
          return (
            <TypeComponentContainer readonly={readonly}>
              {attributeValues &&
                attributeValues?.selectableValues?.map((value: any, valueIndex: number) => {
                  const fieldName = `${attrFieldName}.selectableValues.${valueIndex}`;
                  const selectableItemValues = attributeValues?.selectableValues?.[valueIndex];
                  const selectableItemErrors = attributeErrors?.selectableValues?.[valueIndex];
                  const selectableItemTouched = attributeTouched?.selectableValues?.[valueIndex];
                  const attributeId = attributeValues?.basePath
                    ? `${attributeValues.basePath}.${attributeValues.name}`
                    : attributeValues.name;

                  const tooltipInputCanChangeProps = {
                    onChange: (jsonName: any, reason: OnChangeTooltipValueReason) => {
                      formik.setFieldValue(`${fieldName}.name`, toJsonNameFormat(jsonName || '').trim());
                    },
                    onSave: (jsonName: string | null) => {
                      formik.setFieldValue(`${fieldName}.name`, toJsonNameFormat(jsonName || '').trim());
                      formik.setFieldTouched(`${fieldName}.name`, true, true);
                    },
                    helperText: selectableItemTouched?.name ? selectableItemErrors?.name : '',
                    error: selectableItemTouched?.name && Boolean(selectableItemErrors?.name),
                    updateTooltipValueByValue: !value.id && !selectableItemTouched?.name,
                  };

                  return (
                    <NameSectionContainer key={value.arrayId ?? value.id}>
                      <Checkbox disabled rounded={!multi} />
                      <NameSectionDiv>
                        <NameSection
                          error={Boolean(selectableItemErrors?.displayName) && selectableItemTouched?.displayName}
                          helperText={selectableItemErrors?.displayName}
                          value={selectableItemValues?.displayName || ''}
                          tooltipValue={selectableItemValues?.name || ''}
                          fieldName={`${attrFieldName}.displayName`}
                          attributeId={attributeId}
                          canChangeTooltipValue
                          tooltipInputCanChangeProps={tooltipInputCanChangeProps}
                          onChange={displayName => formik.setFieldValue(`${fieldName}.displayName`, displayName)}
                          tooltipFieldName={`${attrFieldName}.name`}
                          basePath={attributeValues?.basePath}
                          placeholder={intl.formatMessage({
                            id: 'select_type.option.placeholder',
                            defaultMessage: 'Please choose value',
                          })}
                          bold={false}
                        />
                      </NameSectionDiv>
                      <Button onClick={() => remove(valueIndex)} paddingHorizontal="8px" paddingVertical="0px">
                        <Icon color={theme.palette.grayScale.darkest} IconComponent={otherIcons.close} />
                      </Button>
                    </NameSectionContainer>
                  );
                })}

              <FieldError
                errors={attributeErrors}
                name={`${attrFieldName}.selectableValues`}
                attributeName="selectableValues"
                touched={attributeTouched}
              />
              <Button type="button" size="medium" onClick={() => push({ displayName: '', name: '', arrayId: v4() })}>
                {intl.formatMessage({
                  id: 'template.modal.attribute.selectType-add-button',
                  defaultMessage: ' + Add Option',
                })}
              </Button>
            </TypeComponentContainer>
          );
        }}
      </FieldArray>
    </>
  );
};

const BuiltInSelectType: React.FC<SelectTypeVariantComponentProps> = props => {
  const { attributeValues, readonly, attrFieldName } = props;

  return (
    <TypeComponentContainer readonly={readonly}>
      {attributeValues?.selectableValues?.map((value: any, index: number) => {
        return (
          <BuiltInNameSection
            key={value.id}
            {...props}
            attributeValues={{ ...value, basePath: attributeValues.basePath }}
            attrFieldName={`${attrFieldName}.selectableValues.${index}`}
          />
        );
      })}
    </TypeComponentContainer>
  );
};

const BuiltInNameSection: React.FC<SelectTypeVariantComponentProps> = props => {
  const { attributeValues, attrFieldName } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <NameSectionContainer>
      <Checkbox rounded disabled checked={false} />
      <StyleName>{attributeValues?.displayName}</StyleName>
      <TooltipButton
        open={isOpen}
        onOpenChange={setIsOpen}
        readOnly
        value={attributeValues.name}
        name={`${attrFieldName}.name`}
        basePath={attributeValues.basePath}
      />
    </NameSectionContainer>
  );
};

export default SelectType;
