import {
  BuiltInAttribute,
  CreateBuiltInAttribute,
  CreateCustomAttribute,
  CreateTemplateRequest,
  CustomAttribute,
  UpdateBuiltInAttribute,
  UpdateCustomAttribute,
  UpdateTemplateRequest,
} from '@biotmed/sdk-api-provider/lib/types/settings';
import { CreateTemplateAttribute, TemplateAttribute, UpdateTemplateAttribute } from '@biotmed/settings-sdk';
import { Template } from 'src/redux/data/entity/modules/interfaces';

const mapBuiltInAttributeToCreateBuiltInAttribute = (builtInAttribute: BuiltInAttribute): CreateBuiltInAttribute => ({
  name: builtInAttribute.name,
  basePath: builtInAttribute.basePath,
  displayName: builtInAttribute.displayName,
  phi: builtInAttribute.phi,
  validation: {
    mandatory: builtInAttribute?.validation?.mandatory || false,
    min: builtInAttribute?.validation?.min,
    max: builtInAttribute?.validation?.max,
    regex: builtInAttribute?.validation?.regex,
  },
  numericMetaData: builtInAttribute.numericMetaData,
  referenceConfiguration: builtInAttribute.referenceConfiguration,
  linkConfiguration: builtInAttribute.linkConfiguration,
});

const mapCustomAttributeToCreateCustomAttribute = (customAttribute: CustomAttribute): CreateCustomAttribute => ({
  name: customAttribute.name,
  type: customAttribute.type as any,
  displayName: customAttribute.displayName,
  phi: customAttribute.phi,
  validation: {
    mandatory: customAttribute?.validation?.mandatory || false,
    min: customAttribute?.validation?.min,
    max: customAttribute?.validation?.max,
    regex: customAttribute?.validation?.regex,
  },
  numericMetaData: customAttribute.numericMetaData,
  selectableValues: customAttribute.selectableValues,
  category: customAttribute.category?.name || '',
  referenceConfiguration: customAttribute.referenceConfiguration,
  linkConfiguration: customAttribute.linkConfiguration,
});

const mapTemplateAttributeToCreateTemplateAttribute = (
  templateAttribute: TemplateAttribute,
): CreateTemplateAttribute => ({
  name: templateAttribute.name,
  basePath: templateAttribute.basePath,
  displayName: templateAttribute.displayName,
  phi: templateAttribute.phi,
  referenceConfiguration: templateAttribute.referenceConfiguration,
  linkConfiguration: templateAttribute.linkConfiguration,
  value: templateAttribute.value,
});

const mapBuiltInAttributeToUpdateBuiltInAttribute = (builtInAttribute: BuiltInAttribute): UpdateBuiltInAttribute => ({
  id: builtInAttribute?.id || '',
  displayName: builtInAttribute.displayName,
  phi: builtInAttribute.phi,
  validation: {
    mandatory: builtInAttribute?.validation?.mandatory || false,
    min: builtInAttribute?.validation?.min,
    max: builtInAttribute?.validation?.max,
    regex: builtInAttribute?.validation?.regex,
  },
  numericMetaData: builtInAttribute.numericMetaData,
  referenceConfiguration: builtInAttribute.referenceConfiguration,
  linkConfiguration: builtInAttribute.linkConfiguration,
});

const mapTemplateAttributeToUpdateTemplateAttribute = (
  templateAttribute: TemplateAttribute,
): UpdateTemplateAttribute => ({
  id: templateAttribute?.id || '',
  displayName: templateAttribute.displayName,
  phi: templateAttribute.phi,
  referenceConfiguration: templateAttribute.referenceConfiguration,
  linkConfiguration: templateAttribute.linkConfiguration,
  value: templateAttribute.value,
});

const mapCustomAttributeToUpdateCustomAttribute = (customAttribute: CustomAttribute): UpdateCustomAttribute => ({
  name: customAttribute.name,
  type: customAttribute.type as any,
  displayName: customAttribute.displayName,
  phi: customAttribute.phi,
  validation: {
    mandatory: customAttribute?.validation?.mandatory || false,
    min: customAttribute?.validation?.min,
    max: customAttribute?.validation?.max,
    regex: customAttribute?.validation?.regex,
  },
  id: customAttribute.id,
  selectableValues: customAttribute.selectableValues,
  category: customAttribute?.category?.name || '',
  numericMetaData: customAttribute.numericMetaData,
  referenceConfiguration: customAttribute.referenceConfiguration,
  linkConfiguration: customAttribute.linkConfiguration,
});

export const mapTemplateToCreateTemplateRequest = (template: Template): CreateTemplateRequest => ({
  name: template.name,
  displayName: template.displayName,
  description: template.description,
  customAttributes: template.customAttributes?.map(attr => mapCustomAttributeToCreateCustomAttribute(attr)),
  builtInAttributes: template.builtInAttributes?.map(attr => mapBuiltInAttributeToCreateBuiltInAttribute(attr)),
  templateAttributes: template.templateAttributes?.map(attr => mapTemplateAttributeToCreateTemplateAttribute(attr)),
  entityType: template.entityTypeName || '',
  ownerOrganizationId: template.ownerOrganizationId,
  parentTemplateId: template?.parentTemplateId,
});

export const mapTemplateToUpdateTemplateRequest = (template: Template): UpdateTemplateRequest => ({
  name: template.name,
  displayName: template.displayName,
  description: template.description,
  customAttributes: template.customAttributes?.map(attr => mapCustomAttributeToUpdateCustomAttribute(attr)),
  builtInAttributes: template.builtInAttributes?.map(attr => mapBuiltInAttributeToUpdateBuiltInAttribute(attr)),
  templateAttributes: template.templateAttributes?.map(attr => mapTemplateAttributeToUpdateTemplateAttribute(attr)),
  ownerOrganizationId: template.ownerOrganizationId,
  parentTemplateId: template?.parentTemplateId,
});

export const mapChildTemplateToCreateTemplateRequest = (template: Template): CreateTemplateRequest => ({
  name: template.name,
  displayName: template.displayName,
  description: template.description,
  customAttributes: template.customAttributes?.map(attr => mapCustomAttributeToCreateCustomAttribute(attr)),
  builtInAttributes: template.builtInAttributes?.map(attr => mapBuiltInAttributeToCreateBuiltInAttribute(attr)),
  templateAttributes: template.templateAttributes?.map(attr => mapTemplateAttributeToCreateTemplateAttribute(attr)),
  entityType: template.entityTypeName || '',
  ownerOrganizationId: template.ownerOrganizationId,
  parentTemplateId: template?.parentTemplateId,
});
