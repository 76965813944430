import React from 'react';

import { GetViewResponse, SelectedPortalAttribute } from '@biotmed/settings-sdk';
import { AttributesWrapper, HRLine, SectionsOuterWrapper, Spacer } from './PortalBuilder.styled';

import MeasurementsBuilder from './ViewBuilders/MeasurementsBuilder';
import TabsBuilder from './ViewBuilders/TabsBuilder';
import AttributesBuilder from './ViewBuilders/AttributesBuilder';

export interface ViewTabsSelectorProps {
  selectedValues: GetViewResponse; // TODO: add an enum
  onChange: (changedViewValues: GetViewResponse) => void;
}

const ViewTabsSelector: React.FC<ViewTabsSelectorProps> = props => {
  const { selectedValues, onChange } = props;
  const { attributes, measurements, reverseReferenceTabs } = selectedValues;

  const fullScreen = !(attributes && !!measurements);

  const handleChangeMeasurements = (values: Array<SelectedPortalAttribute>) => {
    onChange({
      ...selectedValues,
      measurements: {
        ...measurements,
        ...values,
      },
    });
  };

  const handleChangeReverseReferenceTabs = (values: Array<SelectedPortalAttribute>) => {
    onChange({
      ...selectedValues,
      reverseReferenceTabs: {
        ...reverseReferenceTabs,
        ...values,
      },
    });
  };

  const handleChangeAttributes = (values: Array<SelectedPortalAttribute>, classification?: string) => {
    const newAttributes = attributes ? [...attributes] : [];
    const newClassificationObj = {
      ...newAttributes.find(obj => classification && obj.classification === classification),
    };
    if (classification && newClassificationObj) {
      const classificationObjIndex = newAttributes.findIndex(
        obj => classification && obj.classification === classification,
      );
      newClassificationObj.selected = values;
      newAttributes[classificationObjIndex] = newClassificationObj;
    } else if (newAttributes[0]) {
      newAttributes[0] = { ...newAttributes[0], selected: values };
    }
    onChange({
      ...selectedValues,
      attributes: newAttributes,
    });
  };

  return (
    <SectionsOuterWrapper>
      <AttributesWrapper>
        {attributes && (
          <AttributesBuilder attributes={attributes} onChange={handleChangeAttributes} fullScreen={fullScreen} />
        )}
        {measurements && (
          <>
            <Spacer />
            <HRLine />
            <Spacer />
            <MeasurementsBuilder
              measurementAttributes={measurements}
              onChange={handleChangeMeasurements}
              fullScreen={fullScreen}
            />
          </>
        )}
        {reverseReferenceTabs && (
          <>
            <Spacer />
            <HRLine />
            <Spacer />
            <TabsBuilder
              tabsAttributes={reverseReferenceTabs}
              onChange={handleChangeReverseReferenceTabs}
              fullScreen={fullScreen}
            />
          </>
        )}
      </AttributesWrapper>
    </SectionsOuterWrapper>
  );
};

export default ViewTabsSelector;
