export const toSnakeCase = (str: string = ''): string => str.replace(/ +/g, '_').replace(/-+/g, '_');

export const toJsonNameFormat = (str: string = ''): string =>
  str
    .toLowerCase()
    .replace(/ +/g, '_')
    .replace(/-+/g, '_')
    .match(/[a-zA-Z][a-zA-Z0-9_]*/g)
    ?.join('')
    .trim() || '';
