import React, { useEffect, useMemo, useState } from 'react';
import { Card } from '@biotmed/base-components';
import { Field, FieldArray, FieldProps, FormikErrors, FormikTouched, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { TransparentEditInput } from 'src/components/TransparentInput/TransparentInput.styled';
import { selectors, actions } from 'src/redux/data/organization';
import { useDispatch, useSelector } from 'react-redux';
import InputWithTooltip from 'src/components/InputWithTooltip';
import { entityTemplateFieldName } from 'src/routes/Templates/modules/constant';
import { CategoryEnum } from '@biotmed/data-components';
import { Template } from 'src/redux/data/entity/modules/interfaces';
import { OverviewContainer } from './Overview.styled';
import { TextWrapper, Title } from '../../Templates.styled';
import AssignOwnerOrganization from './AssignOwnerOrganization/AssignOwnerOrganization';
import { EntityTemplateForm } from '../../TemplateForm';
import TemplateAttribute from '../../Attribute/TemplateAttribute/TemplateAttribute';

type FormikTouchedCurrent = FormikTouched<Template['customAttributes']>;
type FormikErrorsCurrent = FormikErrors<Template['customAttributes']>;

interface OverviewProps {
  iotArchitecture?: boolean;
  withSelectOwnerOrganization: boolean;
}

const Overview: React.FC<OverviewProps> = props => {
  const { withSelectOwnerOrganization } = props;
  const formik = useFormikContext<EntityTemplateForm>();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { values, setFieldTouched, setFieldValue, getFieldMeta, handleBlur } = formik;

  const organizationOptions = useSelector(selectors.selectOrganization) ?? [];
  const [assignOwnerOrganization, setAssignOwnerOrganization] = useState(!!values?.entityTemplate?.ownerOrganizationId);

  useEffect(() => {
    dispatch(actions.loadOrganizations());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredTemplateAttributes = useMemo(() => {
    const { templateAttributes } = formik.values.entityTemplate;
    return templateAttributes
      ?.map((templateAttr: any, index: number) => ({ attr: templateAttr, index }))
      .filter((templateAttr: { attr: any; index: number }) => templateAttr.attr.category?.name === CategoryEnum.REGULAR)
      ?.slice()
      ?.sort((a, b) => a?.attr?.displayName.localeCompare(b?.attr?.displayName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.entityTemplate.templateAttributes]);

  const handleChangeAssignOwnerOrganization = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!checked) {
      setFieldValue(`${entityTemplateFieldName}.ownerOrganizationId`, null);
    }
    setAssignOwnerOrganization(oldValue => !oldValue);
  };

  const displayNameFieldName = `${entityTemplateFieldName}.displayName`;
  const nameFieldName = `${entityTemplateFieldName}.name`;
  const templateAttributesFieldName = `${entityTemplateFieldName}.templateAttributes`;

  return (
    <OverviewContainer>
      <TextWrapper>
        <Title>
          {intl.formatMessage({ id: 'template.modal.overview-tab.title', defaultMessage: 'Overview Details' })}
        </Title>
      </TextWrapper>

      <Card padding="10px">
        <InputWithTooltip
          fieldName={displayNameFieldName}
          tooltipFieldName={nameFieldName}
          canChangeTooltipValue
          error={Boolean(getFieldMeta(displayNameFieldName).error) && getFieldMeta(displayNameFieldName).touched}
          helperText={getFieldMeta(displayNameFieldName).touched ? getFieldMeta(displayNameFieldName).error : ''}
          placeholder={intl.formatMessage({
            id: 'template.modal.overview-tab.display-name.placeholder',
            defaultMessage: 'Template Name...',
          })}
          onBlur={handleBlur}
          tooltipInputCanChangeProps={{
            error: Boolean(getFieldMeta(nameFieldName).error) && getFieldMeta(nameFieldName).touched,
            helperText: getFieldMeta(nameFieldName).touched ? getFieldMeta(nameFieldName).error : '',
            onSave: value => {
              setFieldValue(nameFieldName, value);
            },
            onChange: (value, reason) => {
              if (reason === 'inputChange') {
                setFieldTouched(nameFieldName, true, false);
                setFieldValue(nameFieldName, value);
              } else if (reason === 'byValue' && !getFieldMeta(nameFieldName).touched) {
                setFieldValue(nameFieldName, value);
              } else if (reason === 'clickAway') {
                setFieldValue(nameFieldName, value);
              }
            },
            onBlur: handleBlur,
            updateTooltipValueByValue: !getFieldMeta(nameFieldName).touched,
          }}
          value={values.entityTemplate?.displayName}
          tooltipValue={values.entityTemplate?.name}
          onChange={value => {
            setFieldValue(displayNameFieldName, value);
            setFieldTouched(displayNameFieldName, true, false);
          }}
        />
      </Card>
      <Card>
        <Field
          name="entityTemplate.description"
          render={({ field }: FieldProps) => (
            <TransparentEditInput
              type="text"
              minRows="6"
              multiline
              placeholder={intl.formatMessage({
                id: 'template.modal.overview-tab.description.placeholder',
                defaultMessage: 'Template Description (optional)...',
              })}
              {...field}
              onChange={event =>
                setFieldValue('entityTemplate.description', event.target.value === '' ? null : event.target.value)
              }
            />
          )}
        />
      </Card>
      {withSelectOwnerOrganization && (
        <AssignOwnerOrganization
          assignOwnerOrganization={assignOwnerOrganization}
          organizationOptions={organizationOptions}
          handleChangeAssignOwnerOrganization={handleChangeAssignOwnerOrganization}
        />
      )}
      <FieldArray name={templateAttributesFieldName}>
        {() => {
          return (
            <>
              {filteredTemplateAttributes &&
                filteredTemplateAttributes.length > 0 &&
                filteredTemplateAttributes.map((attr: any) => {
                  const attributeTouched = (
                    formik.touched?.entityTemplate?.templateAttributes as FormikTouchedCurrent
                  )?.[attr.index];
                  const attributeErrors = (formik.errors?.entityTemplate?.templateAttributes as FormikErrorsCurrent)?.[
                    attr.index
                  ];
                  const attributeValues = values?.entityTemplate?.templateAttributes?.[attr.index];
                  const attributeInitialValues = formik.initialValues?.entityTemplate?.builtInAttributes?.[attr.index];
                  return (
                    <TemplateAttribute
                      key={attr.attr.name}
                      attrFieldName={`${templateAttributesFieldName}.${attr.index}`}
                      attributeTouched={attributeTouched}
                      attributeValues={attributeValues}
                      attributeErrors={attributeErrors}
                      attributeInitialValues={attributeInitialValues}
                      type={attr.attr.type}
                    />
                  );
                })}
            </>
          );
        }}
      </FieldArray>
      {/* {iotArchitecture && (
        <Card padding="23px 25px">
          <div>
            {intl.formatMessage({
              id: 'template.modal.overview.iot-architacture.title',
              defaultMessage: 'Iot Architacture',
            })}
          </div>
          <Radio onChangeRadio={() => {}} radioObjects={radioObjects} />
        </Card>
      )} */}
    </OverviewContainer>
  );
};

export default Overview;
