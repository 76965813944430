import { defineMessages } from 'react-intl';

// eslint-disable-next-line no-shadow
export enum TemplateSagaDictionaryKey {
  UPDATE_SUCCESS = 'UPDATE_SUCCESS',
  CREATE_SUCCESS = 'CREATE_SUCCESS',
  DELETE_SUCCESS = 'DELETE_SUCCESS',
  CREATE_SUCCESS_PARTIAL = 'CREATE_SUCCESS_PARTIAL',
  UPDATE_SUCCESS_PARTIAL = 'UPDATE_SUCCESS_PARTIAL',
}

export const TemplateSagaDictionary = defineMessages({
  [TemplateSagaDictionaryKey.CREATE_SUCCESS]: { id: 'create.template.success.snack', defaultMessage: '{name} created' },
  [TemplateSagaDictionaryKey.UPDATE_SUCCESS]: { id: 'update.template.success.snack', defaultMessage: '{name} updated' },
  [TemplateSagaDictionaryKey.DELETE_SUCCESS]: { id: 'delete.template.success.snack', defaultMessage: '{name} deleted' },
  [TemplateSagaDictionaryKey.CREATE_SUCCESS_PARTIAL]: {
    id: 'create.template.success.partial.snack',
    defaultMessage: '{name} The template has been partially created',
  },
  [TemplateSagaDictionaryKey.UPDATE_SUCCESS_PARTIAL]: {
    id: 'update.template.success.partial.snack',
    defaultMessage: '{name} has been partially updated',
  },
});
