import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { getSdkApi } from '@biotmed/sdk-api-provider';
import { Button, Icon, PopperWithAnchor, otherIcons } from '@biotmed/base-components';
import { ReactComponent as InfoIcon } from 'src/images/info.svg';
import AppConfig from 'src/config/AppConfig';
import {
  Layout,
  PageTitle,
  Content,
  SubTitle,
  Box,
  PopperWrapper,
  CloseButton,
  PopperComponent,
  PopperHeader,
  HR,
} from './TechnicalInformation.styled';

interface TechnicalInformationProps {}

interface Version {
  name: string;
  version: string;
  taskDefinition?: string;
}

const displayVersion = (version: Version) => {
  const versionName = version.name?.replaceAll(/_|-/g, ' ');

  return `${versionName} - ${version.version}`;
};

const isBiotVersion = (version: Version) => {
  return version?.name === BIOT_VERSION_NAME;
};

const BIOT_VERSION_NAME = 'biot';

const TechnicalInformation: React.FC<TechnicalInformationProps> = props => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [versions, setVersions] = useState<Version[]>([]);
  const [endpoint, setEndpoint] = useState<string | undefined>();

  const intl = useIntl();
  const theme = useTheme();

  useEffect(() => {
    fetchVersions();
    fetchEndpoint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchVersions = async () => {
    try {
      const response = await fetch(AppConfig.VERSIONS_URL);
      const responseData = await response.json();
      setVersions(responseData.versions);
      setError(null);
    } catch (e) {
      setError(
        intl.formatMessage({ id: 'technical-information.fetch-error', defaultMessage: 'Could not fetch versions' }),
      );
    }
  };

  const fetchEndpoint = async () => {
    try {
      const response = await getSdkApi().device.endpointApi.getEndPoint();
      setEndpoint(response?.data?.endpoint);
    } catch (e) {
      console.error('Could not fetch endpoint. ', e);
    }
  };

  const handleInfoClick = () => {
    setOpen(previousOpen => !previousOpen);
  };

  const biotVersion = versions?.find(isBiotVersion)?.version;
  const allVersions = versions
    ?.filter((v: Version) => !isBiotVersion(v))
    ?.map(displayVersion)
    .sort((a, b) => a.localeCompare(b))
    .join('\n');

  return (
    <Layout>
      <PageTitle>
        {intl.formatMessage({ id: 'technical-information.mainTitle', defaultMessage: 'Technical Information' })}
      </PageTitle>

      <Content>
        <SubTitle>
          {intl.formatMessage({
            id: 'technical-information.biot-version',
            defaultMessage: 'BioT Version',
          })}
        </SubTitle>

        <PopperWrapper>
          {biotVersion ||
            intl.formatMessage({
              id: 'technical-information.temp-version',
              defaultMessage: 'Temp',
            })}

          <PopperWithAnchor
            open={open}
            modifiers={[
              {
                name: 'offset',
                enabled: true,
                options: {
                  offset: [30, 15],
                },
              },
            ]}
            PopperComponent={
              <PopperComponent>
                <PopperHeader>
                  <SubTitle>
                    {intl.formatMessage({
                      id: 'technical-information.version-log',
                      defaultMessage: 'Version log',
                    })}
                  </SubTitle>
                  <CloseButton onClick={handleInfoClick}>
                    <Icon color={theme.palette.grayScale.darkest} IconComponent={otherIcons.close} />
                  </CloseButton>
                </PopperHeader>
                <HR />
                {error ? <Box>{error}</Box> : <Box capitalize>{allVersions}</Box>}
              </PopperComponent>
            }
            handleClickAway={() => {}}
          >
            <Button onClick={handleInfoClick}>
              <Icon IconComponent={InfoIcon} />
            </Button>
          </PopperWithAnchor>
        </PopperWrapper>
      </Content>

      <Content>
        <SubTitle>
          {intl.formatMessage({
            id: 'technical-information.environment-name',
            defaultMessage: 'Environment name',
          })}
        </SubTitle>
        {AppConfig.ENVIRONMENT_NAME}
      </Content>

      <Content>
        <SubTitle>
          {intl.formatMessage({
            id: 'technical-information.iot-endpoint',
            defaultMessage: 'IoT Endpoint',
          })}
        </SubTitle>
        {endpoint}
      </Content>
    </Layout>
  );
};

export default TechnicalInformation;
