import React from 'react';
import { BaseInputProps } from '@biotmed/base-components';
import { TransparentEditInput, TransparentNonEditInput } from './TransparentInput.styled';
import EditInputAdornment from '../EditAdormentInput';

interface TransparentInputProps {
  readonly?: boolean | undefined;
}

const TransparentInput: React.FC<BaseInputProps & TransparentInputProps> = props => {
  const { value, readonly = false } = props;

  return readonly ? (
    <TransparentNonEditInput>{value as string}</TransparentNonEditInput>
  ) : (
    <TransparentEditInput
      value={value}
      {...props}
      InputProps={{
        startAdornment: <EditInputAdornment position="end" />,
      }}
    />
  );
};
export default TransparentInput;
