import React from 'react';
import { Tabs, Icon, ExternalLink } from '@biotmed/base-components';
import { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import AppConfig from 'src/config/AppConfig';
import { FlexSeparator } from 'src/components/AttributeLayout';
import { Link } from 'react-router-dom';
import { EntityType, SelfProfile } from '@biotmed/data-components';
import { LanguagePicker } from '@biotmed/i18n';
import history from '../../../utils/history';
import RoutesEnum from '../../../routes/Navigation/modules/Routes';
import { actions as loginActions } from '../../../redux/data/login';
import { ReactComponent as OrgIcon } from '../../../images/logo.svg';
import { ReactComponent as LogoIcon } from '../../../images/consoleLogo.svg';
import { ReactComponent as ApiRefIcon } from '../../../images/api-reference.svg';
import { ReactComponent as DevGuidesIcon } from '../../../images/dev-guides.svg';
import { ReactComponent as RequestFeatureIcon } from '../../../images/request-feature.svg';
import { LogoContainer, RightContent, TopMenu as TopMenuStyled } from './AppLayout.styled';
import { PlatformsLinksMenu } from './PlatformLinksMenu';
import { contentPadding } from '..';
import { selectors } from '../../../redux/data/user';

interface TopMenuProps {
  children?: React.ReactNode;
}

const getCurrentTab = (tabsData: { tabKey: string }[]) => {
  const tabKeys = tabsData.map(tabData => tabData.tabKey);
  const currentLocation = history?.location?.state as string;
  if (currentLocation && !tabKeys.includes(currentLocation)) {
    return false;
  }

  return currentLocation;
};

const TopMenu: React.FC<TopMenuProps> = () => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const userName = useSelector(selectors.selectFullName);
  const userRole = useSelector(selectors.selectUserRole);
  const userType = useSelector(selectors.selectUserType);

  const handleCallToRouter = (event: React.ChangeEvent<any>, value: string) => {
    history.push(value, value);
  };

  const onDisconnectClick = () => {
    dispatch(loginActions.logout());
  };

  const onChangePasswordClick = () => {
    history.push(RoutesEnum.CHANGE_PASSWORD, RoutesEnum.CHANGE_PASSWORD);
  };

  const tabsData = [
    {
      label: intl.formatMessage({
        id: 'app-layout.top-menu.tab.templates',
        defaultMessage: 'Templates',
        description: 'tab title for templates page',
      }),
      tabKey: RoutesEnum.TEMPLATES,
    },
    {
      label: intl.formatMessage({
        id: 'app-layout.top-menu.tab.portal-builder',
        defaultMessage: 'Portal Builder',
        description: 'tab title for portal builder page',
      }),
      tabKey: RoutesEnum.PORTAL_BUILDER,
    },
    {
      label: intl.formatMessage({
        id: 'app-layout.top-menu.tab.technical-information',
        defaultMessage: 'Technical Information',
        description: 'tab title for technical information page',
      }),
      tabKey: RoutesEnum.TECHNICAL_INFORMATION,
    },
  ];

  const currentTab = getCurrentTab(tabsData);

  const rightContent = (
    <RightContent>
      <ExternalLink
        svg={RequestFeatureIcon}
        title={intl.formatMessage({
          id: 'app-layout.top-menu.link.requestFeature',
          defaultMessage: 'request feature',
        })}
        link={AppConfig.REQUEST_FEAUTE_URL ? AppConfig.REQUEST_FEAUTE_URL : ''}
      />
      <FlexSeparator style={{ width: '3px' }} />
      <ExternalLink
        svg={ApiRefIcon}
        title={intl.formatMessage({
          id: 'app-layout.top-menu.link.apiReference',
          defaultMessage: 'api reference',
        })}
        link={AppConfig.API_REFERENCE_URL ? AppConfig.API_REFERENCE_URL : ''}
      />
      <FlexSeparator style={{ width: '3px' }} />
      <ExternalLink
        svg={DevGuidesIcon}
        title={intl.formatMessage({
          id: 'app-layout.top-menu.link.devGuides',
          defaultMessage: 'dev guides',
        })}
        link={AppConfig.DEV_GUIDE_URL ? AppConfig.DEV_GUIDE_URL : ''}
      />
      <FlexSeparator style={{ width: '10px' }} />
      <PlatformsLinksMenu />
      <FlexSeparator style={{ width: '10px' }} />
      <LanguagePicker availableLanguages={Object.values(AppConfig.AVAILABLE_LOCALES)} />
      <SelfProfile
        orgIcon={OrgIcon}
        userName={userName}
        userRole={userRole}
        onDisconnectClick={onDisconnectClick}
        userType={userType as EntityType}
        buttons={[
          {
            text: intl.formatMessage({
              id: 'self-profile.change-password',
              defaultMessage: 'Change Password',
            }),
            onClick: onChangePasswordClick,
          },
        ]}
      />
    </RightContent>
  );

  const leftContent = (
    <LogoContainer>
      <Link to="./">
        <Icon height="21px" IconComponent={LogoIcon} color={theme.palette.primary.medium} />
      </Link>
    </LogoContainer>
  );

  return (
    <TopMenuStyled>
      <Tabs
        tabBarExtraContent={{
          left: leftContent,
          right: rightContent,
          spaceBetween: 80,
        }}
        tabsData={tabsData}
        backgroundActive={`${theme.palette.primary.dark}${theme.opacity.almostTransparent}`}
        textColorActive={theme.palette.primary.medium}
        indicatorOverAll
        selectedTabKey={currentTab}
        padding={`0 ${contentPadding}`}
        onChange={handleCallToRouter} // eslint-disable-line
        variant="scrollable"
        scrollButtons="auto"
      />
    </TopMenuStyled>
  );
};
export default TopMenu;
