import React from 'react';
import { useIntl } from 'react-intl';
import { StyledBuiltInMessage } from './ComponentSections.styled';

const BuiltIn: React.FC = props => {
  const intl = useIntl();

  return (
    <StyledBuiltInMessage>
      {intl.formatMessage({
        id: 'templates.attribute.builtin-phi-section.built-in-attribute',
        defaultMessage: 'Built in attribute',
      })}
    </StyledBuiltInMessage>
  );
};

export default BuiltIn;
