import { InputNumber } from '@biotmed/base-components';
import styled from 'styled-components';

export const NumericInput = styled(InputNumber)`
  max-width: 190px;
  &.MuiFormControl-root {
    height: 70px;
  }
`;

export const AttributeTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 15px;
  height: 70px;
`;
