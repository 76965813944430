import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { selectors as entitySelectors } from 'src/redux/data/entity';
import { selectors as templateSelectors, actions as templateActions } from 'src/redux/data/template';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import TransparentInput from 'src/components/TransparentInput';
import { EntityTemplateForm } from 'src/routes/Templates/components/TemplateForm';
import { GetTemplateResponse } from '@biotmed/settings-sdk';
import { EntityType, EntityTypeToIntlDisplayNameMap } from '@biotmed/data-components';
import { fallbackMessage } from 'src/routes/Templates/modules/constant';
import { InputsContainer, ReferenceTypeContainer, Separator, SingleSelectStyled } from './ReferenceType.styled';
import { ReferenceTypeVariantComponentProps } from './ReferenceType';
import AdaptedMultiSelect from './AdaptedMultiSelect';
import TypeComponent from '../TypeComponent';

const CustomReferenceType: React.FC<ReferenceTypeVariantComponentProps> = props => {
  const { attrFieldName, attributeValues, attributeErrors, attributeTouched, readonly } = props;

  const formik = useFormikContext<EntityTemplateForm>();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [referencedSideAttributeName, setReferencedSideAttributeName] = useState(
    attributeValues?.referenceConfiguration?.referencedSideAttributeName ?? '',
  );
  useEffect(() => {
    if (!attributeValues?.referenceConfiguration?.referencedSideAttributeName) {
      formik.setFieldValue(`${attrFieldName}.referenceConfiguration.referencedSideAttributeName`, '');
    }
    if (!attributeValues?.referenceConfiguration?.entityType) {
      formik.setFieldValue(`${attrFieldName}.referenceConfiguration.entityType`, '');
    }
    // TODO: FIX BUG OF FORMIK TOUCHED IN OTHER WAY

    dispatch(templateActions.onLoadAllTemplate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const entityTypesList = useSelector(entitySelectors.getTypes);

  const entityTypeError = attributeErrors?.referenceConfiguration?.entityType;
  const referencedSideAttributeNameError = attributeErrors?.referenceConfiguration?.referencedSideAttributeName;

  const entityTypeTouched = attributeTouched?.referenceConfiguration?.entityType;
  const referencedSideAttributeNameTouched = attributeTouched?.referenceConfiguration?.referencedSideAttributeName;

  const validTemplatesToReferenceError = attributeErrors?.referenceConfiguration?.validTemplatesToReference;
  const validTemplatesToReferenceTouched = attributeTouched?.referenceConfiguration?.validTemplatesToReference;

  const [entityType, setEntityType] = useState(attributeValues?.referenceConfiguration?.entityType ?? '');
  const [entityTemplate, setEntityTemplate] = useState(
    attributeValues?.referenceConfiguration?.validTemplatesToReference ?? [],
  );

  const entity = useSelector(entitySelectors.getEntityByEntityName(entityType));

  const entityTemplateList: Array<GetTemplateResponse> = useSelector(
    templateSelectors.getAllTemplatesListByEntityType(entityType) ?? [],
  );

  const handleChangeEntityType = (event: any) => {
    const { value } = event.target;
    setEntityType(value);
    setEntityTemplate([]);
    formik.setFieldValue(`${attrFieldName}.referenceConfiguration.validTemplatesToReference`, []);
    formik.setFieldValue(`${attrFieldName}.referenceConfiguration.entityType`, value);
  };

  const handleChangeEntityTemplate = (value: string[] | null) => {
    setEntityTemplate(value);
    formik.setFieldValue(`${attrFieldName}.referenceConfiguration.validTemplatesToReference`, value);
  };

  const handleChangeReferencedSideAttributeName = (event: any) => {
    const { value } = event.target;
    setReferencedSideAttributeName(value);
    formik.setFieldValue(`${attrFieldName}.referenceConfiguration.referencedSideAttributeName`, value);
  };

  return (
    <TypeComponent readonly={readonly}>
      <ReferenceTypeContainer>
        <InputsContainer>
          <SingleSelectStyled
            onChange={event => {
              handleChangeEntityType(event);
              formik.handleChange(event);
            }}
            selectList={entityTypesList.map((type: string) => ({
              value: type,
              title: intl.formatMessage(EntityTypeToIntlDisplayNameMap[type as EntityType] || fallbackMessage(type)),
            }))}
            placeholder={intl.formatMessage({
              id: 'template.modal.attribute.Reference-type.entity-type.placeholder',
              defaultMessage: 'Select Entity',
            })}
            inputProps={{
              variant: 'standard',
              error: Boolean(entityTypeError) && entityTypeTouched,
              helperText: entityTypeTouched ? entityTypeError : null,
            }}
            defaultValue={entityType}
            name={`${attrFieldName}.referenceConfiguration.entityType`}
          />
          <Separator>-</Separator>
          <AdaptedMultiSelect
            handleChangeEntityTemplate={handleChangeEntityTemplate}
            entityTemplateList={entityTemplateList}
            entityTemplate={entityTemplate}
            entity={entity}
            attrFieldName={attrFieldName}
            disabled={Boolean(!entityType) || !entity.addingTemplatesSupported}
            error={Boolean(validTemplatesToReferenceError) && validTemplatesToReferenceTouched}
            helperText={validTemplatesToReferenceTouched ? validTemplatesToReferenceError : null}
          />
          <Separator>-</Separator>
          <TransparentInput
            value={referencedSideAttributeName}
            onChange={event => {
              handleChangeReferencedSideAttributeName(event);
              if (!event.target.value || event.target.value.length === 1) {
                formik.handleChange(event);
              }
            }}
            name={`${attrFieldName}.referenceConfiguration.referencedSideAttributeName`}
            placeholder={intl.formatMessage({
              id: 'template.modal.attribute.Reference-type.referenced-side.placeholder',
              defaultMessage: 'Referenced side attribute name',
            })}
            error={Boolean(referencedSideAttributeNameError) && referencedSideAttributeNameTouched}
            helperText={referencedSideAttributeNameTouched ? referencedSideAttributeNameError : null}
            readonly={readonly}
          />
        </InputsContainer>
      </ReferenceTypeContainer>
    </TypeComponent>
  );
};
export default CustomReferenceType;
